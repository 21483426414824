/**
 * @author [Tandy Alam]
 * @email [tandy.hutama@dwidasa.com]
 * @create date 2021-06-16 16:13:22
 * @modify date 2021-01-24 16:13:22
 * @desc [description]
 */
import axios from "axios";
import headers from "@/services/headers";
// const API_URL = "https://dev-api.klinisia.id/doctor/appointment/"; //prod
const API_URL = "https://dev-api.klinisia.id/doctor/appointment/"; //dev
//const API_URL = "https://demo-dev-api.klinisia.id/doctor/consultation/"; //demo
class WAppConsultationServices {
  async getMyAppWthDate(uriParams) {
    return await axios.get(
      API_URL +
        `my-appointment/by-sch-date?schDate=${uriParams.reqDate}&timeZoneId=${uriParams.timezone}`,
      {
        headers: headers(),
      },
    );
  }
  async getMyAppEvent(data) {
    return await axios.get(
      `${API_URL}my-appointment/by-year-month?year=${data.year}&month=${data.month}&timeZoneId=${data.timezone}`,
      {
        headers: headers(),
      },
    );
  }
  async getSummaryMyApp(data) {
    return await axios.get(
      `${API_URL}my-appointment/summary-by-year-month?year=${data.year}&month=${data.month}&timeZoneId=${data.timezone}`,
      {
        headers: headers(),
      },
    );
  }
  async masterLovAppStatus() {
    return await axios.get(API_URL + "master/lov/appointment-status", {
      headers: headers(),
    });
  }
  async masterLovAppType() {
    return await axios.get(API_URL + "master/lov/appointment-type", {
      headers: headers(),
    });
  }
  async MyappDetail(params) {
    return await axios.post(API_URL + "my-appointment/detail/" + params, {
      headers: headers(),
    });
  }
  async MyappCancel(params) {
    return await axios.post(
      API_URL + "my-appointment/cancel/" + params.uriParam,
      params.bodyParams,
      {
        headers: headers(),
      },
    );
  }
  async MyappCancelAll(bodyParams) {
    return await axios.post(
      API_URL + "my-appointment/cancel-many",
      bodyParams,
      {
        headers: headers(),
      },
    );
  }
}

export default new WAppConsultationServices();
