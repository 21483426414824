/**
 * @author [Mochamad Arifin]
 * @email [mochamad.arifin@dwidasa.com]
 * @create date 2021-01-18 21:36:36
 * @modify date 2021-01-18 21:36:36
 * @desc [description]
 */


<template>
    <v-row>
        <v-col cols="12  mt-15">
            <v-card :class="'rounded-xl'">
                <v-row>
                    <v-col cols="5" class="ml-5">
                        <v-row>
                            <v-col>
                                <v-combobox 
                                    v-model="selectedComboBox"
                                    :items="comboBoxItem"
                                    flat
                                    solo
                                    dense
                                    hide-details
                                    @change="changeCombobox()"
                                ></v-combobox>
                            </v-col>
                        </v-row>
                    </v-col>

                    <v-col cols="6">
                        <v-row>
                            <v-col cols="6">
                                <v-dialog
                                    ref="dialogstart"
                                    v-model="startdatemodal"
                                    :return-value.sync="startdate"
                                    persistent
                                    width="290px"
                                >   
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            class="customTF"
                                            v-model="startdate"
                                            placeholder="Start Date"
                                            outlined
                                            dense
                                            readonly
                                            hide-details
                                            prepend-inner-icon="calendar_today"
                                            append-icon="arrow_drop_down"
                                            v-bind="attrs"
                                            v-on="on"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="startdate"
                                        scrollable
                                        >
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            text
                                            color="primary"
                                            @click="startdatemodal = false"
                                        >
                                            Cancel
                                        </v-btn>
                                        <v-btn
                                            text
                                            color="primary"
                                            @click="[$refs.dialogstart.save(startdate), getObjDate()]"
                                        >
                                            OK
                                        </v-btn>
                                    </v-date-picker>
                                </v-dialog>
                            </v-col>
                            <v-col cols="6">
                                <v-dialog
                                    ref="dialogend"
                                    v-model="enddatemodal"
                                    :return-value.sync="enddate"
                                    persistent
                                    width="290px"
                                >   
                                    <template v-slot:activator="{ on, attrs }">
                                        <v-text-field
                                            class="customTF"
                                            v-model="enddate"
                                            placeholder="End Date"
                                            outlined
                                            readonly
                                            dense
                                            hide-details
                                            prepend-inner-icon="calendar_today"
                                            append-icon="arrow_drop_down"
                                            v-bind="attrs"
                                            v-on="on"
                                        ></v-text-field>
                                    </template>
                                    <v-date-picker
                                        v-model="enddate"
                                        scrollable
                                        >
                                        <v-spacer></v-spacer>
                                        <v-btn
                                            text
                                            color="primary"
                                            @click="enddatemodal = false"
                                        >
                                            Cancel
                                        </v-btn>
                                        <v-btn
                                            text
                                            color="primary"
                                            @click="[$refs.dialogend.save(enddate), getObjDate()]"
                                        >
                                            OK
                                        </v-btn>
                                    </v-date-picker>
                                </v-dialog>                                
                            </v-col>
                        </v-row>
                    </v-col>

                    <!-- Divider -->
                    <v-col cols="12" class="ma-0 pa-0">
                        <v-divider class="light-blue lighten-4"></v-divider>
                    </v-col>

                    <!-- List Objective -->
                    <!-- <v-col cols="12" v-if="attachments.length ===0">No</v-col> -->
                    <v-container v-show="attachments.length <1">
                        <v-col cols="12" class="text-center">No Objective data</v-col>
                    </v-container>

                    <v-container v-show="attachments.length >=1">
                        <v-col cols="12">
                            <v-list>
                                <v-list-item v-for="(obj, key) in attachments" :key="key">
                                    <v-row class="ma-0 pa-0">

                                        <v-col cols="12">
                                            <v-row :style="randomColor(key)">
                                                <!-- icon -->
                                                <v-col cols="1">
                                                    <v-list-item-icon>
                                                        <v-icon dense>mdi-image</v-icon>
                                                    </v-list-item-icon>
                                                </v-col>
                                                <!-- time -->
                                                <v-col cols="4">
                                                    <v-list-item-content>
                                                        <v-list-item-title class="title1">{{ obj.attachmentDatetime | objectiveDate }}</v-list-item-title>
                                                    </v-list-item-content>
                                                </v-col>
                                                <!-- desc -->
                                                <v-col cols="4">
                                                    <v-list-item-content>
                                                        <v-list-item-title class="img1" v-text="obj.fileName"></v-list-item-title>
                                                    </v-list-item-content>
                                                </v-col>
                                                <!-- btn -->
                                                <v-col cols="3">

                                                    <v-btn text style="text-transform: none;" class="mt-2 blue--text" 
                                                        @click.stop="modalImage(obj)">
                                                         Lihat
                                                    </v-btn>
                                                </v-col>
                                            </v-row>
                                        </v-col>

                                        <v-col cols="12">
                                        <v-divider></v-divider>
                                        </v-col>
                                    </v-row>
                                </v-list-item>
                            </v-list>
                        </v-col>
                    </v-container>

                </v-row>
            </v-card>
        </v-col>
        <v-row justify="center">
            <v-col cols="auto">
                <v-dialog v-model="dialog" persistent :retain-focus="false" 
                    transition="dialog-bottom-transition" width="750">
                    <v-card class="px-5 py-4">
                        <v-card-actions class="justify-end">
                            <v-btn @click="dialog = false" dark rounded>
                                <v-icon>mdi-close</v-icon>
                            </v-btn>
                        </v-card-actions>
                        <div class="text-center">
                            <img v-if="isImg" :src="img" :alt="alt">
                            <iframe v-if="!isImg" :src="gdocs" style="width:700px; height:700px;" frameborder="0"></iframe>
                        </div>
                    </v-card>
                </v-dialog>
            </v-col>
        </v-row>

    </v-row>
</template>

<script>
// import Vuepdf from "vuetify-pdfjs/src/App.vue";
export default {
    props: {
        // menuTag
    },
    // components: {Vuepdf},
    data: () => ({
        dialog: false,
        isImg: true,
        img: '',
        alt: '',
        gdocs: '',
        attachments: [],
        selectedComboBox: 'Show All',
        comboBoxItem: ['Show All', 'Show 1 Item'],
        startdate: '',
        enddate: '',    
        startdatemodal: false,
        enddatemodal: false,
        patient : {},
        tgl: "",
        leftColor: {}
    }),
    methods: {
        getObjective() {
            this.$store.dispatch('consultation/actGetObjective', window.localStorage.getItem('cntConsultationId')).then(
                resolve => {
                    this.patient = resolve.data.payload
                    if (this.patient) {
                        this.tgl = resolve.data.timestamp
                        this.patient.attachments.forEach(obj =>{
                            obj.url += '?token=' + window.localStorage.getItem('shortToken')
                        })
                        this.attachments.push(...this.patient.attachments)
                    }
                })
        },
        modalImage(obj) {
            this.isImg = obj.fileName.split('.').pop() == 'pdf' ? false : true
            this.dialog = true
            this.img = obj.url
            this.gdocs = `https://docs.google.com/gview?url=${obj.url}&embedded=true`
            this.alt = obj.fileName
        },
        randomColor(id) {
            const r = () => Math.floor(256 * Math.random());
            return {borderLeft: '4px solid ' + (this.leftColor[id] = `rgb(${r()}, ${r()}, ${r()})`) };
        },
        getObjDate() {            
            if (this.startdate && this.enddate) {
                this.attachments = this.patient.attachments.filter(obj =>
                    this.startdate <= obj.attachmentDatetime.split('T')[0] && this.enddate >= obj.attachmentDatetime.split('T')[0]
                )
                return this.attachments
            }
            return this.attachments
        },
        changeCombobox() {
            this.attachments = this.selectedComboBox === 'Show 1 Item' ? [this.patient.attachments[0]] : this.patient.attachments
            return this.attachments
        }
    },
    mounted() {
        this.getObjective()
    }
}
</script>

<style scoped>
img {
  width: 100%;
  height: auto;
}
.customDivider {
    background: #D6E6F5;
    border-radius: 10px;
}
.item.desc {
    border-left: 4px solid #3cd1c2;
}
.customTF {
    background: #EDF4FB;
    border-radius: 6px;
}
.borderLeft {
    border-left: 4px solid #3cd1c2;
}
.countDataObjective {
    color: rgba(95, 95, 95, 0.49);
    text-align: right;
}

@media only screen and (max-width: 1366px) {
.title1{
 font-size: 14px;
 font-weight: bold;
 margin-top: 4px;
 font-style: Nunito;
}
.img1{
 font-size: 14px;
 margin-top: 4px;
 font-style: Nunito;
 text-align: center;
}
}

</style>