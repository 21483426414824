<template>
  <v-main class="bgColor">
    <v-container fluid class="bgColor2">
      <v-layout row wrap class="">
        <v-spacer></v-spacer>
        <p class="ma-3 pa-3 text-nama">{{ fullName }}</p>
        <v-flex md1 class="ma-2 pa-0" align-self-start>
          <v-avatar size="40" color="white">
            <img class="image-profile" :src="photoProfile" alt="" />
          </v-avatar>
        </v-flex>
      </v-layout>

      <v-row class="height">
        <v-col :cols="colom">
          <v-sheet height="45" class="sheet">
            <v-toolbar flat dense>
              <v-toolbar-title
                v-if="$refs.calendar"
                class="float-center ml-1 mr-3"
              >
                <!-- <p class="mt-4 text-nama">{{ $refs.calendar.title }}</p> -->
              </v-toolbar-title>
              <v-btn
                outlined
                class="mr-4"
                color="grey darken-2"
                @click="setToday"
                small
                rounded
              >
                Today
              </v-btn>

              <v-btn
                class="ml-15"
                fab
                text
                small
                color="grey darken-2"
                @click="prev"
              >
                <v-icon small> mdi-chevron-left </v-icon>
              </v-btn>
              <!-- <div v-if="type === 'month'">
                <v-toolbar-title v-if="$refs.calendar" class="m">
                  <p class="mt-4 text-nama">{{ $refs.calendar.title }}</p>
                </v-toolbar-title>
              </div> -->

              <v-toolbar-title class="">
                <p class="mt-4 text-nama" v-if="$refs.calendar">
                  {{ $refs.calendar.title }}
                </p>
              </v-toolbar-title>

              <v-btn fab text small color="grey darken-2" @click="next">
                <v-icon small> mdi-chevron-right </v-icon>
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                class="btnmouth"
                small
                :color="warnamonth"
                @click="type = 'month'"
              >
                <span class="textmouth">Month</span>
              </v-btn>
              <v-btn
                class="btnweek"
                small
                :color="warnaweek"
                @click="type = 'week'"
              >
                <span class="textweek">Week</span>
              </v-btn>
            </v-toolbar>
          </v-sheet>

          <v-sheet height="430" class="sheet">
            <v-dialog v-model="dialogWarning" persistent max-width="500">
              <v-card style="background-color: #29aaf3">
                <v-row class="ma-4 pa-2">
                  <v-col cols="12" align-self="center">
                    <p class="icd-judul">Konsultasi Segera Di Mulai</p>
                  </v-col>
                  <v-col cols="12">
                    <p class="icd-text">
                      Konsultasi Segera Di Mulai, Pada {{ jam }}
                    </p>
                    <p class="icd-text">Dengan Pasien {{ nama }}</p>
                  </v-col>
                </v-row>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn medium outlined color="" dark @click="oke">Oke</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
            <v-calendar
              ref="calendar"
              v-model="focus"
              color="primary"
              :events="events"
              event-height="17"
              :event-color="getEventColor"
              :type="type"
              @click:event="showEvent"
              @click:more="viewMore"
              @click:date="viewDay"
              @change="updateRange"
              class="textevent"
            ></v-calendar>
            <!-- <v-calendar
          ref="calendar"
          v-model="focus"
          color="primary"
          :events="events"
          :event-color="getEventColor"
          :type="type"
          @click:event="showEvent"
          @click:more="viewDay"
          @click:date="viewDay"
          @change="updateRange"
        ></v-calendar> -->
            <v-menu
              v-model="selectedOpen"
              :close-on-content-click="false"
              :activator="selectedElement"
              offset-x
            >
              <v-card color="grey lighten-4" min-width="350px" flat>
                <v-toolbar :color="selectedEvent.color" dark>
                  <v-btn icon>
                    <v-icon>mdi-pencil</v-icon>
                  </v-btn>
                  <v-toolbar-title
                    v-html="selectedEvent.name"
                  ></v-toolbar-title>
                  <v-spacer></v-spacer>
                  <v-btn icon>
                    <v-icon>mdi-heart</v-icon>
                  </v-btn>
                  <v-btn icon>
                    <v-icon>mdi-dots-vertical</v-icon>
                  </v-btn>
                </v-toolbar>
                <v-card-text>
                  <span v-html="selectedEvent.details"></span>
                </v-card-text>
                <v-card-actions>
                  <v-btn text color="secondary" @click="selectedOpen = false">
                    Cancel
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-menu>
          </v-sheet>

          <!-- sheet Footer -->
          <v-sheet height="55" class="sheetfooter">
            <Footercalendar />
          </v-sheet>
        </v-col>

        <v-col cols="3" class="tampul" v-show="tampil">
          <v-card rounded="10px" height="520px" class="sheet">
            <v-row>
              <v-col cols="1">
                <v-checkbox
                  v-model="checkbox"
                  dense
                  class="ml-1 mt-0"
                  @change="changeAllSch()"
                ></v-checkbox>
              </v-col>
              <v-col cols="4" class="ml-1 mt-2">
                <h5 class="today">
                  TODAY <span class="todayitem ml-1">{{ this.convert }}</span>
                </h5>
              </v-col>
              <v-col cols="6" class="ml-4 mt-1">
                <v-btn
                  x-small
                  @click="yakinBatal2"
                  color="primary"
                  v-show="checkbox"
                >
                  Berhalangan</v-btn
                >
              </v-col>
            </v-row>

            <v-card height="450px" class="cardtampil" width="600px">
              <v-row class="" v-for="(jadwal, index) in datHasil" :key="index"
                ><v-row class="ml-2">
                  <v-col cols="6">
                    <v-checkbox
                      v-model="jadwal.selectionStatus"
                      color="primary"
                      hide-details
                      @change="isChecked(jadwal)"
                      dense
                      :disabled="jadwal.checkBerhalangan"
                    >
                      <template v-slot:label>
                        <span class="checkboxLabel">{{
                          jadwal.start + "-" + jadwal.end + " " + jadwal.schTimeZoneId
                        }}</span>
                      </template></v-checkbox
                    >
                  </v-col>
                  <v-col cols="4" class="mt-6 ml-7"
                    ><p
                      class="statustext"
                      v-if="
                        jadwal.schSlotUiStatus === 'Available' ||
                          jadwal.schSlotUiStatus === 'Unknown'
                      "
                    >
                      Slot Tersedia
                    </p>
                    <p
                      class="statustext"
                      v-else-if="jadwal.schSlotStatus === 'PaymentProgress'"
                    >
                      Menunggu Pembayaran
                    </p>
                    <p
                      class="statustext"
                      v-else-if="
                        jadwal.schSlotStatus === 'PaymentSuccess' &&
                          jadwal.schSlotUiStatus === 'PatientAssessment'
                      "
                    >
                      Pengkajian Awal
                    </p>
                    <p
                      class="statustext"
                      v-else-if="
                        jadwal.schSlotStatus === 'Active' ||
                          jadwal.schSlotUiStatus === 'Active'
                      "
                    >
                      Active
                    </p>
                    <p
                      class="statustextCancel"
                      v-else-if="
                        jadwal.schSlotStatus === 'DocCancel' ||
                          jadwal.scschSlotUiStatus === 'Cancel'
                      "
                    >
                      Dibatalkan
                    </p>
                    <p
                      class="statustext"
                      v-else-if="jadwal.schSlotUiStatus === 'Finish'"
                    >
                      Finish
                    </p>
                  </v-col>
                  <v-col cols="12" class="statusnama"
                    ><p class="ml-2" v-if="jadwal.appointment !== null">
                      {{ jadwal.appointment.patientFullName }}
                    </p></v-col
                  >
                  <v-col cols="12" class="statusnomerhp"
                    ><p class="ml-2" v-if="jadwal.appointment !== null">
                      {{ jadwal.appointment.patientMobilePhone }}
                    </p></v-col
                  >
                  <v-col cols="6" class="colbtn" v-if="!jadwal.berhasilbatal">
                    <div  v-if="jadwal.today <= jadwal.timeMulai">
                    <v-btn
                      x-small
                      outlined
                      color="primary"
                      class="ml-2 mr-2"
                      @click="yakinbatal(jadwal)"
                    
                    >
                      Berhalangan</v-btn
                    >
                    </div>
                    <div v-else>
 <p
                      class="statustextMenit ml-1 mt-2"
                     
                    >
                      Update Status Tidak Bisa Dilakukan 5 Menit Sebelum
                      Konsultasi
                    </p>
                    </div>
                   
                  </v-col>
                  <v-col cols="6" class="colbtn" v-else>
                       <v-btn
                      x-small
                      outlined
                      color="primary"
                      class="ml-2 mr-2"
                      v-show="jadwal.berhasilbatal"
                      disabled
                    >
                      Berhasil Dibatalkan</v-btn
                    >
                  </v-col>
                  <v-col cols="6" class="colbtn">
                    <v-btn
                      x-small
                      outlined
                      :disabled="jadwal.lihatDisabled"
                      color="primary"
                      v-show="jadwal.btnLihat"
                      @click="lihatDetel(jadwal)"
                    >
                      Lihat Detail</v-btn
                    >
                  </v-col>
                </v-row>
                <v-divider></v-divider>
                <hr class="" />
              </v-row>
            </v-card>
          </v-card>
          <v-dialog v-model="dialogBatal" persistent max-width="700">
            <v-card>
              <v-card-title>
                Apakah Anda Yakin Ingin Membatalkan Jadwal ini ?
              </v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" text @click="close"> Close </v-btn>
                <v-btn color="primary" text @click="batal"> Batalkan </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>
          <v-dialog v-model="dialogBatal2" persistent max-width="700">
            <v-card>
              <v-card-title>
                Apakah Anda Yakin Ingin Membatalkan Jadwal Yang Tercentang ini ?
              </v-card-title>
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn color="error" text @click="close"> Close </v-btn>
                <v-btn color="primary" text @click="batal2"> Batalkan </v-btn>
              </v-card-actions>
            </v-card>
          </v-dialog>

          <!-- Dialog Lihat Detail       -->
          <v-dialog v-model="detail" persistent max-width="700">
            <v-card  style="padding-top:30px">
              <v-row class="ml-13 mr-13" v-if="detailPatient !== null">
                <v-col cols="6" lg="2" class="">
                  <v-row align="center" justify="center" class="">
                    <v-avatar size="80" class="mt-2">
                      <img
                        :src="detailPatient.consultation.profilePictureUrl"
                        alt=""
                        class="imgphoto"
                      />
                    </v-avatar>
                  </v-row>
                </v-col>

                <v-col cols="6" lg="7" class="">
                  <v-row>
                    <v-col>
                      <div class="patientName mb-1">
                        {{ detailPatient.consultation.patientFullName }}
                      </div>
                      <div class="patientGender">
                        {{ detailPatient.patient.gender }}
                      </div>
                    </v-col>
                  </v-row>
                  <v-row class="">
                    <v-col cols="12" lg="4" md="4">
                      <v-row class="ml-0">
                        <img
                          src="@/assets/weightHeight.png"
                          class="imgicon"
                          alt=""
                        />
                        <div class="ml-3 bio">
                          {{ detailPatient.patientAssessment.patientWeightKg }}
                          kg
                        </div>
                        <!-- <div class="ml-3 bio">/</div>  -->
                        <div class="ml-3 bio">
                          {{ detailPatient.patientAssessment.patientHeightCm }}
                          cm
                        </div>
                      </v-row>
                    </v-col>
                    <v-col cols="12" lg="4">
                      <v-row class="ml-0">
                        <img
                          src="@/assets/birthday.png"
                          class="imgicon"
                          alt=""
                        />
                        <!-- <div class="ml-3 bio">{{dataTakeQueue.data.payload.patient.birthDate | birthDate}}</div> -->
                        <div class="ml-3 bio">
                          {{ detailPatient.patientAge.years }} y /
                          {{ detailPatient.patientAge.months }} m /
                          {{ detailPatient.patientAge.days }} d
                        </div>
                      </v-row>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" lg="3" md="3" class="">
                  <v-row align="center" co justify="center">
                    <!-- Btn Start Chat Style -->
                    <v-btn
                      rounded
                      color="#3FEF9A"
                      class="styleBtnChat mb-1"
                      x-small
                      :disabled="mulaiDis"
                      @click="mulai"
                    >
                      Mulai Konsultasi
                    </v-btn>
                    <v-btn
                      rounded
                      color="error"
                      class="styleBtnChat"
                      @click="detail = false"
                      x-small
                    >
                      Close
                    </v-btn>
                  </v-row>
                </v-col>
              </v-row>
              <v-row class="">
                <v-tabs fixed-tabs slider-color="blue" class="ml-16 mr-16">
                  <v-tab @click="currentComponent = 'Subjective'">
                    <div class="titleMenu">Subjective</div>
                  </v-tab>

                  <v-tab @click="currentComponent = 'Objective'">
                    <div class="titleMenu">Objective</div>
                  </v-tab>
                </v-tabs>
                <v-tabs-items vertical class="">
                  <v-card class="ml-16 mr-16 scroll cardtab" flat>
                    <v-row>
                      <keep-alive>
                        <component :is="currentComponent" />
                      </keep-alive>
                    </v-row>
                  </v-card>
                </v-tabs-items>
                <!-- </v-container> -->
              </v-row>
            </v-card>
          </v-dialog>
        </v-col>
      </v-row>
    </v-container>
  </v-main>
</template>

<script>
  // import GeneralServices from "@/services/general_services";
  import DoctorServices from "@/services/doctor_services";
  import AuthServices from "@/services/auth_services";
  import Subjective from "@/components/Subjective2";
  import Objective from "@/components/Objective2";
  import Footercalendar from "@/components/calendar/Footercalendar";
  import WithAppServices from "@/services/withAppServices";
  import moment from "moment";
  export default {
    name: "Calender",

    data: () => ({
      dataJ: 0,
      dataI: 0,
      oh: [],
      currentComponent: "Subjective",
      items: ["Subjective", "Objective"],
      fullName: window.localStorage.getItem("fullName"),
      convertTitle: "",
      dialog: false,
      detail: false,
      btnBerhalangn: false,
      btnLihat: false,
      berhasilbatal: false,
      Checkberhasilbatal: false,
      dialogBatal: false,
      checkbox: false,
      dark: false,
      detailPatient: null,
      patientAssesment: [],
      timeDokter: false,
      startMenu: false,
      endMenu: false,
      convert: "",
      nowMenu: false,
      minWeeks: 1,
      now: null,
      focus: new Date(),
      gmt: "",
      timezoneID:"",
      params: {reqDate :"", timezone:""},
      all: false,
      mulaiDis: false,
      warnaweek: "",
      warnamonth: "",
      mode: "stack",
      modes: ["stack", "column"],
      tampil: false,
      colom: 12,
      photoProfile: null,
      type: "month",
      typeToLabel: {
        month: "Month",
        week: "Week",
      },
      events: [],
      listevents: [],
      dataPatientEvent: [],
      colors: "blue",
      events2: [],
      name: null,
      listJadwal: [],
      datHasil: [],
      idJadwal: "",
      list: [],
      details: null,
      start: null,
      end: null,
      nama: "",
      jam: "",
      dialogBatal2: false,
      idsch: [],
      idsch2: [],
      dateGet: "",
      cntID: "",
      timeZone: window.localStorage.getItem("timeZoneId"),
      dateGetDelete: "",
      currentlyEditing: null,
      selectedEvent: {},
      selectedElement: null,
      selectedOpen: false,
      dialogWarning: false,
      waktu: "",
    }),
    components: {
      Subjective,
      Objective,
      Footercalendar,
    },
    mounted() {
      this.getPhotoprofile();
      this.$refs.calendar.checkChange();
      this.callRefreshToken();
      this.$refs.calendar;
      // this.focus;
      this.focus = moment(this.focus).format("YYYY-MM-DD");
      this.params.reqDate = this.focus
      this.getTimeZoneId()
      console.log('timezoneID ',this.timezoneID);
      this.params.timezone = window.localStorage.getItem("timeZoneId")
      console.log('focus ',this.params);
      this.waktu = setInterval(() => {
        this.getListDokterJadwal(this.params);
      }, 30000);

      this.getEvents();

      this.convert = moment(this.focus).format("l");
    },
    computed: {
      foto() {
        return this.$store.getters["consultation/getPhoto"];
      },
     
    },
    methods: {
       getTimeZoneId(){
         this.gmt = new Date().toLocaleTimeString('en-us',{timeZoneName:'short'}).split(' ')[2]
        if (this.gmt == "GMT+7") {
          this.timezoneID = "WIB";
        }else if (this.gmt == "GMT+8") {
          this.timezoneID = "WITA";
        }else{
            this.timezoneID = "WIT";
        }
      },
      oke() {
        this.dialogWarning = false;
      },
      test() {
        this.dialogWarning = true;
      },
      callRefreshToken() {
        // console.log("trial");
        const params = {
          userId: window.localStorage.getItem("secUserId"),
          deviceType: "browser",
          deviceCode: "123",
          longToken: window.localStorage.getItem("longToken"),
          role: window.localStorage.getItem("role"),
        };
        console.log("PARAMS " + params.longToken);
        AuthServices.doRefreshToken(params)
          .then((response) => {
            console.log("MASUK SINI");
            console.log(
              "TOKEN REFRESH " +
                JSON.stringify(response.data.payload.shortToken.token),
            );
            window.localStorage.removeItem("shortToken");
            window.localStorage.setItem(
              "shortToken",
              response.data.payload.shortToken.token,
            );
            console.log(
              "TOKEN STORAGE " + window.localStorage.getItem("shortToken"),
            );
          })
          .catch((err) => {
            console.log(err);
            window.localStorage.clear();
            window.localStorage.removeItem("in");
            this.$router.push("/login");
          });
      },
      close() {
        let i = [];
        this.$store.dispatch("consultation/setPatientList2", i);
        // this.$store.dispatch("consultation/actDenganJanji", true);
        this.dialogBatal = false;
        this.idsch = [];
        this.dialogBatal2 = false;
        this.idsch2 = [];
      },
      changeAllSch() {
        this.idsch2 = [];
        // console.log(this.listJadwal);
        this.datHasil.forEach((e) => {
          e.checkBerhalangan = false;
          let timeing = "";
          if (e.startTime < 10) {
            timeing = e.dateTimer + "T0" + e.timestart;
          } else {
            timeing = e.dateTimer + "T" + e.timestart;
          }
          let endtime = "";
          if (e.endTime < 10) {
            endtime = e.dateTimer + "T0" + e.timeend;
          } else {
            endtime = e.dateTimer + "T" + e.timeend;
          }
          let cht = moment
            .utc(timeing, "YYYY-MM-DD HH:mm:ss")
            .format("MMM DD, YYYY HH:mm:ss");
          let end = moment
            .utc(endtime, "YYYY-MM-DD HH:mm:ss")
            .format("MMM DD, YYYY HH:mm:ss");
          let waktu = new Date(cht).getTime() - 300000;
          let waktuEnd = new Date(end).getTime() + 1000;
          let hariini = new Date().getTime();
          // console.log("hari ini", hariini);
          // console.log("start ", timeing);
          // console.log("endtime ", endtime);
          console.log(waktuEnd);
          // console.log("waktune", waktu);
          if (hariini >= waktu) {
            console.log("ilang", e.docDoctorHptSchSlotId);
            e.checkBerhalangan = true;
          } else {
            console.log("Muncul", e.docDoctorHptSchSlotId);
            e.checkBerhalangan = false;
          }

          if (e.schSlotStatus === "DocCancel") {
            e.checkBerhalangan = true;
          }
          if (e.checkBerhalangan === true) {
            e.selectionStatus = false;
          } else {
            e.selectionStatus = true;
            this.idsch2.push(e.docDoctorHptSchSlotId);
          }
          if (this.checkbox === false) {
            this.idsch2 = [];
            e.selectionStatus = false;
          }
          this.dateGetDelete = e.dateTimer;
        });
        console.log(this.idsch2);
      },
      mulai() {
        // console.log(this.cntID);
        // console.log("PICK PATIENT " + JSON.stringify(this.cntID));
        // this.params = this.cntID;
        // let doctorEmail = window.localStorage.getItem("email");
        // let doctorUsername = window.localStorage.getItem("fullName");
        // let keyEmail = "KlinisiaWebDoctorAppEmail";
        // // let secUserId = window.localStorage.getItem('secUserId')
        // let combineEmail = doctorEmail + keyEmail;
        // console.log(combineEmail);
        // let combinePass = "KlinisiaWebDoctorAppPass";
        // this.$qiscus
        //   .setUser(combineEmail, combinePass, doctorUsername)
        //   .then((res) => {
        //     console.log("qiscus login", res);
        //     console.log("k");
        //     this.$store.dispatch("consultation/actBtnStatus", false);
        //   });
        // let data = {
        //   cntConsultationId: this.cntID,
        // };
        // this.$store
        //   .dispatch("consultation/postTakeQueueWithApp", data)
        //   .then((response) => {
        //     if (response.data.error !== "") {
        //       this.$toast.open({
        //         message: response.data.error,
        //         position: "top-right",
        //         type: "error",
        //         duration: 6000,
        //       });
        //     } else {
        //       console.log("res taque", response);
        //       // let readStatusMenuResponse = response.data.payload.consultation.summaryStatus
        //       // console.log('ASSESMENT MENU ' + readStatusMenuResponse);
        //       // if(readStatusMenuResponse) {
        //       //     this.$emit('clickedPatient', this.tag)
        //       //     this.$qiscus.chatTarget(window.localStorage.getItem('cntConsultationId')).then(
        //       //         response => {
        //       //             this.$store.dispatch('consultation/actQisqusRoomId', response)
        //       //             // this.params.bodyParams.roomId = response.id
        //       //             // window.localStorage.setItem('roomId', response.id)

        //       //             // this.$store.dispatch('consultation/actStartChat', this.params).then(
        //       //             //     response => {
        //       //             //         console.log(response)
        //       //             //     }
        //       //             // )
        //       //         }
        //       //     )
        //       // }else {
        //       // cntConsultationId
        //       window.localStorage.setItem(
        //         "cntConsultationId",
        //         response.data.payload.consultation.cntConsultationId
        //       );
        //       // docDoctorId
        //       window.localStorage.setItem(
        //         "docDoctorId",
        //         response.data.payload.consultation.docDoctorId
        //       );
        //       // ptnPatientId
        //       window.localStorage.setItem(
        //         "ptnPatientId",
        //         response.data.payload.consultation.ptnPatientId
        //       );
        //       let i = [];
        //       i.push(response.data.payload.consultation);
        //       this.$store.dispatch("consultation/setPatientList2", i);
        //       clearInterval(this.waktu);
        //       this.$store.dispatch("consultation/actGreting", 2);
        //       this.$store.dispatch("consultation/actDenganJanji", true);
        //       this.$router.push("/Chat");
        //     }
        //   });
        this.$store.dispatch("consultation/actGreting", 1);
        this.$store.dispatch("consultation/actDenganJanji", true);
        this.$router.push("/Chat");
      },
      lihatDetel(item) {
        const hariini = new Date().getTime();
        const aptID = item.appointment.aptAppointmentId;
        WithAppServices.MyappDetail(aptID).then((res) => {
          if (res.data.error !== "") {
            this.$toast.open({
              message: res.data.error,
              position: "top-right",
              type: "error",
              duration: 6000,
            });
          } else {
            this.detailPatient = res.data.payload;
            // console.log(this.detailPatient);
            this.cntID = res.data.payload.consultation.cntConsultationId;
            this.$store.dispatch("consultation/actDetailPatient", res);
            let i = [];
            i.push(res.data.payload.consultation);
            this.$store.dispatch("consultation/setPatientList2", i);
            // this.$store.dispatch("consultation/actDenganJanji", true);
          }
        });
        console.log(hariini);
        console.log(item.timeMulai);
        if (hariini >= item.timeMulai) {
          this.mulaiDis = false;
        } else {
          this.mulaiDis = true;
        }
        this.detail = true;
      },
      yakinbatal(item) {
        this.dialogBatal = true;
        console.log(item);
        this.idJadwal = item.docDoctorHptSchSlotId;
        this.dateGet = item.dateTimer;
      },
      yakinBatal2() {
        this.dialogBatal2 = true;
      },
      batal2() {
        console.log(this.idsch2);
        WithAppServices.MyappCancelAll(this.idsch2).then((res) => {
          if (res.data.error !== "") {
            this.$toast.open({
              message: res.data.error,
              position: "top-right",
              type: "error",
              duration: 6000,
            });
          } else {
            this.$toast.open({
              message: "Semua Data Berhasil Dibatalkan",
              position: "top-right",
              type: "success",
              duration: 6000,
            });
            // this.getListDokterJadwal(this.dateGetDelete);
            window.location.reload();
            // this.dialogBatal2 = false;
          }
        });
      },

      batal() {
        console.log(this.idJadwal);
        let data = {
          uriParam: this.idJadwal,
          bodyParams: this.idJadwal,
        };
        this.batalJadwal(data);
      },
      batalJadwal(value) {
        WithAppServices.MyappCancel(value).then((res) => {
          console.log(res);
          if (res.data.error !== "") {
            this.$toast.open({
              message: res.data.error,
              position: "top-right",
              type: "error",
              duration: 6000,
            });
          } else {
            this.$toast.open({
              message: "Jadwal Berhasil Di batalkan Oleh Dokter",
              position: "top-right",
              type: "success",
              duration: 6000,
            });
            window.location.reload();
          }
        });
      },
      getListEvent(value) {
        // clearInterval(this.waktu);
        WithAppServices.getMyAppEvent(value).then((res) => {
          console.log("event res", res);
          if (res.data.error !== "") {
            this.$toast.open({
              message: res.data.error,
              position: "top-right",
              type: "error",
              duration: 6000,
            });
          } else {
            let data = [];
            data = res.data.payload;
            this.events = [];
            data.map((e) => {
              this.events.push({
                names: e.patientFullName,
                color: "#29AAF3",
                details: e.patientFullName,
                date: e.schDate,
                startTime: Number(e.startTime.match(/^(\d+)/)[1]) + ":00",
                endTime: Number(e.endTime.match(/^(\d+)/)[1]) + ":00",
                timestart: e.startTime,
                timeend: e.endTime,
                open: false,
              });
              this.events.forEach((data) => {
                if (data.startTime < 10) {
                  data.start = data.date + "T0" + data.timestart;
                } else {
                  data.start = data.date + "T" + data.timestart;
                }
                if (data.endTime < 10) {
                  data.end = data.date + "T0" + data.timeend;
                  data.endTime = "0" + data.endTime;
                } else {
                  data.end = data.date + "T" + data.timeend;
                }

                let namei = data.names.split(" ");
                let nama2 = " ";
                console.log(namei[1]);
                if (namei[1] === undefined) {
                  namei[1] = nama2;
                }
                data.name = namei[0] + " " + namei[1];
              });
            });
          }
        });
      },
      isChecked(item) {
        console.log(item);
        console.log(item.selectionStatus);
        console.log(this.idsch2);
        let itemData = [];
        for (let i = 0; i < this.idsch2.length; i++) {
          if (item.docDoctorHptSchSlotId !== this.idsch2[i]) {
            itemData.push(this.idsch2[i]);
          }
        }
        console.log("itemdata", itemData);
        this.idsch2 = [];
        itemData.forEach((obj) => {
          this.idsch2.push(obj);
        });
        // console.log("hasil", this.idsch2);
        // if (item.selectionStatus === true) {
        //   this.idsch.push(item.docDoctorHptSchSlotId);
        // }
        // console.log("from isCheked",this.idsch);
      },
      getListDokterJadwal(value) {
        WithAppServices.getMyAppWthDate(value).then((res) => {
          console.log(res);
          if (res.data.error !== "") {
            this.$toast.open({
              message: res.data.error,
              position: "top-right",
              type: "error",
              duration: 6000,
            });
          } else {
            this.listJadwal = [];
            this.list = res.data.payload;
            // let data = [];
            // this.list.forEach((e) => {
            //   data.push(Number(e.schSlot.startTime.match(/^(\d+)/)[1]));
            // });
            // let hasilurut = data.sort(function (a, b) {
            //   return a - b;
            // });
            // console.log(hasilurut);
            let listBenar = [];
            // for (let i = 0; i < hasilurut.length; i++) {
            //   for (let j = 0; j < this.list.length; j++) {
            //     if (
            //       Number(this.list[j].schSlot.startTime.match(/^(\d+)/)[1]) ===
            //       hasilurut[i]
            //     ) {
            //       listBenar.push(this.list[j]);
            //     }
            //   }
            // }

            // // console.log("benar", listBenar);
            for (let i = 0; i < this.list.length; i++) {
              listBenar.push(this.list[i]);
            }
            listBenar.sort((a, b) =>
              a.schSlot.startTime < b.schSlot.startTime
                ? -1
                : a.schSlot.startTime > b.schSlot.startTime
                ? 1
                : 0,
            );
            this.list = [];
            this.list = listBenar;
            // console.log("list benar", this.list);
            this.list.map((e) => {
              this.listJadwal.push({
                appointment: e.appointment,
                startTime:
                  Number(e.schSlot.startTime.match(/^(\d+)/)[1]) + ":00",
                endTime: Number(e.schSlot.endTime.match(/^(\d+)/)[1]) + ":00",
                timeAwal: Number(e.schSlot.startTime.match(/^(\d+)/)[1]),
                docDoctorHospitalId: e.schSlot.docDoctorHospitalId,
                docDoctorHptSchDateTimeId: e.schSlot.docDoctorHptSchDateTimeId,
                docDoctorHptSchSlotId: e.schSlot.docDoctorHptSchSlotId,
                schSlotStatus: e.schSlot.schSlotStatus,
                dateTimer: e.schSlot.schDate,
                timestart: e.schSlot.startTime,
                timeend: e.schSlot.endTime,
                schSlotUiStatus: e.schSlotUiStatus,
                checkBerhalangan: true,
                start: e.start,
                timeMulai: e.timeMulai,
                today: e.today,
                end: e.end,
                schTimeZoneId:e.schSlot.schTimeZoneId
              });
              // console.log(this.listJadwal);
              this.listJadwal.forEach((data) => {
                let timeing = "";
                if (data.startTime < 10) {
                  timeing = data.dateTimer + "T0" + data.timestart;
                  data.startTime = "0" + data.startTime;
                } else {
                  timeing = data.dateTimer + "T" + data.timestart;
                }
                let endtime = "";
                if (data.endTime < 10) {
                  endtime = data.dateTimer + "T0" + data.timeend;
                  data.endTime = "0" + data.endTime;
                } else {
                  endtime = data.dateTimer + "T" + data.timeend;
                }
                let cht = moment
                  .utc(timeing, "YYYY-MM-DD HH:mm:ss")
                  .format("MMM DD, YYYY HH:mm:ss");
                data.end = moment
                  .utc(endtime, "YYYY-MM-DD HH:mm:ss")
                  .format("HH:mm");
                data.start = moment
                  .utc(timeing, "YYYY-MM-DD HH:mm:ss")
                  .format("HH:mm");
                let waktu = new Date(cht).getTime() - 300000;
                let waktuwarning = new Date(cht).getTime() - 600000;
                let waktuwarning2 = new Date(cht).getTime() - 570000;
                // let lebih = waktu - 10;
                // let waktuEnd = new Date(end).getTime() + 1000;
                let hariini = new Date().getTime();
                data.timeMulai = waktu;
                data.today = hariini;
                // console.log("start ", timeing);
                // console.log(waktuEnd);
                // console.log("waktune", waktu);
                // console.log(waktuwarning);
                if (hariini >= waktuwarning && hariini <= waktuwarning2) {
                  this.nama = data.appointment.patientFullName;
                  this.jam = moment
                    .utc(timeing, "YYYY-MM-DD HH:mm:ss")
                    .format("MMM DD, YYYY HH:mm:ss");
                  this.dialogWarning = true;
                  this.$store.dispatch("consultation/actWarning", true);
                  this.$store.dispatch(
                    "consultation/actWarningNama",
                    this.nama,
                  );
                  this.$store.dispatch("consultation/actWarningJam", this.jam);
                } else {
                  this.$store.dispatch("consultation/actWarning", false);
                }
                if (hariini >= waktu) {
                  data.checkBerhalangan = true;
                  // data.berhalangan = false;
                  // data.textMenit = true;
                  // data.mulaiya = false;
                  // data.lihatDisabled = false;
                  // data.lihatDetel = true;
                  //  console.log("Muncul", data.mulaiya);
                } else {
                  // data.berhalangan = true;
                  // this.dialogWarning = false;
                  data.checkBerhalangan = true;
                  // data.mulaiya = true;
                  // data.textMenit = false;
                  // data.textMenit = false;
                  // data.mulaiya = true;
                }

                if (
                  data.schSlotUiStatus === "Active" ||
                  data.schSlotUiStatus === "PatientAssessment"
                ) {
                  // data.lihatDisabled = true;
                  data.btnLihat = true;
                } else {
                  data.btnLihat = false;
                }

                if (data.schSlotUiStatus !== "Active") {
                  data.lihatDisabled = true;
                } else {
                  data.lihatDisabled = false;
                }

                if (data.schSlotUiStatus === "Cancel") {
                  data.berhasilbatal = true;
                  data.berhalangan = false;
                  data.checkBerhalangan = true;
                  data.textMenit = false;
                  data.btnLihat = false;
                }
                if (data.appointment === null) {
                  data.btnLihat = false;
                } else {
                  data.btnLihat = true;
                }
              });
              // this.listJadwal.sort((a, b) => (a.time < b.time) ? -1 : ((a.time > b.time) ? 1 : 0))
            });
            console.log("Jadwal", this.listJadwal);
            if (this.oh.length < this.listJadwal.length) {
              this.oh = [];
              if (this.datHasil.length === 0) {
                for (let i = 0; i < this.listJadwal.length; i++) {
                  console.log(this.listJadwal[i]);
                  this.datHasil.push(this.listJadwal[i]);
                }
              } else if (this.datHasil.length > 0) {
                console.log("lebih");
                if (this.datHasil.length !== this.listJadwal.length) {
                  for (
                    let i = this.datHasil.length;
                    i < this.listJadwal.length;
                    i++
                  ) {
                    console.log(i);
                    this.datHasil.push(this.listJadwal[i]);
                  }
                }
              }
              this.oh = this.listJadwal;
            } else if (this.oh.length === this.listJadwal.length) {
              console.log("a");
              for (let i = 0; i < this.datHasil.length; i++) {
                this.dataJ = 0;
                this.dataI = 0;

                for (let j = 0; j < this.listJadwal.length; j++) {
                  if (
                    this.datHasil[i].docDoctorHptSchSlotId ===
                    this.listJadwal[j].docDoctorHptSchSlotId
                  ) {
                    this.dataI = i;
                    this.dataJ = j;
                  }
                   if (
                    this.datHasil[i].today ===
                    this.listJadwal[j].today
                  ) {
                   this.dataI = i;
                    this.dataJ = j;
                  }
                }
                if (
                  this.datHasil[this.dataI].schSlotUiStatus !==
                  this.listJadwal[this.dataJ].schSlotUiStatus
                ) {
                  //console.log("beda");
                  this.datHasil.splice(
                    this.dataI,
                    1,
                    this.listJadwal[this.dataJ],
                  );
                }
                  if (
                  this.datHasil[this.dataI].today !==
                  this.listJadwal[this.dataJ].today
                ) {
                  //console.log("beda");
                  this.datHasil.splice(
                    this.dataI,
                    1,
                    this.listJadwal[this.dataJ],
                  );
                }
                // console.log('data i ',this.dataI);
                // console.log('data j',this.dataJ);
              }
            }
            console.log("hasil", this.datHasil);
          }
        });
      },
      viewDay({ date }) {
        // clearInterval(this.waktu);
        this.datHasil = [];
        this.oh = [];
        this.checkbox = false;
        this.focus = date;
        this.convert = moment(date).format("l");
        this.convertTitle = moment(this.focus).format("dddd MMM D YYYY");
        (this.type = "week"), (this.colom = 9), (this.tampil = true);
        this.params.reqDate = this.focus
        this.getListDokterJadwal(this.params);
        // this.waktu = setInterval(() => {
        //   this.getListDokterJadwal(this.focus);
        // }, 30000);
      },
      viewMore({ date }) {
        //  clearInterval(this.waktu);
        this.datHasil = [];
        this.oh = [];
        this.focus = date;
        this.convert = moment(date).format("l");
        this.convertTitle = moment(this.focus).format("dddd MMM D YYYY");
        console.log(date);
        (this.colom = 9), (this.tampil = true);
        this.params.reqDate = this.focus
        this.getListDokterJadwal(this.params);
        // this.waktu = setInterval(() => {
        //   this.getListDokterJadwal(this.focus);
        // }, 300000);
      },
      getPhotoprofile() {
        // GeneralServices.getPhotoProfile(
        //   window.localStorage.getItem("secUserId")
        // ).then((response) => {
        //   console.log("foto", response);
        //   this.photoProfile =
        //     response.config.url +
        //     "?token=" +
        //     window.localStorage.getItem("shortToken");
        //   this.$store.dispatch("consultation/actPhoto", this.photoProfile);
        // });
        DoctorServices.getProfile().then((res) => {
          console.log("prfile", res);
          this.fullName = res.data.payload.fullName;
          this.photoProfile = res.data.payload.profilePictureUrl;
          console.log(this.photoProfile);
        });
      },
      getEventColor(events) {
        return events.color;
      },
      // getMonth() {
      //   if (this.$refs.calendar) {
      //     let values = this.$refs.calendar.value;
      //     console.log(values);
      //     let yearID = moment(values).format("YYYY");
      //     let monthID = moment(values).format("MM");
      //     console.log(yearID);
      //     console.log(monthID);
      //     let data = {
      //       year: yearID,
      //       month: monthID,
      //     };
      //     this.getListEvent(data);
      //   }
      // },
      setToday() {
        this.focus = "";
      },
      prev() {
        this.$refs.calendar.prev();
      },
      next() {
        this.$refs.calendar.next();
      },
      // showEvent({ nativeEvent, event }) {
      //   let open = () => {
      //     this.selectedEvent = event;
      //     this.selectedElement = nativeEvent.target;
      //     requestAnimationFrame(() =>
      //       requestAnimationFrame(() => (this.selectedOpen = true))
      //     );
      //   };

      //   if (this.selectedOpen) {
      //     this.selectedOpen = false;
      //     requestAnimationFrame(() => requestAnimationFrame(() => open()));
      //   } else {
      //     open();
      //   }

      //   nativeEvent.stopPropagation();
      // },
      updateRange() {
        if (this.type === "week") {
          this.colom = 9;
          this.tampil = true;
          this.warnaweek = "#2B92E4";
          this.warnamonth = "#FFFFFF";
          const cht = moment.utc(this.focus).format("YYYY-MM-DD");
          this.getListDokterJadwal(cht);
        } else {
          console.log("clears");
          // clearInterval(this.waktu);
          this.colom = 12;
          this.tampil = false;
          this.warnaweek = "#FFFFFF";
          this.warnamonth = "#2B92E4";
          if (this.$refs.calendar) {
            let values = this.$refs.calendar.value;
            console.log(values);
            let yearID = moment(values).format("YYYY");
            let monthID = moment(values).format("MM");
            console.log(yearID);
            console.log(monthID);
            this.$store.dispatch("consultation/actYear", yearID);
            this.$store.dispatch("consultation/actMonth", monthID);
            let data = {
              year: yearID,
              month: monthID,
              timezone: window.localStorage.getItem("timeZoneId")
            };
            this.getListEvent(data);
          }
        }
      },
      // rnd(a, b) {
      //   return Math.floor((b - a + 1) * Math.random()) + a;
      // },
    },
  };
</script>

<style scoped>
  .height {
    margin-top: -20px;
  }
  .tampul {
    margin-left: -15px;
  }
  .icd-judul {
    font-family: Nunito;
    font-style: normal;
    font-weight: 800;
    font-size: 18px;
    display: flex;
    align-self: center;
    position: relative;
    color: #ffffff;
  }
  .icd-text {
    font-family: Nunito;
    font-size: 14px;
    display: flex;
    margin-top: -5px;
    align-self: center;
    position: relative;
    color: #ffffff;
  }
  .my-event {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    border-radius: 2px;
    background-color: #1867c0;
    color: #ffffff;
    border: 1px solid #1867c0;
    width: 90%;
    margin-left: 5px;
    font-size: 8px;
    padding: 3px;
    cursor: pointer;
    margin-bottom: 1px;
  }

  .feature-pane {
    position: relative;
    padding-top: 30px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
  }

  .day-header {
    margin: 0px 2px 2px 2px;
    padding: 2px 6px;
    background-color: #1867c0;
    color: #ffffff;
    border: 1px solid #1867c0;
    border-radius: 2px;
    user-select: none;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .day-body {
    position: absolute;
    top: 400px;
    height: 36px;
    margin: 2px;
    padding: 2px 6px;
    background-color: #1867c0;
    color: #ffffff;
    border: 1px solid #1867c0;
    border-radius: 2px;
    left: 0;
    right: 0;
    user-select: none;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .day {
    position: relative;
    height: 24px;
    margin: 0px;
    padding: 0px 6px;
    background-color: #1867c0;
    color: #ffffff;
    border: 1px solid #1867c0;
    border-radius: 2px;
    left: 0;
    right: 0;
    user-select: none;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  .bgColor {
    background: #5c95ff;
    overflow-y: visible;
  }
  .bgColor2 {
    background: #edf4fb;
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
    width: 100%;
    height: 100%;
  }
  .text-nama {
    font-size: 12px;
    font-family: Nunito;
  }
  .image-profile {
    width: 40px;
    height: 40px;
    border: 2px solid #ffffff;
  }
  .btnmouth {
    border: 1px;
    box-sizing: border-box;
    border-radius: 20px 0px 0px 20px;
  }
  .btnweek {
    border: 1px;
    box-sizing: border-box;
    border-radius: 20px 0px 0px 20px;
    transform: rotate(-180deg);
    margin-right: 20px;
  }
  .textweek {
    font-family: Nunito;
    transform: rotate(180deg);
  }
  .textmouth {
    font-family: Nunito;
  }
  .today {
    font-family: Nunito;
    font-style: normal;
    font-weight: 800;
    font-size: 12px;
    display: flex;
    align-items: center;
    color: #2b92e4;
    flex: none;
  }
  .todayitem {
    font-family: Nunito;
    font-style: normal;
    font-size: 10px;
    font-weight: 400;
  }
  .hr {
    margin-top: -15px;
  }
  .statustext {
    font-family: Nunito;
    font-style: normal;
    font-size: 10px;
    color: #00cc6a;
    text-decoration-line: underline;
  }
  .statustextCancel {
    font-family: Nunito;
    font-style: normal;
    font-size: 10px;
    color: #ff3737;
    text-decoration-line: underline;
  }
  .statustextMenit {
    font-family: Nunito;
    font-style: italic;
    font-size: 8px;
    color: #2b92e4;
    text-decoration-line: underline;
  }
  .statustextbatal {
    font-family: Nunito;
    font-style: normal;
    font-size: 13px;
    color: #ff3737;
    text-decoration-line: underline;
  }
  .statusnama {
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
    /* identical to box height, or 100% */
    margin-top: -12px;
    text-decoration-line: underline;
    margin-top: -35px;
    /* klinisia biru 2 */
    color: #074291;
  }
  .statusnomerhp {
    font-family: Nunito;
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    margin-top: -40px;
  }
  .colbtn {
    margin-top: -37px;
  }
  .cardtampil {
    overflow-y: auto;
    overflow-x: hidden;
  }
  .texttotal {
    font-family: Nunito;
    font-style: normal;
    font-weight: 500;
    font-size: 10px;
  }
  .textjumlah {
    font-family: Nunito;
    font-style: normal;
    font-weight: 900;
    font-size: 20px;
    margin-top: -17px;
  }
  .textpasien {
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 10px;
  }
  .textevent {
    font-family: Nunito;
    font-weight: 500;
  }
  .sheet {
    border-radius: 10px !important;
    box-shadow: 0px 4px 10px rgba(68, 133, 253, 0.5) !important;
  }
  .sheet {
    border-radius: 10px !important;
    box-shadow: 0px 4px 10px rgba(68, 133, 253, 0.5) !important;
  }
  .sheetfooter {
    border-bottom-left-radius: 10px !important;
    border-bottom-right-radius: 10px !important;
    box-shadow: 0px 4px 10px rgba(68, 133, 253, 0.5) !important;
  }
  .patientName {
    font-weight: bold;
    font-size: 14px;
    font-family: Nunito;
  }
  .patientGender {
    color: #777777;
    font-size: 12px;
    font-family: Nunito;
  }
  .imgicon {
    width: 12px;
    height: 12px;
    margin-top: -15px;
  }
  .bio {
    font-size: 8px;
    color: #414042;
    font-family: Nunito;
    font-weight: 400;
    margin-top: -15px;
  }
  .titleMenu {
    font-weight: bold;
    font-size: 12px;
    font-family: Nunito;
  }
  .checkboxLabel {
    font-family: Nunito;
    font-size: 10px;
  }
  .scroll {
    display: flex !important;
    flex-direction: column;
    max-height: 375px;
  }
  .cardtab {
    background: #ffffff;
    overflow-y: scroll;
    overflow-x: hidden;
  }
</style>
