/**
 * @author [Mochamad Arifin]
 * @email [mochamad.arifin@dwidasa.com]
 * @create date 2021-01-11 22:06:42
 * @modify date 2021-01-11 22:06:42
 * @desc [description]
 */

<template>
  <v-row>
    <v-col cols="12">
      <v-row class="ml-15">
        <v-col cols="5" lg="4" class="mt-7">
          <p class="lblTotalPatient">Total Antrian</p>
        </v-col>
      </v-row>
      <v-row class="ml-15">
        <v-col cols="3">
          <p class="lblPatient">Pasien</p>
        </v-col>
        <v-col cols="3" class="mt-1">
          <p class="bgTotalPatient">{{ totalPatientList }}</p>
        </v-col>
      </v-row>
      <v-row>
        <v-divider class="bgColorDivider ml-5 mr-5"></v-divider>
      </v-row>

      <v-row class="mt-2 ml-12 mr-9">
        <v-col>
          <v-list>
            <v-list-item-group v-model="selectedItem">
              <v-list-item
                v-for="(chat, key) in filteredPatient"
                :key="key"
                @click="takeQueue(chat.cntConsultationId)"
                :disabled="chat.disabled || di"
              >
                <v-list-item-avatar height="45" width="45">
                  <img
                    :src="chat.profilePictureUrl"
                    :alt="`${chat.patientFullName}`"
                  />
                  <div>
                    <vue-audio :file="file1" :autoPlay="statusPlay" hidden />
                  </div>
                  <!-- <div v-if="chat.chatRoomId != null ">
                                         <vue-audio :file="file1" :autoPlay="false" hidden />
                                    </div> -->
                </v-list-item-avatar>

                <v-list-item-content
                  :class="{ 'disable-patient': chat.disabled }"
                >
                  <v-list-item-title
                    class="ma-0 pa-0 titlePatientList"
                    v-text="chat.patientFullName"
                  ></v-list-item-title>
                  <div v-if="chat.paymentDate">
                    <v-list-item-subtitle class="subtitlePatientList mt-1"
                      >Pembayaran Pk
                      {{
                        chat.paymentDate | paymentListDate
                      }}</v-list-item-subtitle
                    >
                  </div>
                </v-list-item-content>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
// import useSound from "vue-use-sound";
import notif from "@/assets/pristine-609.mp3";
import VueAudio from "vue-audio";
export default {
  data: () => ({
    file1: notif,
    statusPlay: false,
    tag: 2,
    di: false,
    nilaiID: "",
    pasien: [],
    selectedItem: 0,
    list: [],
    shorToken: window.localStorage.getItem("shortToken"),
    params: {
      cntConsultationId: "",
    },
    dataPasient: {
      cntConsultationId: "1jdafb5jfhvaaafvjhdfkhafba",
      patientFullName: "Dengan Janji",
      profilePictureUrl: "",
      paymentDate: "2021-06-14T13:35:07.030169",
    },
  }),
  components: {
    "vue-audio": VueAudio,
  },
  methods: {
    takeQueue(value) {
      if (this.getStatusDenganJanji !== true) {
        console.log("ino value", value);
      console.log("ll", this.listPatient);
      //   this.listPatient = this.listPatient.forEach((obj) => {
      //     obj.disabled = true;
      //   });
      this.di = true;
      console.log("PICK PATIENT " + JSON.stringify(value));
      this.params.cntConsultationId = value;
      let doctorEmail = window.localStorage.getItem("email");
      let doctorUsername = window.localStorage.getItem("fullName");
      let keyEmail = "KlinisiaWebDoctorAppEmail";
      // let secUserId = window.localStorage.getItem('secUserId')
      let combineEmail = doctorEmail + keyEmail;
      let combinePass = "KlinisiaWebDoctorAppPass";
      // console.log('combineEmail ' + combineEmail)
      // console.log('combinePass ' + combinePass)
      // console.log('doctorUsername ' + doctorUsername)
      this.$qiscus
        .setUser(combineEmail, combinePass, doctorUsername)
        .then((res) => {
          console.log("qiscus login", res);
          console.log("k");
          this.$store.dispatch("consultation/actBtnStatus", false);
        });
      // this.$qiscus.setUser('guest-1003', 'passkey', 'guest-1003')
      this.$emit("clickedPatient", this.tag);
      this.$store
        .dispatch("consultation/postTakeQueue", this.params)
        .then((response) => {
          //   this.listPatient = this.listPatient.forEach((obj) => {
          //     obj.disabled = false;
          //   });
          this.di = false;
          if (response.data.error !== "") {
            this.$toast.open({
              message: response.data.error,
              position: "top-right",
              type: "error",
              duration: 6000,
            });
          } else {
           
            window.localStorage.setItem(
              "cntConsultationId",
              response.data.payload.consultation.cntConsultationId
            );
            // docDoctorId
            window.localStorage.setItem(
              "docDoctorId",
              response.data.payload.consultation.docDoctorId
            );
            // ptnPatientId
            window.localStorage.setItem(
              "ptnPatientId",
              response.data.payload.consultation.ptnPatientId
            );
          }
        });
      }else{
      // console.log("value dengan janji", value);
      // console.log("list dengan janji", this.listPatient);
      //   this.listPatient = this.listPatient.forEach((obj) => {
      //     obj.disabled = true;
      //   });
      this.di = true;
      console.log("PICK PATIENT " + JSON.stringify(value));
      this.params.cntConsultationId = value;
      let doctorEmail = window.localStorage.getItem("email");
      let doctorUsername = window.localStorage.getItem("fullName");
      let keyEmail = "KlinisiaWebDoctorAppEmail";
      // let secUserId = window.localStorage.getItem('secUserId')
      let combineEmail = doctorEmail + keyEmail;
      let combinePass = "KlinisiaWebDoctorAppPass";
      // console.log('combineEmail ' + combineEmail)
      // console.log('combinePass ' + combinePass)
      // console.log('doctorUsername ' + doctorUsername)
      this.$qiscus
        .setUser(combineEmail, combinePass, doctorUsername)
        .then((res) => {
          console.log("qiscus login", res);
          console.log("k");
          this.$store.dispatch("consultation/actBtnStatus", false);
        });
      // this.$qiscus.setUser('guest-1003', 'passkey', 'guest-1003')
      this.$emit("clickedPatient", this.tag);
      this.$store
        .dispatch("consultation/postTakeQueueWithApp", this.params)
        .then((response) => {
          //   this.listPatient = this.listPatient.forEach((obj) => {
          //     obj.disabled = false;
          //   });
          this.di = false;
          if (response.data.error !== "") {
            this.$toast.open({
              message: response.data.error,
              position: "top-right",
              type: "error",
              duration: 6000,
            });
          } else {
            // let readStatusMenuResponse = response.data.payload.consultation.summaryStatus
            // console.log('ASSESMENT MENU ' + readStatusMenuResponse);
            // if(readStatusMenuResponse) {
            //     this.$emit('clickedPatient', this.tag)
            //     this.$qiscus.chatTarget(window.localStorage.getItem('cntConsultationId')).then(
            //         response => {
            //             this.$store.dispatch('consultation/actQisqusRoomId', response)
            //             // this.params.bodyParams.roomId = response.id
            //             // window.localStorage.setItem('roomId', response.id)

            //             // this.$store.dispatch('consultation/actStartChat', this.params).then(
            //             //     response => {
            //             //         console.log(response)
            //             //     }
            //             // )
            //         }
            //     )
            // }else {
           
            // cntConsultationId
            window.localStorage.setItem(
              "cntConsultationId",
              response.data.payload.consultation.cntConsultationId
            );
            // docDoctorId
            window.localStorage.setItem(
              "docDoctorId",
              response.data.payload.consultation.docDoctorId
            );
            // ptnPatientId
            window.localStorage.setItem(
              "ptnPatientId",
              response.data.payload.consultation.ptnPatientId
            );
          }
        });
        }
    },
    getdataDenganJanji() {
      console.log(this.dataPasient);
      this.pasien.push(this.dataPasient);
      return this.pasien;
    },
    getListPatient() {
     console.log('gag',this.listPatient);
    //  console.log(this.totalPatientList);
      this.listPatient = this.listPatient.forEach((obj, idx) => {
        idx == 0 ? (obj.disabled = false) : (obj.disabled = true);
        obj.profilePictureUrl;
      });
      var data = this.listPatient.map((e) => e.patientFullName);
      this.listPatient.map((e) => e.patientFullName);
      if (data.length > 0) {
        if (this.list.length > 0) {
          for (let i = 0; i < data.length; i++) {
            if (!this.list.includes(data[i])) {
              console.log("dengar");
            }
          }
          this.list = data;
        } else if (this.list.length === 0) {
          this.list = data;
          // console.log("diam");
        }
      } else if (data.length == 0) {
        this.statusPlay = true;
      }
      return this.listPatient;
    },
  },
  computed: {
    totalPatientList() {
      return this.listPatient.length;
    },
    listPatient: {
      get() {
        if (this.getStatusDenganJanji !== true) {
        return this.$store.getters["consultation/getListPatient"];
      } else {
         return this.$store.getters["consultation/getListPatient2"];
       //  return this.getdataDenganJanji();
      }
       
      },
      set() {},
    },
    filteredPatient() {
      // if (this.getStatusDenganJanji !== true) {
      //   return this.getListPatient();
      // } else {
      //   console.log('hjhhh',this.getStatusDenganJanji);
      //    return this.getListPatient();
      //  //  return this.getdataDenganJanji();
      // }
       return this.getListPatient();
    },
    getStatusDenganJanji() {
      return this.$store.getters["consultation/getDenganJanji"];
    },
  },
  mounted() {
    this.getStatusDenganJanji
    this.listPatient
    console.log("Dengan Janji", this.getStatusDenganJanji);
    console.log(this.list);
    // this.takeQueue()
  },
};
</script>

<style scoped>
.v-list {
  height: 65vh;
  background: #edf4fb;
  overflow-y: auto;
}

/* .v-list-item--active{
    background: linear-gradient(to bottom right, #76CAFA 45%, #06FF88 100%);
    background: linear-gradient(to bottom right, #33ccff 0%, #ff99cc 100%);
    color: white;
} */
.v-list-item--inactive {
  background: red;
  color: white;
}
.bgTotalPatient {
  background: #a0cee8;
  font-size: 17px;
  width: 45px;
  color: #073059;
  text-align: center;
  border-radius: 50px;
}
.v-list-item {
  background-color: #edf4fb;
}
.lblTotalPatient {
  color: #2b92e4;
  font-size: 12px;
  margin: 0%;
  padding: 0%;
  font-family: Poppins;
  line-height: 1px;
}
.lblPatient {
  font-size: 20px;
  line-height: 33px;
  margin: 0%;
  padding: 0%;
  color: #073059;
  font-family: Nunito;
  font-weight: bold;
}
.titlePatientList {
  color: #073059;
  font-weight: 800;
  font-size: 14px;
  display: flex;
  align-items: center;
  font-family: Nunito;
}
.subtitlePatientList {
  color: #073059;
  font-size: 12px;
  font-family: Nunito;
}
.disable-patient div {
  color: rgba(7, 48, 89, 0.56);
}
@media only screen and (max-width: 1920px) {
  .v-list {
    height: 60vh;
    background: #edf4fb;
    overflow-y: auto;
  }
  .lblTotalPatient {
    color: #2b92e4;
    font-size: 12px;
    margin: 0%;
    padding: 0%;
    font-family: Poppins;
    line-height: 1px;
  }
  .lblPatient {
    font-size: 20px;
    line-height: 33px;
    margin: 0%;
    padding: 0%;
    color: #073059;
    font-family: Nunito;
    font-weight: bold;
  }
  .lblTotalPatient {
    color: #2b92e4;
    font-size: 12px;
    margin: 0%;
    padding: 0%;
    font-family: Poppins;
    line-height: 1px;
  }
  .titlePatientList {
    color: #073059;
    font-weight: 800;
    font-size: 12px;
    display: flex;
    align-items: center;
    font-family: Nunito;
  }
  .subtitlePatientList {
    color: #073059;
    font-size: 10px;
    font-family: Nunito;
  }
}
</style>