/** * @author [Mochamad Arifin][Tandy Alam][Iman Akbar] * @email
[mochamad.arifin@dwidasa.com][tandy.hutama@dwidasa.com][iman.akbar@dwidasa.com]
* @create date 2021-01-11 12:51:34 * @modify date 2021-05-04 12:51:34 * @desc
[description] */
<template>
  <v-app :style="{
    backgroundImage: 'url(' + require('../assets/bgLogin.png') + ')',
  }">
    <v-container fluid class="ma-0 pa-0">
      <v-row>
        <v-col cols="12" lg="12" class="login-part d-flex align-center justify-center">
          <v-row no-gutters>
            <v-col cols="12" class="login-part d-flex align-center justify-center flex-column">
              <div class="login-wrapper">
                <v-form>
                  <v-container class>
                    <v-row class="flex-column">
                      <v-col>
                        <v-img src="@/assets/klinisia logo final 1.png" class="my-4 imageLogo"></v-img>
                      </v-col>
                      <v-col>
                        <p class="lblGreeting">Selamat Datang</p>

                        <p class="lblSubGreeting my-4">Silahkan cek email untuk password anda</p>
                      </v-col>
                      <v-form class="form1">
                        <v-alert :value="alertAuthError" type="error">
                          <p>{{ valueAlert }}</p>
                        </v-alert>
                        <v-col>
                          <p class="lblInfo">Email address*</p>
                          <v-text-field
                            placeholder="Enter email address"
                            required
                            outlined
                            dense
                            :rules="emailRules"
                            v-model="params.email"
                            v-on:keypress.enter="login"
                          ></v-text-field>
                          <p class="lblInfo">Password*</p>
                          <v-text-field
                            :type="showPass ? 'text' : 'password'"
                            :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                            placeholder="Enter password"
                            hint="At least 8 characters"
                            required
                            outlined
                            counter
                            dense
                            v-model="params.password"
                            :rules="passwordRules"
                            @click:append="showPass = !showPass"
                          ></v-text-field>
                          <p class="lblInfo">Rumah Sakit*</p>
                          <v-select
                            outlined
                            required
                            placeholder="Pilih Rumah Sakit"
                            v-model="params.hospitalId"
                            :items="masterRs"
                            item-text="name"
                            item-value="id"
                            hide-details
                            v-on:keypress.enter="login"
                            dense
                          ></v-select>
                          <v-col cols="5" offset="7" class="marginbtn">
                            <v-btn
                              @click="forgotPass"
                              medium
                              text
                              class="text-capitalize grey--text text--darken-3"
                            >Lupa Password?</v-btn>
                          </v-col>
                          <v-col class="marginbtn">
                            <v-btn
                              block
                              medium
                              depressed
                              @click="login"
                              color="primary"
                              class="text-capitalize"
                            >Masuk</v-btn>
                          </v-col>
                        </v-col>
                      </v-form>
                    </v-row>

                    <v-row justify="center">
                      <v-col>
                        <p class="text-center textversi">V2.8.3-20230605</p>
                      </v-col>
                    </v-row>
                  </v-container>
                </v-form>
              </div>
            </v-col>
            <!-- <v-col cols="12" class="d-flex justify-center">
              <v-footer>
                <div class="primary--text">© Created By Mochamad Arifin.</div>
              </v-footer>
            </v-col>-->
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
// import NotificationServices from "@/services/notificationServices";
import GeneralServices from "@/services/general_services";
export default {
  name: "Login",
  data: () => ({
    isAda: false,
    dataToken: 0,
    colors: [
      "indigo",
      "warning",
      "pink darken-2",
      "red lighten-1",
      "deep-purple accent-4",
    ],
    slides: ["First", "Second", "Third", "Fourth", "Fifth"],
    showPass: false,
    alertAuthError: false,
    valueAlert: "",
    detailFcm: "",
    rumahSakit: [],
    rs: "",
    masterRs: [],
    emailRules: [
      (v) => !!v || "E-mail is required",
      (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
    ],
    passwordRules: [
      (v) => !!v || "Password is required",
      (v) => v.length >= 8 || "Min 8 characters",
    ],
    // bodyParams : {
    //           // fcmToken: this.datafcmToken,
    //           fcmToken: window.localStorage.getItem("tokenfcm"),
    //   },
    params: {
      deviceType: "browser",
      deviceCode: "123",
      userType: "doctor",
      hospitalId: "",
      email: "",
      mobilePhone: "",
      password: "",
    },
    statusMasuk: false,
  }),
  computed: {
    datafcmToken() {
      return this.$store.getters["auth/getDataFcmtoken"];
    },
  },
  methods: {
    getNamaRs() {
      GeneralServices.getHospital().then((res) => {
        console.log(res);
        let data = [];
        data = res.data.payload;
        data.map((e) => {
          this.masterRs.push({
            id: e.hptHospitalId,
            name: e.hospitalName,
          });
        });
      });
    },
    login() {
      if (
        this.params.email != "" &&
        this.params.password != "" &&
        this.params.hospitalId != ""
      ) {
        this.$store
          .dispatch("auth/actDoLogin", this.params)
          .then((response) => {
            window.localStorage.setItem("res", JSON.stringify(response));
            window.localStorage.setItem("email", this.params.email);
            this.$store.dispatch("auth/actDataEmail", this.params.email);
            window.localStorage.setItem(
              "secUserId",
              response.data.payload.secUserId,
            );
            window.localStorage.setItem(
              "fullName",
              response.data.payload.fullName,
            );
            window.localStorage.setItem(
              "timeZoneId",
              response.data.payload.timeZoneId,
            );
            this.$store.dispatch(
              "auth/actDataFullName",
              response.data.payload.fullName,
            );
            window.localStorage.setItem(
              "longToken",
              response.data.payload.jwtToken.longToken.token,
            );
            window.localStorage.setItem(
              "shortToken",
              response.data.payload.jwtToken.shortToken.token,
            );
            this.$store.dispatch(
              "auth/actDataShortToken",
              response.data.payload.jwtToken.shortToken.token,
            );
            window.localStorage.setItem("role", response.data.payload.role);

            if (response.data.payload.features.length > 0) {

              var questionFeature = response.data.payload.features.map(feature => {
                if (feature.featureCode == 'DOC-FRM-ACTION') {
                  return true
                }
              });

              // DOC-FRM-ACTION
              window.localStorage.setItem("questionFeature", questionFeature);

            }
            if (response.data.payload.mustChangePassword) {
              this.$router.push("/forcechangepass");
            } else {
              console.log("start");
              // NotificationServices.getTokenfcm().then((response) => {
              //   console.log("haha", response);
              //   this.dataToken = response.data.payload.length;
              //   if (this.dataToken == 0) {
              //     NotificationServices.deleteTokenfcm().then((response1) => {
              //       console.log(response1);
              //       console.log("berhasil delete");
              //     });
              //     var bodyParams = {
              //       fcmToken: this.datafcmToken,
              //       // fcmToken: window.localStorage.getItem("tokenfcm"),
              //     };
              //     NotificationServices.registerTokenfcm(bodyParams)
              //       .then((response3) => {
              //         console.log("hoho", response3);
              //         window.localStorage.setItem(
              //           "fcm",
              //           response3.data.payload.fcmToken
              //         );
              //         this.$toast.success("Daftar FCM Token Berhasil", {
              //           type: "success",
              //           position: "top-right",
              //           duration: 4000,
              //         });
              //         window.localStorage.setItem("authenticated", true);
              //         this.$router.push("/home");
              //         // window.location.reload();
              //       })
              //       .catch((e) => {
              //         console.log("ERROR " + e);
              //         this.alertAuthError = true;
              //         this.valueAlert =
              //           "Login Gagal, Daftar FCM Token Belum Berhasil";
              //         //  this.$router.push("/login");
              //       });
              //   } else {
              //     var bodyParams2 = {
              //       fcmToken: this.datafcmToken,
              //       // fcmToken: window.localStorage.getItem("tokenfcm"),
              //     };
              //     NotificationServices.registerTokenfcm(bodyParams2)
              //       .then((response4) => {
              //         console.log("hihi", response4);
              //          window.localStorage.setItem(
              //           "fcm",
              //           response4.data.payload.fcmToken
              //         );
              //         this.$toast.success("Daftar FCM Token Berhasil", {
              //           type: "success",
              //           position: "top-right",
              //           duration: 4000,
              //         });
              //         window.localStorage.setItem("authenticated", true);
              //         this.$router.push("/home");
              //         // window.location.reload();
              //       })
              //       .catch((e) => {
              //         console.log("ERROR " + e);
              //         this.alertAuthError = true;
              //         this.valueAlert =
              //           "Login Gagal, Daftar FCM Token Belum Berhasil";
              //         // this.$router.push("/login");
              //       });
              //   }
              // });
              // console.log(this.dataToken);

              window.localStorage.setItem("authenticated", true);
              this.$router.push("/home");
              // window.location.reload();
            }

            // window.localStorage.setItem("authenticated", true);
            // this.$router.push("/home");
            // window.location.reload();
          })
          .catch((e) => {
            console.log("ERROR " + e.response);
            this.alertAuthError = true;
            this.valueAlert = e.response.data.error;
          });
      } else {
        this.alertAuthError = true;
        this.valueAlert =
          "Email, Password dan Rumah Sakit tidak boleh kosong";
      }
    },
    forgotPass() {
      this.$router.push("/forgotpass");
    },
    getMasterrs() {
      this.masterRs.map((element) => {
        this.rumahSakit.push({
          value: element.id,
          text: element.namars,
        });
      });
    },
  },
  mounted() {
    this.getNamaRs();
  },
  created() {
    if (window.localStorage.getItem("authenticated") === "true") {
      this.$router.push("/home");
    }
  },
};
</script>

<style scoped>
.bgColor {
  background: "@/assets/bgLogin.png";
}
.form1 {
  margin-top: -15px;
}
.login-part {
  width: 100%;
  height: 100vh;
}
.marginbtn {
  margin-top: -10px;
}
.login-wrapper {
  width: 520px;
  height: 1200px;
}
.imageLogo {
  width: 180px;
  height: 70px;
}
.lblGreeting {
  position: absolute;
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 26px;
  display: flex;
  align-items: center;
  margin-top: -29px;
  color: #2a8bf2;
}
.lblSubGreeting {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 28px;
  display: flex;
  align-items: center;
  color: #8692a6;
}
.lblInfo {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 19px;
  display: flex;
  align-items: center;
  color: #696f79;
}
.textversi {
  font-family: Inter;
  font-style: normal;
  font-size: 12px;
  line-height: 10px;
  color: #696f79;
  position: relative;
}
</style>
