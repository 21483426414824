/**
 * @author [Mochamad Arifin]
 * @email [mochamad.arifin@dwidasa.com]
 * @create date 2021-01-18 22:19:13
 * @modify date 2021-01-18 22:19:13
 * @desc [description]
 */


<template>
  <v-row>
    <v-col cols="12">
      <v-container fluid fill-height class="py-0">
        <v-row>
          <v-col cols="4">
            <v-combobox
              dense
              placeholder="Category"
              v-model="filterBy"
              :items="category"
              solo
              flat
              hide-details
              class="customComboBox"
            ></v-combobox>
          </v-col>
          <v-col cols="8">
            <v-text-field
              solo
              dense
              flat
              placeholder="Search by Keyword"
              append-icon="search"
              hide-details
              class="customTf"
              v-model="query"
            ></v-text-field>
          </v-col>

          <v-col cols="12">
            <div class="lblTitle ml-3">Notes</div>
          </v-col>
          <v-col cols="12">
            <v-textarea
              maxlength="2000"
              solo
              dense
              flat
              placeholder="Tambahkan Keterangan Untuk Pasien"
              hide-details
              class="customTf chatingbro"
              v-model="indikasi"
              auto-grow
              rows="1"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-col>

    <v-col class="ml-7" cols="12" v-if="chipData.length >= 1">
      <v-row>
        <v-list
          class="ml-4"
          cols="6"
          v-for="(item, key) in chipData"
          :key="key"
        >
          <v-chip
            close
            color="#68FFB7"
            text-color="#073059"
            class="chip-font"
            @click:close="[(item.selectionStatus = false), isChecked(item)]"
            label
          >
            {{ item.laboratoryItemName }}
          </v-chip>
        </v-list>
      </v-row>
    </v-col>

    <v-col cols="12" class="py-0">
      <v-row>
        <v-col>
          <v-list>
            <v-list-item
              v-for="(obj, key) in filteredLaboratory"
              :key="key"
              class="mb-3"
            >
              <v-container fluid fill-height class="contentTable">
                {{ obj.laboratoryGroupName }}
                <v-row align="center" justify="center" class="mt-3">
                  <v-col>
                    <v-list class="scrollableList">
                      <v-row no-gutters>
                        <v-col cols="6" v-for="(item, i) in obj.items" :key="i">
                          <v-checkbox
                            class="mt-2 mx-4 pt-0"
                            v-model="item.selectionStatus"
                            @change="isChecked(item)"
                          >
                            <template v-slot:label>
                              <span class="checkboxLabel">{{
                                item.laboratoryItemName
                              }}</span>
                            </template>
                          </v-checkbox>
                        </v-col>
                      </v-row>
                    </v-list>
                  </v-col>
                </v-row>
              </v-container>
            </v-list-item>
          </v-list>
        </v-col>
      </v-row>
    </v-col>
    <v-row>
      <!-- <alert type="success" :message="message" v-show="isSuccess"></alert>
      <alert type="error" :message="message" v-show="isError"></alert> -->
        <v-alert class="alertnih ml-5" text dense type="success"  v-show="isSuccess">{{message}}</v-alert>
      <v-alert class="alertnih ml-5" text dense type="error"  v-show="isError">{{message}}</v-alert>
    </v-row>
    <v-col>
      <v-btn
        small
        rounded
        dark
        width="120"
        color="#00CC6A"
        class="float-right mr-5"
        @click="submitLaboratory"
        >Submit</v-btn
      >
    </v-col>
  </v-row>
</template>

<script>
import GeneralServices from "@/services/general_services";
import ConsultationServices from "@/services/consultation_services";

export default {
  data: () => ({
    status: [],
    isSuccess: false,
    isError: false,
    message: "",
    query: "",
    filterBy: "All",
    category: ["All"],
    laboratory: [],
    kimiaDarah: {},
    indikasi: "",
    chipData: [],
    laboratorium: [],
  }),
  methods: {
    capitalizeFirstLetter: (str) => {
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
    getLaboratory() {
      let statusMenu = this.getResponseTakeQueue.data.payload.consultation
        .laboratoryStatus;
      console.log("Status Menu " + JSON.stringify(statusMenu));
      if (statusMenu) {
        ConsultationServices.getLaboratory(
          window.localStorage.getItem("cntConsultationId")
        ).then((response) => {
          console.log("response " + JSON.stringify(response));
          this.indikasi = response.data.payload.laboratoryNote;
          this.laboratorium = response.data.payload.groups;
          this.laboratorium.map((groupsname) => {
            this.status = groupsname["items"];
            this.status.map((itemgrup) => {
              this.chipData.push(itemgrup);
            });
          });
        });
      }
      GeneralServices.getMasterLaboratory().then((response) => {
        if (response.data.payload.groups) {
          this.laboratory = response.data.payload.groups;
          this.category.push(
            ...this.laboratory.map((e) => e.laboratoryGroupName)
          );
        }
      });
    },
    isChecked(item) {
      if (item.selectionStatus) {
        this.chipData.push(item);
      } else {
        this.chipData = this.chipData.filter(
          (element) => element.itemOrder !== item.itemOrder
        );
      }
    },
    submitLaboratory() {
      let payload = {
        uriParam: window.localStorage.getItem("cntConsultationId"),
        body: {
          laboratoryFormId: "",
          laboratoryNote: this.indikasi,
          groups: this.laboratory,
        },
      };
      ConsultationServices.postLaboratory(payload)
        .then(() => {
          this.message = "Laboratory Created!";
          (this.isSuccess = true),
            setTimeout(() => {
              this.isSuccess = false;
            }, 5000);
        })
        .catch(() => {
          this.message = "Cannot post multiple checklist";
          (this.isError = true),
            setTimeout(() => {
              this.isError = false;
            }, 5000);
        });
    },
  },
  computed: {
     getStatusDenganJanji() {
      return this.$store.getters["consultation/getDenganJanji"];
    },
    getResponseTakeQueue() {
      if (this.getStatusDenganJanji !== true) {
        return this.$store.getters["consultation/getResponseTakeQueue"];
      } else {
        return this.$store.getters["consultation/getResponseTakeQueueWithApp"];
      }
    },
    filteredLaboratory() {
      if (this.filterBy !== "") {
        if (this.filterBy !== "All" && this.query === "") {
          return this.laboratory.filter((obj) =>
            obj.laboratoryGroupName
              .toLowerCase()
              .includes(this.filterBy.toLowerCase())
          );
        }
      }
      return this.laboratory.filter((obj) => {
        return (
          (obj.items.find((item) => {
            return item.laboratoryItemName
              .toLowerCase()
              .includes(this.query.toLowerCase());
          }) &&
            obj.items.filter((item) => {
              let iman = item.labLaboratoryItemId;
              this.laboratorium.map((element) => {
                element.items.filter(function (item2) {
                  if (item2.labLaboratoryItemId == iman)
                    return (item.selectionStatus = item2.selectionStatus);
                });
              });
            })) ||
          obj.laboratoryGroupName
            .toLowerCase()
            .includes(this.query.toLowerCase())
        );
      });
    },
  },
 mounted() {
    this.getStatusDenganJanji
    this.getLaboratory();
  },
  watch: {
    indikasi: function (newValue) {
      this.indikasi = this.capitalizeFirstLetter(newValue);
    },
  },
};
</script>

<style scoped>
.scrollableList {
  /* height: 19vh; */
  overflow-y: auto;
  border-radius: 10px;
}
.contentTable {
  background: #e9f5fc;
  border-radius: 15px;
}
.customComboBox {
  border: 1px solid rgba(43, 146, 228, 0.55);
  box-sizing: border-box;
  border-radius: 10px;
}
.customTf {
  border: 1px solid rgba(43, 146, 228, 0.55);
  box-sizing: border-box;
  border-radius: 10px;
}
.lblTitle {
  font-weight: bold;
  font-size: 18px;
  line-height: 25px;
  text-align: left;
  color: #2a8bf2;
}
.chatingbro {
  max-height: 120px;
  overflow-y: auto;
  font-size: 18px;
  font-family: Nunito;
}
@media only screen and (max-width: 1366px) {
  .customComboBox {
    border: 1px solid rgba(43, 146, 228, 0.55);
    box-sizing: border-box;
    border-radius: 10px;
    font-size: 14px;
  }
  .customTf {
    border: 1px solid rgba(43, 146, 228, 0.55);
    box-sizing: border-box;
    border-radius: 10px;
    font-size: 14px;
  }
  .lblTitle {
    font-weight: bold;
    font-size: 14px;
    line-height: 25px;
    text-align: left;
    color: #2a8bf2;
    font-family: Nunito;
  }
  .checkboxLabel {
    font-size: 12px;
    line-height: 25px;
    text-align: left;
    font-family: Nunito;
  }
  .chip-font {
    font-size: 13px;
    font-family: Nunito;
  }
  .contentTable {
    background: #e9f5fc;
    border-radius: 15px;
    font-size: 13px;
  }
  .alertnih {
    font-size: 13px;
    font-family: Nunito;
    margin-top: -12;
  }
}
</style>