/**
 * @author [Mochamad Arifin]
 * @email [mochamad.arifin@dwidasa.com]
 * @create date 2021-01-11 22:06:52
 * @modify date 2021-01-11 22:06:52
 * @desc [description]
 */


<template>
  <v-col class="">
    <v-row class="ml-13 mr-13">
      <v-col cols="6" lg="2" class="">
        <v-row align="center" justify="center" class="">
          <v-avatar size="80" class="" style="margin-top: 10px;">
            <img
              :src="dataTakeQueue.data.payload.consultation.profilePictureUrl"
              alt=""
              class="imgphoto"
            />
          </v-avatar>
        </v-row>
      </v-col>

      <v-col cols="6" lg="7" class="">
        <v-row>
          <v-col>
            <div class="patientName mb-1">
              {{ dataTakeQueue.data.payload.consultation.patientFullName }}
            </div>
            <div class="patientGender">
              {{ dataTakeQueue.data.payload.patient.gender }}
            </div>
          </v-col>
        </v-row>
        <v-row class="">
          <v-col cols="12" lg="4" md="4">
            <v-row class="ml-0">
              <img src="@/assets/weightHeight.png" class="imgicon" alt="" />
              <div class="ml-3 bio">
                {{
                  dataTakeQueue.data.payload.patientAssessment.patientWeightKg
                }}
                kg
              </div>
              <!-- <div class="ml-3 bio">/</div>  -->
              <div class="ml-3 bio">
                {{
                  dataTakeQueue.data.payload.patientAssessment.patientHeightCm
                }}
                cm
              </div>
            </v-row>
          </v-col>
          <v-col cols="12" lg="4">
            <v-row class="ml-0">
              <img src="@/assets/birthday.png" class="imgicon" alt="" />
              <!-- <div class="ml-3 bio">{{dataTakeQueue.data.payload.patient.birthDate | birthDate}}</div> -->
              <div class="ml-3 bio">
                {{ dataTakeQueue.data.payload.patientAge.years }} y /
                {{ dataTakeQueue.data.payload.patientAge.months }} m /
                {{ dataTakeQueue.data.payload.patientAge.days }} d
              </div>
            </v-row>
          </v-col>
        </v-row>
      </v-col>

      <v-col cols="12" lg="3" md="3" class="">
        <v-row align="center" co justify="center">
          <!-- Btn Start Chat Style -->
          <v-btn
            v-model="changeButton"
            v-show="showButtonStyle == 1"
            @click="startFinishChat"
            rounded
            :disabled="dataLogin"
            color="#3FEF9A"
            class="styleBtnChat"
          >
            Mulai Chat
          </v-btn>
          <!-- Btn End Chate Style-->
          <v-btn
            v-model="changeButton"
            v-show="showButtonStyle == 2"
            @click="startFinishChat"
            rounded
            class="blue--text styleBtnEndChat"
          >
            Akhir Chat
          </v-btn>
        </v-row>
      </v-col>

      <v-col cols="12" lg="12" class="">
        <alert
          :type="getAlert.type"
          :message="getAlert.message"
          v-show="getAlert.isAlert"
        ></alert>
      </v-col>
    </v-row>

    <v-row class="">
      <v-tabs fixed-tabs slider-color="blue" class="ml-16 mr-16">
        <!-- <v-tab v-for="item in items" :key="item" @click="currentComponent = item">
                    {{ item  }}
                </v-tab> -->
        <v-tab @click="currentComponent = 'Subjective'">
          <div class="titleMenu">Subjective</div>
        </v-tab>

        <v-tab @click="currentComponent = 'Objective'">
          <div class="titleMenu">Objective</div>
        </v-tab>

        <v-tab
          :disabled="getStatusTabSummary"
          @click="currentComponent = 'Summary'"
        >
          <div class="titleMenu">Summary</div>
        </v-tab>

        <v-tab
          :disabled="getStatusTabAssesmennt"
          @click="currentComponent = 'Assesment'"
        >
          <div class="titleMenu">Assessment</div>
        </v-tab>

        <v-tab
          :disabled="getStatusTabPlanning"
          @click="currentComponent = 'Planning'"
        >
          <div class="titleMenu">Planning</div>
        </v-tab>
      </v-tabs>
      <!-- <v-container fluid class="blue" > -->
      <v-tabs-items vertical class="">
        <v-card class="ml-16 mr-16 scroll" flat>
          <v-row>
            <keep-alive>
              <component :is="currentComponent" />
            </keep-alive>
          </v-row>
        </v-card>
      </v-tabs-items>
      <!-- </v-container> -->
    </v-row>
  </v-col>
</template>

<script>
import Subjective from "@/components/Subjective";
import Objective from "@/components/Objective";
import Summary from "@/components/Summary";
import Assesment from "@/components/Assesment";
import Planning from "@/components/Planning";
import moment from "moment";

export default {
  data: () => ({
    currentComponent: "Subjective",
    changeButton: 1,
    showButtonStyle: 1,
    showAlert: false,
    typeAlert: "error",
    message: "",
    lengthMessage: 0,
    patientName: "",
    doctorName: "",
    tfComment: "",
    shortToken: window.localStorage.getItem("shortToken"),
    items: ["Subjective", "Objective", "Summary", "Assesment", "Planning"],
    params: {
      uriParam: "",
      bodyParams: {
        cntConsultationId: "",
        roomId: "",
        patientUserId: "",
        doctorUserId: "",
      },
    },
    dataToChatPage: {
      status: true,
      name: "",
    },
  }),
  components: {
    Subjective,
    Objective,
    Summary,
    Assesment,
    Planning,
  },
  methods: {
    startFinishChat() {
      if (this.changeButton == 1) {
        let summaryStatus =
          this.dataTakeQueue.data.payload.consultation.summaryStatus;
        let assesmentStatus =
          this.dataTakeQueue.data.payload.consultation.assessmentStatus;
        console.log("Status Summary Detail " + summaryStatus);
        console.log("Status Assesment Detail " + assesmentStatus);
        if (summaryStatus) {
          this.changeButton = 2;
          this.showButtonStyle = 2;
          if (assesmentStatus) {
            this.$store.dispatch("consultation/actSetTabSummary", false);
            this.$store.dispatch("consultation/actSetTabAssesment", false);
            this.$store.dispatch("consultation/actSetTabPlanning", false);
          } else {
            this.$store.dispatch("consultation/actSetTabSummary", false);
            this.$store.dispatch("consultation/actSetTabAssesment", false);
          }
        } else {
          this.changeButton = 1;
          this.showButtonStyle = 1;
          this.$store.dispatch("consultation/actSetTabSummary", true);
          this.$store.dispatch("consultation/actSetTabAssesment", true);
        }
        this.showButtonStyle = 2;
        this.changeButton = 2;
        this.$store.dispatch("consultation/actSetTabSummary", false);
        this.$store.dispatch("consultation/actLeftMenu", true);

        //add Body Param
        this.params.bodyParams.cntConsultationId =
          window.localStorage.getItem("cntConsultationId");
        this.params.bodyParams.patientUserId =
          window.localStorage.getItem("ptnPatientId");
        this.params.bodyParams.doctorUserId =
          window.localStorage.getItem("docDoctorId");

        //add Uri Param
        this.params.uriParam = window.localStorage.getItem("cntConsultationId");
        console.log("uri param",this.params.uriParam);
        this.$store.dispatch("consultation/actShowTime", true);
        // console.log(this.getCommentChat);
        this.$qiscus
          .chatTarget(window.localStorage.getItem("cntConsultationId"))
          .then((response) => {
            console.log("kkk", response);
            this.$store.dispatch("consultation/actQisqusRoomId", response);
            this.params.bodyParams.roomId = response.id;
            window.localStorage.setItem("roomId", response.id);
            this.$store
              .dispatch("consultation/actStartChat", this.params)
              .then((response) => {
                const cht = moment
                  .utc(
                    response.data.payload.chatStartDatetime,
                    "YYYY-MM-DD HH:mm"
                  )
                  .format("MMM DD, YYYY HH:mm:ss");
                localStorage.setItem(
                  "endTime",
                  new Date(cht).getTime() + 1800000
                );
                const tim = new Date(cht).getTime() + 1850000;
                console.log("tim", tim);
                this.$store.dispatch("consultation/actStatusTime", tim);
                console.log("start time ", cht);
                console.log(
                  "end time ",
                  new Date(new Date(cht).getTime() + 1800000)
                );
                console.log("end Time: ", localStorage.endTime);
                this.patientName = this.getCommentChat.name;
                this.doctorName = response.data.payload.doctorFullName;
                this.$store.dispatch("auth/actDataFullName", this.doctorName);
    
                this.tfComment =
                  "Selamat datang Bapak/Ibu di layanan telemedicine kami. Apakah saat ini saya terhubung dengan Bapak/Ibu " +
                  this.patientName +
                  " ?" +
                  "Perkenankan saya " +
                  this.doctorName +
                  ", untuk memulai konsultasi ini.";
                if (this.lengthMessage > 0) {
                  console.log("haha");
                } else {
                   this.$store.dispatch("consultation/actBoot", true);
                  this.$qiscus.sendComment(
                    this.getCommentChat.id,
                    this.tfComment
                  );
                }
                console.log(this.doctorName);
              });
          });
      } else if (this.changeButton == 2) {
        // var second  = 0
        //  window.localStorage.setItem('time', second)
        // window.localStorage.removeItem("time");
        // console.log("tutups");
        // localStorage.removeItem("in");
        // window.localStorage.removeItem("in");
        // localStorage.removeItem('endTime')
        //  window.location.reload();
        //Finish Chat
        const dialogContent = {
          isActive: true,
          patientName:
            this.dataTakeQueue.data.payload.consultation.patientFullName,
        };

        this.$store.dispatch("consultation/actDialogStatus", dialogContent);

        //  this.$store.dispatch("consultation/actShowTime", false);
      }
      this.$emit("leftMenuTag", this.changeButton);
    },
    readStatusTabMenu() {
      let assesmentStatus =
        this.dataTakeQueue.data.payload.consultation.summaryStatus;
      console.log("Status Menu Detail " + assesmentStatus);
      if (!assesmentStatus) {
        this.changeButton = 1;
        this.showButtonStyle = 1;
        this.$store.dispatch("consultation/actSetTabSummary", true);
        this.$store.dispatch("consultation/actSetTabAssesment", true);
      } else {
        this.changeButton = 1;
        this.showButtonStyle = 1;
        this.$store.dispatch("consultation/actSetTabSummary", true);
        this.$store.dispatch("consultation/actSetTabAssesment", true);
      }
    },
  },
  computed: {
    getStatusDenganJanji() {
      return this.$store.getters["consultation/getDenganJanji"];
    },
    dataTakeQueue() {
      if (this.getStatusDenganJanji !== true) {
        return this.$store.getters["consultation/getResponseTakeQueue"];
      } else {
        return this.$store.getters["consultation/getResponseTakeQueueWithApp"];
      }
    },
    dataLogin() {
      return this.$store.getters["consultation/getBtnStatus"];
    },
    dataQiscusRoomId() {
      return this.$store.getters["consultation/getResponseQiscus"];
    },
    getStatusTabSummary() {
      return this.$store.getters["consultation/getStatusTabSummary"];
    },
    getStatusTabAssesmennt() {
      return this.$store.getters["consultation/getStatusTabAssesment"];
    },
    getStatusTabPlanning() {
      return this.$store.getters["consultation/getStatusTabPlanning"];
    },
    getResponseFinishChat() {
      return this.$store.getters["consultation/getResponseFinishChat"];
    },
    getMessageStatus() {
      return this.$store.getters["consultation/getMessageStatus"];
    },
    getCommentChat() {
      return this.$store.getters["consultation/getResponseQiscus"];
    },
    getAlert() {
      return this.$store.getters["consultation/getShowAlert"];
    },
  },
  mounted() {
    // this.readStatusTabMenu()
    this.getStatusDenganJanji;
    this.getCommentChat;
    console.log(this.dataLogin);
    console.log("taque", JSON.stringify(this.dataTakeQueue));
    console.log("ini message status", this.getCommentChat);
  },
  watch: {
    getAlert() {
      setTimeout(() => {
        this.getAlert.isAlert = false;
      }, 5000);
    },
    getCommentChat() {
      this.lengthMessage = this.getCommentChat.comments.length;
      console.log("ini message ", this.lengthMessage);
    },
    dataTakeQueue() {
      this.readStatusTabMenu();
    },
  },
};
</script>

<style scoped>
html {
  overflow: hidden !important;
}

.scroll {
  display: flex !important;
  flex-direction: column;
  max-height: 500px;
}
.v-card {
  background: #ffffff;
  overflow-y: scroll;
  overflow-x: hidden;
}
/* .v-card:hover {
    
    overflow-y:scroll;
} */
.v-tab {
  text-transform: none !important;
}
.patientName {
  font-weight: bold;
  font-size: 18px;
  font-family: Nunito;
}
.patientGender {
  color: #777777;
  font-size: 14px;
  font-family: Nunito;
}
.styleBtnChat {
  filter: drop-shadow(-7px 8px 30px rgba(130, 252, 184, 0.630208));
  margin: 30px;
  border: 1px solid #73edb2;
  box-sizing: border-box;
  border-radius: 25px;
}
.titleMenu {
  font-weight: bold;
  font-size: 15px;
  font-family: Nunito;
}
.styleBtnEndChat {
  filter: drop-shadow(-7px 8px 30px rgba(130, 252, 184, 0.630208));
  margin: 30px;
  border: 1px solid #73edb2;
  box-sizing: border-box;
  border-radius: 25px;
}
.bio {
  font-size: 14px;
  color: #414042;
  font-family: Nunito;
  font-weight: 400;
}
.biorow {
  line-height: 1px;
}
.imgicon {
  width: 17px;
  height: 17px;
}
@media only screen and (max-width: 1366px) {
  .patientName {
    font-weight: bold;
    font-size: 14px;
    font-family: Nunito;
  }
  .patientGender {
    color: #777777;
    font-size: 12px;
    font-family: Nunito;
  }
  .imgicon {
    width: 12px;
    height: 12px;
  }
  .bio {
    font-size: 12px;
    color: #414042;
    font-family: Nunito;
    font-weight: 400;
    /* margin-top: -15px; */
  }
  .titleMenu {
    font-weight: bold;
    font-size: 12px;
    font-family: Nunito;
  }
  .scroll {
    display: flex !important;
    flex-direction: column;
    max-height: 375px;
  }
  .v-tab {
    text-transform: none !important;
  }
}
</style>
