/** * @author [Mochamad Arifin] * @email [mochamad.arifin@dwidasa.com] * @create
date 2021-01-13 11:42:51 * @modify date 2021-01-13 11:42:51 * @desc
[description] */

<template>
  <v-row>
    <v-col cols="12">
      <v-card :class="'rounded-xl'" class="">
        <v-row>
          <v-col>
            <p class="lblInfo ml-9" style="margin-top:26px">Informasi Pasien</p>
            <v-divider class="blue"></v-divider>
            <!-- Keluhan Utama -->
            <v-row align="center" justify="center" class="ml-6 mt-7 mr-6">
              <v-col cols="6">
                <div class="lblTitle">Keluhan Utama</div>
              </v-col>
              <v-col cols="6">
                <v-icon class="float-right colorIcon">arrow_drop_down</v-icon>
              </v-col>
              <v-col cols="12" class="ma-0 pa-0">
                <v-textarea
                  required
                  :value="keluhanUtama"
                  class="ma-3 pa-3 chatingbro"
                  readonly
                  hide-details
                  auto-grow
                  rows="1"
                />
              </v-col>
            </v-row>
            <!-- Durasi keluhan -->
            <v-row align="center" justify="center" class="ml-6 mr-6">
              <v-col cols="6">
                <div class="lblTitle">Durasi Keluhan</div>
              </v-col>
              <v-col cols="6">
                <v-icon class="float-right colorIcon">arrow_drop_down</v-icon>
              </v-col>
              <v-col cols="12" class="ma-0 pa-0">
                <v-text-field
                  class="ma-3 pa-3 isi"
                  required
                  :value="durasiKeluhan"
                  readonly
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>
            <!-- tingkatKeparahan -->
            <v-row align="center" justify="center" class="ml-6 mr-6">
              <v-col cols="6">
                <div class="lblTitle">Tingkat Keparahan</div>
              </v-col>
              <v-col cols="6">
                <v-icon class="float-right colorIcon">arrow_drop_down</v-icon>
              </v-col>
              <v-col cols="12" class="ma-0 pa-0">
                <v-text-field
                  class="ma-3 pa-3 isi"
                  required
                  :value="tingkatKeparahan"
                  readonly
                  hide-details
                ></v-text-field>
              </v-col>
            </v-row>
            <!-- penyakitPenyerta -->
            <v-row align="center" justify="center" class="ml-6 mr-6">
              <v-col cols="6">
                <div class="lblTitle">Penyakit Penyerta</div>
              </v-col>
              <v-col cols="6">
                <v-icon class="float-right colorIcon">arrow_drop_down</v-icon>
              </v-col>
              <v-col cols="12" class="ma-0 pa-0">
                <v-select
                  class="ma-3 pa-3 isi"
                  required
                  v-model="penyakitPenyerta"
                  :items="listPenyakitPenyerta"
                  hide-details
                  readonly
                  append-icon=""
                  multiple
                >
                </v-select>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  class="ma-1 pa-1 chatingbro"
                  outlined
                  required
                  v-model="penyakitPenyertaLainnya"
                  readonly
                  hide-details
                  append-icon=""
                  label="Penyakit Penyerta Lainnya"
                  v-show="penyakitPenyerta.includes('DIS-OTHER')"
                  auto-grow
                  rows="1"
                />
              </v-col>
            </v-row>
            <!-- penyakitKeluarga -->
            <v-row align="center" justify="center" class="ml-6 mr-6">
              <v-col cols="6">
                <div class="lblTitle">Penyakit Keluarga</div>
              </v-col>
              <v-col cols="6">
                <v-icon class="float-right colorIcon">arrow_drop_down</v-icon>
              </v-col>
              <v-col cols="12" class="ma-0 pa-0">
                <v-select
                  class="ma-3 pa-3 isi"
                  required
                  v-model="penyakitKeluarga"
                  :items="listPenyakitKeluarga"
                  hide-details
                  readonly
                  append-icon=""
                  multiple
                >
                </v-select>
              </v-col>
              <v-col cols="12">
                <v-textarea
                  class="ma-1 pa-1 chatingbro"
                  outlined
                  required
                  v-model="penyakitKeluargaLainnya"
                  readonly
                  hide-details
                  append-icon=""
                  label="Penyakit Keluarga Lainnya"
                  v-show="penyakitKeluarga.includes('DIS-OTHER')"
                  auto-grow
                  rows="1"
                />
              </v-col>
            </v-row>
            <!-- riwayatOperasi -->
            <v-row align="center" justify="center" class="ml-6 mr-6">
              <v-col cols="6">
                <div class="lblTitle">Riwayat Operasi/Tindakan</div>
              </v-col>
              <v-col cols="6">
                <v-icon class="float-right colorIcon">arrow_drop_down</v-icon>
              </v-col>
              <v-col cols="12" class="ma-0 pa-0">
                <v-textarea
                  class="ma-3 pa-3 chatingbro"
                  required
                  :value="riwayatOperasi"
                  readonly
                  hide-details
                  auto-grow
                  rows="1"
                />
              </v-col>
            </v-row>
            <!-- Alergi -->
            <v-row align="center" justify="center" class="ml-12 mr-6">
              <v-col cols="12" class="ma-0 pa-0">
                <div class="lblTitle">Alergi yang dimiliki</div>
              </v-col>
              <v-col cols="12" class="ma-0 mt-6 pa-0 mb-2">
                <v-row>
                  <v-btn
                    dark
                    class="btntext"
                    color="green"
                    rounded
                    width="100"
                    >Makan</v-btn
                  >
                  <v-select
                    class="ml-5 mr-16 mb-3 pa-0 isi"
                    solo
                    flat
                    required
                    v-model="alergiMakanan"
                    :items="listAlergiMakanan"
                    hide-details
                    multiple
                    readonly
                    append-icon=""
                  >
                  </v-select>
                </v-row>
                <v-row>
                  <v-textarea
                    class="ml-5 mr-16 mb-3 pa-0 customTF isi"
                    outlined
                    required
                    v-model="alergiMakananLainnya"
                    clearable
                    readonly
                    hide-details
                    label="Alergi Makanan Lainnya"
                    v-show="alergiMakanan.includes('ALGFOD-OTHER')"
                    auto-grow
                    rows="1"
                  />
                </v-row>
              </v-col>

              <v-col cols="12" class="ma-0 pa-0 mb-2" >
                <v-row>
                  <v-btn
                    class="btntext"
                    dark
                    color="primary"
                    rounded
                    
                    width="100"
                    >Obat</v-btn
                  >
                  <v-select
                    class="ml-5 mr-16 mb-3 pa-0 isi"
                    solo
                    flat
                    required
                    v-model="alergiObat"
                    :items="listAlergiObat"
                    hide-details
                    multiple
                    readonly
                    append-icon=""
                  >
                  </v-select>
                </v-row>
                <v-row>
                  <v-textarea
                    class="ml-5 mr-16 mb-3 pa-0 isi"
                    outlined
                    required
                    v-model="alergiObatLainnya"
                    clearable
                    readonly
                    hide-details
                    label="Alergi Obat Lainnya"
                    v-show="alergiObat.includes('ALGMED-OTHER')"
                    auto-grow
                    rows="1"
                  />
                </v-row>
              </v-col>

              <v-col cols="12" class="ma-0 pa-0 mb-2">
                <v-row>
                  <v-btn
                    dark
                    class="btntext"
                    color="yellow darken-2"
                    rounded
                    
                    width="100"
                    >Other</v-btn
                  >
                  <v-select
                    class="ml-5 mr-16 mb-3 pa-0 isi"
                    solo
                    flat
                    required
                    v-model="alergiLainnya"
                    :items="listAlergiLainnya"
                    hide-details
                    multiple
                    readonly
                    append-icon=""
                  >
                  </v-select>
                </v-row>
                <v-row>
                  <v-textarea
                    class="ml-5 mr-16 mb-3 pa-0 isi"
                    outlined
                    required
                    v-model="alergiLainnyaLainnya"
                    clearable
                    readonly
                    hide-details
                    label="Alergi Lainnya"
                    v-show="alergiLainnya.includes('ALGOTH-OTHER')"
                    auto-grow
                    rows="1"
                  />
                </v-row>
              </v-col>
            </v-row>

            <v-divider class="ml-8 mr-9  mt-10 grey"></v-divider>
            <!-- Obat -->
            <v-row align="center" justify="center" class="ml-6 mr-6 mt-10">
              <v-col cols="6">
                <div class="lblTitle">Obat Sedang Dikonsumsi/Obat Rutin</div>
              </v-col>
              <v-col cols="6">
                <v-icon class="float-right colorIcon">arrow_drop_down</v-icon>
              </v-col>
              <v-col cols="12" class="ma-0 pa-0">
                <v-textarea
                  class="ma-3 pa-3 chatingbro"
                  required
                  :value="obat"
                  readonly
                  hide-details
                  append-icon=""
                  auto-grow
                  rows="1"
                />
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
    <v-col cols="12" class="mt-9">
      <v-card>
        <v-row align="center" justify="center" class="ma-0 pa-0">
          <v-col cols="12" lg="3">
            <v-combobox
              v-model="params.body.docDoctorMedicalSpecialistId"
              :items="items"
              outlined
              style="font-size: 13px"
              dense
              hide-details
            ></v-combobox>
          </v-col>
          <v-col cols="12" lg="4" class="">
            <v-text-field
              v-model="query"
              outlined
              dense
              style="font-size: 13px"
              placeholder="Search by Keyword"
              append-icon="search"
              hide-details
            ></v-text-field>
          </v-col>
          <v-col cols="12" lg="2">
            <v-dialog
              ref="dialogstart"
              v-model="startdatemodal"
              :return-value.sync="startdate"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="startdate"
                  placeholder="Start Date"
                  outlined
                  readonly
                  dense
                  style="font-size: 13px"
                  hide-details
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker v-model="startdate" scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="startdatemodal = false">
                  Cancel
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="
                    [$refs.dialogstart.save(startdate), getHistoryByDate()]
                  "
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-dialog>
          </v-col>
          <v-col cols="12" lg="2" class="">
            <v-dialog
              ref="dialogend"
              v-model="enddatemodal"
              :return-value.sync="enddate"
              persistent
              width="290px"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-text-field
                  v-model="enddate"
                  placeholder="End Date"
                  outlined
                  dense
                  readonly
                  style="font-size: 13px"
                  hide-details
                  v-bind="attrs"
                  v-on="on"
                ></v-text-field>
              </template>
              <v-date-picker v-model="enddate" scrollable>
                <v-spacer></v-spacer>
                <v-btn text color="primary" @click="enddatemodal = false">
                  Cancel
                </v-btn>
                <v-btn
                  text
                  color="primary"
                  @click="[$refs.dialogend.save(enddate), getHistoryByDate()]"
                >
                  OK
                </v-btn>
              </v-date-picker>
            </v-dialog>
          </v-col>
          <!-- <v-col cols="12" class="ma-0 pa-0">
            <v-divider class="blue"></v-divider>
          </v-col> -->
        </v-row>
        
        <v-row align="center" justify="center" class="mt-3">
          <v-col>
            <v-list>
              <v-list-item v-for="(history, key) in filteredHistory" :key="key">
                <v-col cols="1">
                  <div></div>
                </v-col>
                <v-col cols="11">
                  <v-row>
                    <v-col cols="3">
                      <v-row>
                        <v-col cols="8" class="ml-3 ma-0 pa-0">
                          <div class="LblDate">
                            {{
                              history.consultationStartDatetime
                                | patientHistoryDate
                            }}
                          </div>
                          <div class="lblTime">
                            pk
                            {{
                              history.consultationStartDatetime
                                | patientHistoryTime
                            }}
                            -
                            {{
                              history.consultationEndDatetime
                                | patientHistoryTime
                            }}
                          </div>
                        </v-col>
                        <v-col cols="3" class="ma-0 pa-0">
                          <img
                            class="float-right"
                            src="@/assets/separator.png"
                            alt=""
                          />
                        </v-col>
                      </v-row>
                    </v-col>

                    <v-col cols="3">
                      <v-row>
                        <v-col cols="8" class="ml-3 ma-0 pa-0">
                          <div class="lblStyleTitle">Konsultasi</div>
                          <div
                            v-if="
                              history.appointmentType === 'withoutAppointment'
                            "
                          >
                            <p class="lblStyleSubtitle">Tanpa Janji</p>
                          </div>
                           <div
                            v-else
                          >
                            <p class="lblStyleSubtitle">Dengan Janji</p>
                          </div>
                          <div class="lblStatus">
                            {{ history.consultationStatus }}
                          </div>
                        </v-col>

                        <v-col cols="3" class="ma-0 pa-0">
                          <img
                            class="float-right"
                            src="@/assets/separator.png"
                            alt=""
                          />
                        </v-col>
                      </v-row>
                    </v-col>
                    <v-col cols="3">
                      <div class="lblStyleTitle">Dokter</div>
                      <div class="lblStyleSubtitle">
                        {{ history.doctorFullName }}
                      </div>
                    </v-col>
                    <v-col cols="3">
                      <v-btn
                        text
                        class="btnSummary"
                        @click="getSumary(history)"
                      >
                        Summary
                      </v-btn>
                    </v-col>

                    <v-col cols="12">
                      <v-row>
                        <v-col> </v-col>
                        <v-col class="ma-0 pa-0">
                        </v-col>
                      </v-row>
                    </v-col>
                  </v-row>

                  <v-divider class="blue"></v-divider>
                </v-col>
              </v-list-item>
            </v-list>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
    <v-dialog v-model="dialogSumary" width="1200" :retain-focus="false">
      <v-card width="1200" class="cardDialog">
        <v-card-title class="ml-7">
          <h3>
            Informasi Pasien Tanggal {{ tanggal | patientHistoryDate }} , pk
            {{ jamawal | patientHistoryTime }}
            -
            {{ jamAkhir | patientHistoryTime }}
          </h3>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text width="1200">
          <v-row class="ml-7 mt-2">
            <h2 class="lblStyleTitle">Dokter :</h2>
            <div class="lblStyleSubtitle">
              {{ dokterName }}
            </div>
          </v-row>
          <!-- Keluhan Utama -->
          <v-row align="center" justify="center" class="ml-6 mt-7 mr-6">
            <v-col cols="6">
              <div class="lblTitle">Keluhan Utama</div>
            </v-col>
            <v-col cols="6">
              <v-icon class="float-right colorIcon">arrow_drop_down</v-icon>
            </v-col>
            <v-col cols="12" class="ma-0 pa-0">
              <v-text-field
                class="ma-3 pa-3 isi"
                required
                :value="keluhanUtamaSumary"
                readonly
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
          <!-- Durasi keluhan -->
          <v-row align="center" justify="center" class="ml-6 mr-6">
            <v-col cols="6">
              <div class="lblTitle">Durasi Keluhan</div>
            </v-col>
            <v-col cols="6">
              <v-icon class="float-right colorIcon">arrow_drop_down</v-icon>
            </v-col>
            <v-col cols="12" class="ma-0 pa-0">
              <v-text-field
                class="ma-3 pa-3 isi"
                required
                :value="durasiKeluhan"
                readonly
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
          <!-- tingkatKeparahan -->
          <v-row align="center" justify="center" class="ml-6 mr-6">
            <v-col cols="6">
              <div class="lblTitle">Tingkat Keparahan</div>
            </v-col>
            <v-col cols="6">
              <v-icon class="float-right colorIcon">arrow_drop_down</v-icon>
            </v-col>
            <v-col cols="12" class="ma-0 pa-0">
              <v-text-field
                class="ma-3 pa-3 isi"
                required
                :value="tingkatKeparahan"
                readonly
                hide-details
              ></v-text-field>
            </v-col>
          </v-row>
          <!-- penyakitPenyerta -->
          <v-row align="center" justify="center" class="ml-6 mr-6">
            <v-col cols="6">
              <div class="lblTitle">Penyakit Penyerta</div>
            </v-col>
            <v-col cols="6">
              <v-icon class="float-right colorIcon">arrow_drop_down</v-icon>
            </v-col>
            <v-col cols="12" class="ma-0 pa-0">
              <v-select
                class="ma-3 pa-3 isi"
                required
                v-model="penyakitPenyerta"
                :items="listPenyakitPenyerta"
                hide-details
                readonly
                append-icon=""
                multiple
              >
              </v-select>
            </v-col>
            <v-col cols="12">
              <v-textarea
                class="ma-1 pa-1 chatingbro"
                outlined
                required
                v-model="penyakitPenyertaLainnya"
                readonly
                hide-details
                append-icon=""
                label="Penyakit Penyerta Lainnya"
                v-show="penyakitPenyerta.includes('DIS-OTHER')"
                auto-grow
                rows="1"
              />
            </v-col>
          </v-row>
          <!-- penyakitKeluarga -->
          <v-row align="center" justify="center" class="ml-6 mr-6">
            <v-col cols="6">
              <div class="lblTitle">Penyakit Keluarga</div>
            </v-col>
            <v-col cols="6">
              <v-icon class="float-right colorIcon">arrow_drop_down</v-icon>
            </v-col>
            <v-col cols="12" class="ma-0 pa-0">
              <v-select
                class="ma-3 pa-3 isi"
                required
                v-model="penyakitKeluarga"
                :items="listPenyakitKeluarga"
                hide-details
                readonly
                append-icon=""
                multiple
              >
              </v-select>
            </v-col>
            <v-col cols="12">
              <v-textarea
                class="ma-1 pa-1 chatingbro"
                outlined
                required
                v-model="penyakitKeluargaLainnya"
                readonly
                hide-details
                append-icon=""
                label="Penyakit Keluarga Lainnya"
                v-show="penyakitKeluarga.includes('DIS-OTHER')"
                auto-grow
                rows="1"
              />
            </v-col>
          </v-row>
          <!-- riwayatOperasi -->
          <v-row align="center" justify="center" class="ml-6 mr-6">
            <v-col cols="6">
              <div class="lblTitle">Riwayat Operasi/Tindakan</div>
            </v-col>
            <v-col cols="6">
              <v-icon class="float-right colorIcon">arrow_drop_down</v-icon>
            </v-col>
            <v-col cols="12" class="ma-0 pa-0">
              <v-textarea
                class="ma-3 pa-3 chatingbro"
                required
                :value="riwayatOperasi"
                readonly
                hide-details
                auto-grow
                rows="1"
              />
            </v-col>
          </v-row>
          <!-- Alergi -->
          <v-row align="center" justify="center" class="ml-12 mr-6">
            <v-col cols="12" class="ma-0 pa-0">
              <div class="lblTitle">Alergi yang dimiliki</div>
            </v-col>
            <v-col cols="12" class="ma-0 mt-6 pa-0">
              <v-row>
                <v-btn
                  dark
                  class="btntext"
                  color="green"
                  rounded
                  large
                  width="100"
                  >Makan</v-btn
                >
                <v-select
                  class="ml-5 mr-16 mb-3 pa-0 isi"
                  solo
                  flat
                  required
                  v-model="alergiMakanan"
                  :items="listAlergiMakanan"
                  hide-details
                  multiple
                  readonly
                  append-icon=""
                >
                </v-select>
              </v-row>
              <v-row>
                <v-textarea
                  class="ml-5 mr-16 mb-3 pa-0 customTF isi"
                  outlined
                  required
                  v-model="alergiMakananLainnya"
                  clearable
                  readonly
                  hide-details
                  label="Alergi Makanan Lainnya"
                  v-show="alergiMakanan.includes('ALGFOD-OTHER')"
                  auto-grow
                  rows="1"
                />
              </v-row>
            </v-col>
            <v-col cols="12" class="ma-0 pa-0">
              <v-row>
                <v-btn
                  class="btntext"
                  dark
                  color="primary"
                  rounded
                  large
                  width="100"
                  >Obat</v-btn
                >
                <v-select
                  class="ml-5 mr-16 mb-3 pa-0 isi"
                  solo
                  flat
                  required
                  v-model="alergiObat"
                  :items="listAlergiObat"
                  hide-details
                  multiple
                  readonly
                  append-icon=""
                >
                </v-select>
              </v-row>
              <v-row>
                <v-textarea
                  class="ml-5 mr-16 mb-3 pa-0 isi"
                  outlined
                  required
                  v-model="alergiObatLainnya"
                  clearable
                  readonly
                  hide-details
                  label="Alergi Obat Lainnya"
                  v-show="alergiObat.includes('ALGMED-OTHER')"
                  auto-grow
                  rows="1"
                />
              </v-row>
            </v-col>

            <v-col cols="12" class="ma-0 pa-0">
              <v-row>
                <v-btn
                  dark
                  class="btntext"
                  color="yellow darken-2"
                  rounded
                  large
                  width="100"
                  >Other</v-btn
                >
                <v-select
                  class="ml-5 mr-16 mb-3 pa-0 isi"
                  solo
                  flat
                  required
                  v-model="alergiLainnya"
                  :items="listAlergiLainnya"
                  hide-details
                  multiple
                  readonly
                  append-icon=""
                >
                </v-select>
              </v-row>
              <v-row>
                <v-textarea
                  class="ml-5 mr-16 mb-3 pa-0 isi"
                  outlined
                  required
                  v-model="alergiLainnyaLainnya"
                  clearable
                  readonly
                  hide-details
                  label="Alergi Lainnya"
                  v-show="alergiLainnya.includes('ALGOTH-OTHER')"
                  auto-grow
                  rows="1"
                />
              </v-row>
            </v-col>
          </v-row>
          <!-- Obat -->
          <v-row align="center" justify="center" class="ml-6 mr-6">
            <v-col cols="6">
              <div class="lblTitle">Obat Sedang Dikonsumsi/Obat Rutin</div>
            </v-col>
            <v-col cols="6">
              <v-icon class="float-right colorIcon">arrow_drop_down</v-icon>
            </v-col>
            <v-col cols="12" class="ma-0 pa-0">
              <v-textarea
                class="ma-3 pa-3 chatingbro"
                required
                :value="obat"
                readonly
                hide-details
                append-icon=""
                auto-grow
                rows="1"
              />
            </v-col>
          </v-row>
          <!-- Catatan Dokter -->
          <v-row class="ml-7">
            <v-card width="95%" class="cardSumary">
              <v-card-title>
                <h4>Catatan Dokter</h4>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text>
                <h3 class="lblTitle">Diagnosis Utama</h3>
                <div v-for="(chipUtama, key) in diagnosisUtama" :key="key">
                  <p class="mt-2">{{ chipUtama.value }}</p>
                </div>

                <div v-if="!diagnosisMainNote">
                  <p class="mt-2">Catatan : Tidak Ada</p>
                </div>
                <div v-else>
                  <p class="mt-2">Catatan : {{ diagnosisMainNote }}</p>
                </div>

                <h3 class="lblTitle">Diagnosis Lainnya</h3>
                <div v-for="(chipLainnya, key) in diagnosisLainnya" :key="key">
                  <p class="mt-2">{{ chipLainnya.value }}</p>
                </div>
                <div v-if="!diagnosisOtherNote">
                  <p class="mt-2">Catatan : Tidak Ada</p>
                </div>
                <div v-else>
                  <p class="mt-2">Catatan : {{ diagnosisOtherNote }}</p>
                </div>

                <h3 class="lblTitle">Saran Lainnya</h3>

                <div v-if="saranLainnya == null">
                  <p class="mt-2" v-show="true">Tidak Ada</p>
                </div>
                <div
                  v-if="
                    saranLainnya != null &&
                    activityStatus == false &&
                    dietStatus == false
                  "
                >
                  <p v-show="true" class="mt-2">Tidak Ada</p>
                </div>
                <div v-if="activityStatus">
                  <p class="mt-2" v-show="true">Aktifitas : {{ activity }}</p>
                </div>
                <div v-if="dietStatus">
                  <p class="mt-2" v-show="true">Diet : {{ diet }}</p>
                </div>

                <h3 class="lblTitle">Notes</h3>

                <div v-if="notes == null">
                  <p class="mt-2">Tidak Ada</p>
                </div>
                <div v-else>
                  <p class="mt-2">{{ notes }}</p>
                </div>
              </v-card-text>
            </v-card>
          </v-row>
          <!-- History Obat -->
          <v-row class="ml-7 mt-5">
            <v-card width="95%" class="cardSumary">
              <v-card-title>
                <h4>Rekomendasi Obat</h4>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text>
                <p class="lblTitle">
                  List Obat Tanggal {{ tanggal | patientHistoryDate }}
                </p>
                <div v-if="datainstruksi == null">
                  <p>Tidak Ada</p>
                </div>
                <div v-else>
                  <div v-for="(obatnih, key) in isiobat" :key="key">
                    <h3 class="lblTitle">
                      <v-icon small class="mr-1" color="green darken-2">
                        mdi-check</v-icon
                      >{{ obatnih.namaobat }} {{ obatnih.size }}
                      {{ obatnih.sizeunit }}, TOTAL {{ obatnih.totalobat }}
                      {{ obatnih.typeobat }}
                    </h3>
                    <div class="mt-1">
                      <p>
                        Instruksi : Di {{ obatnih.htuse }},
                        {{ obatnih.instruksi }},
                      </p>
                      <p>
                        {{ obatnih.sigmaone }} x {{ obatnih.sigmatwo }}
                        {{ obatnih.typeobat }} Per {{ obatnih.period }}
                      </p>
                    </div>
                    <v-divider></v-divider>
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </v-row>
          <!-- Laboratorium -->
          <v-row class="ml-7 mt-5">
            <v-card width="95%" class="cardSumary">
              <v-card-title>
                <h4>Test Laboratorium</h4>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text>
                <p class="lblTitle">Pemeriksaan Yang Diminta Dokter Adalah</p>
                <div v-if="statusLab == null">
                  <p>Tidak Ada</p>
                </div>
                <div v-else>
                  <p class="lblTitle">Catatan : {{ labotatoryNote }}</p>
                  <div v-for="(labnih, key) in laboratoryGroup" :key="key">
                    <h3 class="lblTitle">
                      <v-icon small class="mr-1" color="green darken-2">
                        mdi-check</v-icon
                      >
                      {{ labnih.namaGrup }}
                    </h3>
                    <p>{{ labnih.item }}</p>
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </v-row>
          <!-- radiologi -->
          <v-row class="ml-7 mt-5">
            <v-card width="95%" class="cardSumary">
              <v-card-title>
                <h4>Radiologi</h4>
              </v-card-title>
              <v-divider></v-divider>
              <v-card-text>
                <p class="lblTitle">Pemeriksaan Yang Diminta Dokter Adalah</p>
                <div v-if="stastusradiologi == null">
                  <p>Tidak Ada</p>
                </div>
                <div v-else>
                  <p class="lblTitle">Indikasi : {{ radiologiNote }}</p>
                  <div v-for="(rad, key) in radiologiGroup" :key="key">
                    <h3 class="lblTitle">
                      <v-icon small class="mr-1" color="green darken-2">
                        mdi-check</v-icon
                      >
                      {{ rad.namaGrup }}
                    </h3>
                    <p>{{ rad.item }}</p>
                  </div>
                </div>
              </v-card-text>
            </v-card>
          </v-row>
          <v-row class="ml-5 mt-3">
            <v-btn
              dark
              class="btntext ml-4"
              color="red"
              rounded
              large
              v-model="dialogSumary"
              width="100"
              @click="closeDialog"
              >Close</v-btn
            >
          </v-row>
        </v-card-text>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
// import moment from 'moment';
import PharmacyServices from "@/services/pharmacy_services";
import ConsultationSummary from "@/services/consultation_services";
import ConsultationDetailobat from "@/services/consultation_services";
import ConsultationDetaillab from "@/services/consultation_services";
import ConsultationDetailrad from "@/services/consultation_services";
export default {
  data: () => ({
    items: ["Umum"],
    startdatemodal: false,
    enddatemodal: false,
    startdate: "",
    enddate: "",
    query: "",
    params: {
      param: "",
      body: {
        docDoctorMedicalSpecialistId: "Umum",
        searchKeyWord: "",
        startDate: "",
        endDate: "",
      },
    },
    keluhanUtama: "",
    durasiKeluhan: [],
    tingkatKeparahan: [],
    penyakitPenyerta: [],
    listPenyakitPenyerta: [],
    penyakitPenyertaLainnya: "",
    penyakitKeluarga: [],
    listPenyakitKeluarga: [],
    penyakitKeluargaLainnya: "",
    riwayatOperasi: [],
    alergiMakanan: [],
    listAlergiMakanan: [],
    alergiMakananLainnya: "",
    alergiObat: [],
    listAlergiObat: [],
    alergiObatLainnya: "",
    alergiLainnya: [],
    listAlergiLainnya: [],
    alergiLainnyaLainnya: "",
    obat: "",
    sumaryHistory: [],
    listHistory: [],
    tempSumary: "",
    tanggal: "",
    jamawal: "",
    jamAkhir: "",
    dokterName: "",
    keluhanUtamaSumary: "",
    dialogSumary: false,
    diagnosisUtama: [],
    diagnosisLainnya: [],
    diagnosisMainNote: "",
    diagnosisOtherNote: "",
    detail: [],
    icd: [],
    saranLainnya: null,
    activityStatus: false,
    dietStatus: false,
    activity: "",
    diet: "",
    pharmasy: [],
    isiobat: [],
    baseItemsObat: [],
    itemPeriod: [],
    baseItemHtu: [],
    baseItemUnit: [],
    itemInstruction: [],
    selectedType: [],
    selectedHtu: [],
    selectedPeriod: [],
    selectedItem: [],
    datainstruksi: null,
    statusLab: null,
    dataLab: [],
    labotatoryNote: "",
    laboratoryGroup: [],
    laboratoryItem: [],
    group: [],
    stastusradiologi: null,
    radiologiNote: "",
    radiologiGroup: [],
    radiologiItem: [],
    groupradiologi: [],
    notes: "",
  }),
  methods: {
    nameObat() {
      PharmacyServices.getMasterLovs().then((response) => {
        console.log("ini response master obat", response);
        this.baseItemsObat = response.data.payload["medicine-type"];
        this.itemPeriod = response.data.payload["period-type"]; //flag to change
        this.baseItemHtu = response.data.payload["how-to-use-type"]; //flag to change
        this.baseItemUnit = response.data.payload["unit-type"];
        this.itemInstruction = response.data.payload["instruction-type"];
      });
    },
    getSumary(key) {
      this.dialogSumary = true;
      this.tempHistory = key.cntConsultationId;
      this.tanggal = key.consultationStartDatetime;
      this.jamawal = key.consultationStartDatetime;
      this.jamAkhir = key.consultationEndDatetime;
      this.dokterName = key.doctorFullName;
      console.log("ini response consultation id", this.tempHistory);
      ConsultationSummary.doctorSummary(this.tempHistory).then((response) => {
        console.log("ini response dokter sumary", JSON.stringify(response));
        this.sumaryHistory = response.data.payload;
        let patient = response.data.payload;
        console.log("ini sumary", this.sumaryHistory);
        this.keluhanUtamaSumary = this.sumaryHistory.mainComplaint;
        this.obat = this.sumaryHistory.currentMedicine;
        if (patient.assessmentAnswers.length !== 0) {
          let durasiKeluhan = patient.assessmentAnswers.find(
            (a) => a.cntMstQuestionId == "ASSNA-PRD"
          ).optionSelectedIds;
          let tingkatKeparahan = patient.assessmentAnswers.find(
            (a) => a.cntMstQuestionId == "ASSNA-LVL"
          ).optionSelectedIds;
          let penyakitPenyerta = patient.assessmentAnswers.find(
            (a) => a.cntMstQuestionId == "ASSNA-OWNDIS"
          );
          let penyakitKeluarga = patient.assessmentAnswers.find(
            (a) => a.cntMstQuestionId == "ASSNA-FAMDIS"
          );
          let riwayatOperasi = patient.assessmentAnswers.find(
            (a) => a.cntMstQuestionId == "ASSNA-SGR"
          );
          let alergiMakanan = patient.assessmentAnswers.find(
            (a) => a.cntMstQuestionId == "ASSNA-ALGFOD"
          );
          let alergiObat = patient.assessmentAnswers.find(
            (a) => a.cntMstQuestionId == "ASSNA-ALGMED"
          );
          let alergiLainnya = patient.assessmentAnswers.find(
            (a) => a.cntMstQuestionId == "ASSNA-ALGOTH"
          );

          this.$store
            .dispatch("consultation/actSummaryQuestion")
            .then((resolve) => {
              let qDurasiKeluhan = resolve.data.payload.questions.find(
                (q) => q.cntMstQuestionId == "ASSNA-PRD"
              ).options;
              let qtingkatKeparahan = resolve.data.payload.questions.find(
                (q) => q.cntMstQuestionId == "ASSNA-LVL"
              ).options;
              let qpenyakitPenyerta = resolve.data.payload.questions.find(
                (q) => q.cntMstQuestionId == "ASSNA-OWNDIS"
              ).options;
              let qpenyakitKeluarga = resolve.data.payload.questions.find(
                (q) => q.cntMstQuestionId == "ASSNA-FAMDIS"
              ).options;
              let qriwayatOperasi = resolve.data.payload.questions.find(
                (q) => q.cntMstQuestionId == "ASSNA-SGR"
              ).options;
              let qalergiMakanan = resolve.data.payload.questions.find(
                (q) => q.cntMstQuestionId == "ASSNA-ALGFOD"
              ).options;
              let qalergiObat = resolve.data.payload.questions.find(
                (q) => q.cntMstQuestionId == "ASSNA-ALGMED"
              ).options;
              let qalergiLainnya = resolve.data.payload.questions.find(
                (q) => q.cntMstQuestionId == "ASSNA-ALGOTH"
              ).options;

              this.durasiKeluhan = qDurasiKeluhan
                .map((element) => {
                  return durasiKeluhan.includes(element.cntMstAnswerOptionId)
                    ? element.optionDescription
                    : "";
                })
                .filter((e) => e != "");

              this.tingkatKeparahan = qtingkatKeparahan
                .map((element) => {
                  return tingkatKeparahan.includes(element.cntMstAnswerOptionId)
                    ? element.optionDescription
                    : "";
                })
                .filter((e) => e != "");

              qpenyakitPenyerta.map((element) =>
                this.listPenyakitPenyerta.push({
                  value: element.optionCode,
                  text: element.optionDescription,
                })
              );
              this.penyakitPenyerta = penyakitPenyerta.optionSelectedIds;
              this.penyakitPenyertaLainnya = penyakitPenyerta.optionNote;

              qpenyakitKeluarga.map((element) =>
                this.listPenyakitKeluarga.push({
                  value: element.optionCode,
                  text: element.optionDescription,
                })
              );
              this.penyakitKeluarga = penyakitKeluarga.optionSelectedIds;
              this.penyakitKeluargaLainnya = penyakitKeluarga.optionNote;

              this.riwayatOperasi =
                riwayatOperasi.optionNote.trim() !== ""
                  ? riwayatOperasi.optionNote
                  : qriwayatOperasi
                      .map((element) => {
                        return riwayatOperasi.optionSelectedIds.includes(
                          element.cntMstAnswerOptionId
                        )
                          ? element.optionDescription
                          : "";
                      })
                      .filter((e) => e != "");

              qalergiMakanan.map((element) =>
                this.listAlergiMakanan.push({
                  value: element.optionCode,
                  text: element.optionDescription,
                })
              );
              this.alergiMakanan = alergiMakanan.optionSelectedIds;
              this.alergiMakananLainnya = alergiMakanan.optionNote;

              qalergiObat.map((element) =>
                this.listAlergiObat.push({
                  value: element.optionCode,
                  text: element.optionDescription,
                })
              );
              this.alergiObat = alergiObat.optionSelectedIds;
              this.alergiObatLainnya = alergiObat.optionNote;

              qalergiLainnya.map((element) =>
                this.listAlergiLainnya.push({
                  value: element.optionCode,
                  text: element.optionDescription,
                })
              );
              this.alergiLainnya = alergiLainnya.optionSelectedIds;
              this.alergiLainnyaLainnya = alergiLainnya.optionNote;
            });
        }
      });
      ConsultationSummary.getDetail(this.tempHistory).then((response) => {
        console.log("ini respon detail", response);
        this.detail = response.data.payload.diagnosisDto;
        this.diagnosisMainNote = this.detail.diagnosisMainNote;
        this.notes = response.data.payload.doctorAssessment.doctorNote;
        this.diagnosisOtherNote = this.detail.diagnosisOtherNote;

        this.icd = this.detail.detailsMap.main;
        this.icd.map((data) => {
          this.diagnosisUtama.push({
            value: data.icdName,
          });
        });
        let icdOther = this.detail.detailsMap.other;
        icdOther.map((data2) => {
          this.diagnosisLainnya.push({
            value: data2.icdName,
          });
        });

        // this.datainstruksi = response.data.payload.prescriptionAll;

        this.saranLainnya = response.data.payload.suggestion;
        this.activityStatus = response.data.payload.suggestion.activityStatus;
        this.dietStatus = response.data.payload.suggestion.dietStatus;
        this.activity = response.data.payload.suggestion.activityNote;
        this.diet = response.data.payload.suggestion.dietNote;
        console.log("statusLab", this.statusLab);
        console.log(this.datainstruksi);
        console.log(this.stastusradiologi);
      });

      ConsultationDetaillab.getDetail(this.tempHistory).then((response1) => {
        this.statusLab = response1.data.payload.laboratoryForm;

        // this.statusLab= response.data.payload.laboratoryForm
        this.labotatoryNote = this.statusLab.laboratoryNote;
        this.group = this.statusLab.groups;
        this.group.map((datagrup) => {
          this.laboratoryGroup.push({
            namaGrup: datagrup.laboratoryGroupName,
            item: datagrup.items.map((e) => e.laboratoryItemName).toString(),
          });
        });
      });

      ConsultationDetailobat.getDetail(this.tempHistory).then((response2) => {
        this.datainstruksi = response2.data.payload.prescriptionAll;
        this.pharmasy = this.datainstruksi.mainItems;
        console.log("o", this.pharmasy);
        var obat = this.pharmasy;
        obat.forEach((obj) => {
          obj.instructionTypes = this.itemInstruction.filter((item) =>
            obj.instructionTypes.includes(item.value)
          );
        });

        this.pharmasy.map((obate) => {
          this.selectedHtu = this.baseItemHtu.filter(function (item) {
            if (item.value == obate.howToUseType) return item.label;
          });
          this.selectedType = this.baseItemUnit.filter(function (item) {
            if (item.value == obate.medicineSizeUnitType) return item.label;
          });
          this.selectedPeriod = this.itemPeriod.filter(function (item) {
            if (item.value == obate.periodType) return item.label;
          });
          this.selectedItem = this.baseItemsObat.filter(function (item) {
            if (item.value == obate.medicineType) return item.label;
          });
          this.isiobat.push({
            namaobat: obate.medicineBrandName,
            totalobat: obate.totalMedicine,
            typeobat: this.selectedItem[0].label,
            size: obate.medicineSize,
            sizeunit: obate.medicineSizeUnitType,
            sigmaone: obate.frequency,
            sigmatwo: obate.dosage,
            period: this.selectedPeriod[0].label,
            type: this.selectedType[0].label,
            htuse: this.selectedHtu[0].label,
            instruksi: obate.instructionTypes.map((e) => e.label).toString(),
          });
        });
      });

      ConsultationDetailrad.getDetail(this.tempHistory).then((response3) => {
        this.stastusradiologi = response3.data.payload.radiologyForm;
        this.radiologiNote = this.stastusradiologi.diagnosticNote;
        this.groupradiologi = this.stastusradiologi.groups;
        this.stastusradiologi.groups.map((datagrups) => {
          this.radiologiGroup.push({
            namaGrup: datagrups.diagnosticGroupName,
            item: datagrups.items.map((e) => e.diagnosticItemName).toString(),
          });
        });
      });
    },
    closeDialog() {
      this.dialogSumary = false;
      this.diagnosisUtama = [];
      this.diagnosisLainnya = [];
      // this.diagnosisOtherNote = "",
      // this.diagnosisMainNote = "",
      this.activity = "";
      this.diet = "";
      this.isiobat = [];
      this.datainstruksi = null;
      this.statusLab = null;
      this.stastusradiologi = null;
      this.dataLab = [];
      this.labotatoryNote = "";
      this.laboratoryGroup = [];
      this.laboratoryItem = [];
      this.group = [];
      this.radiologiNote = "";
      this.radiologiGroup = [];
      this.radiologiItem = [];
      this.groupradiologi = [];
    },
    getHistory() {
      this.params.param =
        this.dataTakeQueue.data.payload.consultation.cntConsultationId;
      this.params.body.startDate = this.startdate;
      this.params.body.endDate = this.enddate;
      this.$store
        .dispatch("consultation/actCntHistory", this.params)
        .then((response) => {
          this.tempHistory = response.data.payload;
          this.listHistory = this.tempHistory;
          console.log("ini hget history", JSON.stringify(this.listHistory));
        });
    },
    getHistoryByDate() {
      if ((this.startdate, this.enddate)) {
        this.listHistory = this.tempHistory.filter((obj) => {
          return (
            this.startdate <= obj.consultationStartDatetime.split("T")[0] &&
            this.enddate >= obj.consultationStartDatetime.split("T")[0]
          );
        });
        return this.listHistory;
      }
      return this.listHistory;
    },
    getPatientInfo() {
      let patient = this.dataTakeQueue.data.payload.patientAssessment;
      this.keluhanUtama = patient.mainComplaint;
      this.obat = patient.currentMedicine;

      if (patient.assessmentAnswers.length !== 0) {
        let durasiKeluhan = patient.assessmentAnswers.find(
          (a) => a.cntMstQuestionId == "ASSNA-PRD"
        ).optionSelectedIds;
        let tingkatKeparahan = patient.assessmentAnswers.find(
          (a) => a.cntMstQuestionId == "ASSNA-LVL"
        ).optionSelectedIds;
        let penyakitPenyerta = patient.assessmentAnswers.find(
          (a) => a.cntMstQuestionId == "ASSNA-OWNDIS"
        );
        let penyakitKeluarga = patient.assessmentAnswers.find(
          (a) => a.cntMstQuestionId == "ASSNA-FAMDIS"
        );
        let riwayatOperasi = patient.assessmentAnswers.find(
          (a) => a.cntMstQuestionId == "ASSNA-SGR"
        );
        let alergiMakanan = patient.assessmentAnswers.find(
          (a) => a.cntMstQuestionId == "ASSNA-ALGFOD"
        );
        let alergiObat = patient.assessmentAnswers.find(
          (a) => a.cntMstQuestionId == "ASSNA-ALGMED"
        );
        let alergiLainnya = patient.assessmentAnswers.find(
          (a) => a.cntMstQuestionId == "ASSNA-ALGOTH"
        );

        this.$store
          .dispatch("consultation/actSummaryQuestion")
          .then((resolve) => {
            let qDurasiKeluhan = resolve.data.payload.questions.find(
              (q) => q.cntMstQuestionId == "ASSNA-PRD"
            ).options;
            let qtingkatKeparahan = resolve.data.payload.questions.find(
              (q) => q.cntMstQuestionId == "ASSNA-LVL"
            ).options;
            let qpenyakitPenyerta = resolve.data.payload.questions.find(
              (q) => q.cntMstQuestionId == "ASSNA-OWNDIS"
            ).options;
            let qpenyakitKeluarga = resolve.data.payload.questions.find(
              (q) => q.cntMstQuestionId == "ASSNA-FAMDIS"
            ).options;
            let qriwayatOperasi = resolve.data.payload.questions.find(
              (q) => q.cntMstQuestionId == "ASSNA-SGR"
            ).options;
            let qalergiMakanan = resolve.data.payload.questions.find(
              (q) => q.cntMstQuestionId == "ASSNA-ALGFOD"
            ).options;
            let qalergiObat = resolve.data.payload.questions.find(
              (q) => q.cntMstQuestionId == "ASSNA-ALGMED"
            ).options;
            let qalergiLainnya = resolve.data.payload.questions.find(
              (q) => q.cntMstQuestionId == "ASSNA-ALGOTH"
            ).options;

            this.durasiKeluhan = qDurasiKeluhan
              .map((element) => {
                return durasiKeluhan.includes(element.cntMstAnswerOptionId)
                  ? element.optionDescription
                  : "";
              })
              .filter((e) => e != "");

            this.tingkatKeparahan = qtingkatKeparahan
              .map((element) => {
                return tingkatKeparahan.includes(element.cntMstAnswerOptionId)
                  ? element.optionDescription
                  : "";
              })
              .filter((e) => e != "");

            qpenyakitPenyerta.map((element) =>
              this.listPenyakitPenyerta.push({
                value: element.optionCode,
                text: element.optionDescription,
              })
            );
            this.penyakitPenyerta = penyakitPenyerta.optionSelectedIds;
            this.penyakitPenyertaLainnya = penyakitPenyerta.optionNote;

            qpenyakitKeluarga.map((element) =>
              this.listPenyakitKeluarga.push({
                value: element.optionCode,
                text: element.optionDescription,
              })
            );
            this.penyakitKeluarga = penyakitKeluarga.optionSelectedIds;
            this.penyakitKeluargaLainnya = penyakitKeluarga.optionNote;

            this.riwayatOperasi =
              riwayatOperasi.optionNote.trim() !== ""
                ? riwayatOperasi.optionNote
                : qriwayatOperasi
                    .map((element) => {
                      return riwayatOperasi.optionSelectedIds.includes(
                        element.cntMstAnswerOptionId
                      )
                        ? element.optionDescription
                        : "";
                    })
                    .filter((e) => e != "");

            qalergiMakanan.map((element) =>
              this.listAlergiMakanan.push({
                value: element.optionCode,
                text: element.optionDescription,
              })
            );
            this.alergiMakanan = alergiMakanan.optionSelectedIds;
            this.alergiMakananLainnya = alergiMakanan.optionNote;

            qalergiObat.map((element) =>
              this.listAlergiObat.push({
                value: element.optionCode,
                text: element.optionDescription,
              })
            );
            this.alergiObat = alergiObat.optionSelectedIds;
            this.alergiObatLainnya = alergiObat.optionNote;

            qalergiLainnya.map((element) =>
              this.listAlergiLainnya.push({
                value: element.optionCode,
                text: element.optionDescription,
              })
            );
            this.alergiLainnya = alergiLainnya.optionSelectedIds;
            this.alergiLainnyaLainnya = alergiLainnya.optionNote;
          });
      }
    },
  },
  computed: {
    dataTakeQueue: {
      get() {
        let patient = this.$store.getters["consultation/getDetailPatient"];
        return patient;
      },
      set() {},
    },
    // dataTakeQueue() {
    //   return this.$store.getters["consultation/getDetailPatient"];
    // },
    patientHistory() {
      return this.$store.getters["consultation/getResponseCntHistory"];
    },
    filteredHistory() {
      if (!this.query) {
        return this.listHistory;
      }
      return this.listHistory.filter((history) => {
        return (
          history.doctorFullName.toLowerCase().includes(this.query) ||
          history.appointmentType.toLowerCase().includes(this.query)
        );
      });
    },
  },
  mounted() {
    //For Initial View
    // this.getSumary();
    console.log("oooo", this.dataTakeQueue);
  //  this.nameObat();
    this.getPatientInfo();
   // this.getHistory();
   // console.log("ini history", JSON.stringify(this.patientHistory()));
  },
  watch: {
    //For Dynamic Data By List Patient
  
    dataTakeQueue() {
      this.getPatientInfo();
      this.getHistory();
    },
  },
};
</script>

<style scoped>
.isi {
  max-height: 120px;
  /* overflow-y: auto; */
  font-size: 18px;
  font-family: Nunito;
  background-color: #fafbff;
}
.lblInfo {
  font-size: 18px;
  font-family: Nunito;
  font-weight: bold;
}
.lblTitle {
  color: #073059;
  font-weight: bold;
  font-size: 18px;
  font-family: Nunito;
}
.colorIcon {
  color: #56a4d0;
}
.LblDate {
  color: #3c3844;
  font-weight: bold;
  font-size: 18px;
  font-family: Nunito;
}
.lblTime {
  color: #88879c;
  font-size: 16px;
  font-family: Nunito;
}
.lblStyleTitle {
  color: #414042;
  font-size: 12px;
  font-family: Nunito;
}
.lblStyleSubtitle {
  font-size: 14px;
  color: #073059;
  font-family: Nunito;
}
.lblStatus {
  font-size: 14px;
  color: #00cc6a;
  font-family: Nunito;
}
.btnSummary {
  text-transform: none;
  color: #4485fd;
  font-family: Nunito;
}
.btntext {
  font-family: Nunito;
  font-size: 12px;
}
.chatingbro {
  max-height: 120px;
  overflow-y: auto;
  font-size: 14px;
  font-family: Nunito;
}
.vdialog {
  background: transparent;
}
.cardSumary {
  background-color: #edf4fb;
  border-radius: 25px;
}
.cardDialog {
  background-color: #fafbff;
}

@media only screen and (max-width: 1366px) {
  .LblDate {
    color: #3c3844;
    font-weight: bold;
    font-size: 16px;
    font-family: Nunito;
  }
  .isi {
    max-height: 120px;
    /* overflow-y: auto; */
    font-size: 14px;
    font-family: Nunito;
    background-color: #fafbff;
  }
  .lblTitle {
    color: #073059;
    font-weight: bold;
    font-size: 14px;
    font-family: Nunito;
  }
  .LblDate {
    color: #3c3844;
    font-weight: bold;
    font-size: 16px;
    font-family: Nunito;
  }
  .lblTime {
    color: #88879c;
    font-size: 14px;
    font-family: Nunito;
  }
}
</style>
