<template>
  <v-app>
       <v-container fluid fill-height class="bgColor">
        <v-row align="center" justify="center">
            <v-col cols="6">
                <v-card height="250">
                    <v-row justify="center">
                        <img src="@/assets/ksilogo.png" width="50" height="50" alt="" class="mt-16">
                    </v-row>
                    <v-row justify="center" align="center" class="mt-5">
                        <v-col cols="12">
                            <div class="plainText"> <p>IP : {{ ip }}</p></div>
                             <div class="plainText"><p>City : {{ city }}, {{ region }}, {{ country }}, </p></div>
                        </v-col>
                        <v-col cols="12">
                           
                        </v-col>
                    </v-row>
                </v-card>
            </v-col>
        </v-row>
    </v-container>
  </v-app>
</template>
<script src="https://cdnjs.cloudflare.com/ajax/libs/jquery/2.2.4/jquery.min.js"></script>
<script>
export default {
  name: "showyourip",
  data: () => ({
    url: "https://ipgeolocation.abstractapi.com/v1/?api_key=4aeeefcb9ff346eb839660039eaeb21b",
    api_key: "4aeeefcb9ff346eb839660039eaeb21b",
    ip: "",
    country: "",
    region: "",
    city: "",
  }),
  mounted() {
    this.getIp();
  },
  methods: {
    getIp() {
      const axios = require("axios");
      axios
        .get(
          "https://ipgeolocation.abstractapi.com/v1/?api_key=4aeeefcb9ff346eb839660039eaeb21b"
        )
        .then((response) => {
          console.log(response.data);
          this.ip = response.data.ip_address;
          this.city = response.data.city;
          this.region = response.data.region;
          this.country = response.data.country;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    //   var xmlHttp = new XMLHttpRequest();
    //   xmlHttp.onreadystatechange = function () {
    //     if (xmlHttp.readyState === 4 && xmlHttp.status === 200)
    //       callback(xmlHttp.responseText);
    //       this.ip = xmlHttp.responseText
    //       console.log("haha");
    //       console.log(this.ip);
    //        alert(this.ip);
    //   };
    //   xmlHttp.open("GET", url, true); // true for asynchronous
    //   xmlHttp.send(null);
    // },
  },
};
</script>

<style scoped>
.bgColor {
  background: #EDF4FB;
}
.plainText {
    text-align: center;
    font-size: 16px;
    font-family: Nunito;
    font-weight: 600;
}
</style>

