/**
 * @author [Mochamad Arifin]
 * @email [mochamad.arifin@dwidasa.com]
 * @create date 2021-01-26 13:02:25
 * @modify date 2021-01-26 13:02:25
 * @desc [description]
 */

import AuthServices from "@/services/auth_services";

export const auth = {
  namespaced: true,

  state: {
    responseLogin: [],
    responseChangePass: [],
    responseLogout: [],
    responseRefreshToken: [],
    responseDoForgotPass: [],
    responseDoForgotPassConfirm: [],
    responseDoChangePassword: [],
    dataFcmtoken: [],
    email: "",
    shortToken: "",
    fullname: "",
  },

  mutations: {
    mntGetDataEmail: (state, value) => {
      state.email = value;
    },
    mntGetDataShortToken: (state, value) => {
      state.shortToken = value;
    },

    mntGetDataFullName: (state, value) => {
      state.fullname = value;
    },
    mntGetDataFcmtoken: (state, value) => {
      state.dataFcmtoken = value;
    },
    mntGetDataLogin: (state, value) => {
      state.responseLogin = value;
    },
    mntGetDataLogout: (state, value) => {
      state.responseLogout = value;
    },
    mtnDoForgotPass: (state, value) => {
      state.responseDoForgotPass = value;
    },
    mtnDoForgotPassConfirm: (state, value) => {
      state.responseDoForgotPassConfirm = value;
    },
    mtnDoChangePassword: (state, value) => {
      state.responseDoChangePassword = value;
    },
    mtnDoRefreshToken: (state, value) => {
      state.responseRefreshToken = value;
    },
  },

  actions: {
    actDataEmail({ commit }, value) {
      commit("mntGetDataEmail", value);
    },
    actDataShortToken({ commit }, value) {
      commit("mntGetDataShortToken", value);
    },
    actDataFullName({ commit }, value) {
      commit("mntGetDataFullName", value);
    },
    actDataFcmtoken({ commit }, value) {
      commit("mntGetDataFcmtoken", value);
    },
    actDoLogin({ commit }, value) {
      return AuthServices.doLogin(value).then((response) => {
        commit("mntGetDataLogin", response);
        return Promise.resolve(response);
      });
    },

    actDoLogout({ commit }, value) {
      return AuthServices.doLogout(value).then((response) => {
        commit("mntGetDataLogout", response);
        return Promise.resolve(response);
      });
    },

    actChangePass({ commit }, value) {
      return AuthServices.doChangePassword(value).then((response) => {
        commit("mtnDoChangePassword", response);
        return Promise.resolve(response);
      });
    },

    actDoForgotPass({ commit }, value) {
      return AuthServices.doForgotPassword(value).then((response) => {
        commit("mtnDoForgotPass", response);
        return Promise.resolve(response);
      });
    },
    actDoForgotPassConfirm({ commit }, value) {
      return AuthServices.doForgotPasswordConfirm(value).then((response) => {
        commit("mtnDoForgotPassConfirm", response);
        return Promise.resolve(response);
      });
    },
    actDoRefreshToken({ commit }, value) {
      return AuthServices.doRefreshToken(value).then((response) => {
        commit("mtnDoRefreshToken", response);
        return Promise.resolve(response);
      });
    },
  },

  getters: {
    getDataEmail: (state) => state.email,
    getDataShortToken: (state) => state.shortToken,
    getDataFullName: (state) => state.fullname,
    getDataFcmtoken: (state) => state.dataFcmtoken,
    getResponseLogin: (state) => state.responseLogin,
    getResponseLogout: (state) => state.responseLogout,
  },
};
