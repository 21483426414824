<template>
    <v-col cols="12">
        <v-alert class="ml-13 mr-13" outlined :type="type" text>
            {{ message }}
        </v-alert>
    </v-col>

</template>

<script>
import Vue from 'vue'
export default Vue.component('alert', {
    props: ['type', 'message']
})

</script>