/**
 * @author [Mochamad Arifin] [Tandy Alam]
 * @email [mochamad.arifin@dwidasa.com]
 * @create date 2021-01-26 12:51:39
 * @modify date 2021-01-26 12:51:39
 * @desc [description]
 */

import axios from "axios";
import headers from "@/services/headers";
// Untuk Dev
const API_URL = "https://dev-api.klinisia.id/auth/";

// Untuk  Prod
// const API_URL = "https://dev-api.klinisia.id/auth/";
//Untuk Demo

//const API_URL = "https://demo-dev-api.klinisia.id/auth/";

class AuthServices {
  async doLogin(bodyParams) {
    return await axios.post(API_URL + "login", bodyParams, {
      headers: headers(),
    });
  }

  // ShorToken Refresh
  async doRefreshToken(bodyParams) {
    return await axios.post(API_URL + "login/refesh-token", bodyParams, {
      headers: headers(),
    });
  }

  async doLogout(bodyParams) {
    return await axios.post(API_URL + "login/logout", bodyParams, {
      headers: headers(),
    });
  }

  async doChangePassword(bodyParams) {
    return await axios.post(API_URL + "user/change-password", bodyParams, {
      headers: headers(),
    });
  }

  async doForgotPassword(bodyParams) {
    return await axios.post(API_URL + "forget-pwd/by-email", bodyParams, {
      headers: headers(),
    });
  }

  async doForgotPasswordConfirm(bodyParams) {
    return await axios.post(
      API_URL + "forget-pwd/by-email/confirm",
      bodyParams,
      {
        headers: headers(),
      },
    );
  }

  async getDataEmail(uriParams) {
    return await axios.get(
      API_URL + `forget-pwd/by-email/sec-user-forget-pwd-id/${uriParams}`,
      {
        headers: headers(),
      },
    );
  }
}

export default new AuthServices();
