/**
 * @author [Mochamad Arifin] [Tandy Alam]
 * @email [mochamad.arifin@dwidasa.com]
 * @create date 2021-02-11 12:49:07
 * @modify date 2021-02-11 12:49:07
 * @desc [description]
 */

import Axios from "axios";
import AuthServices from "@/services/auth_services";

export default {
  intercept() {
    Axios.interceptors.request.use(
      (config) => {
        let shortToken = window.localStorage.getItem("shortToken");
        if (shortToken) {
          config.headers["Authorization"] = "Bearer " + shortToken;
        }
        return config;
      },
      (error) => {
        Promise.reject(error);
      },
    );

    Axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        const originalRequest = error.config;
        if (error.response.status === 401) {
          window.localStorage.removeItem("shortToken");
        } else if (error.response.status === 401 && !originalRequest._retry) {
          originalRequest._retry = true;
          const params = {
            userId: window.localStorage.getItem("secUserId"),
            deviceType: "browser",
            deviceCode: "123",
            longToken: window.localStorage.getItem("longToken"),
          };
          // const params = {
          //     deviceType: "browser",
          //     deviceCode: "123",
          //     userType: "doctor",
          //     hospitalId: "",
          //     email: "doctor@gmail.com",
          //     mobilePhone: "",
          //     password: "password.1"
          // }
          // window.localStorage.clear()
          // this.$router.push('/login')
          AuthServices.doLogin({ params }).then((response) => {
            console.log("MASUK SINI");
            console.log(
              "TOKEN REFRESH " + response.data.payload.shortToken.token,
            );
            window.localStorage.removeItem("shortToken");
            window.localStorage.setItem(
              "shortToken",
              response.data.payload.shortToken.token,
            );
            Axios.defaults.headers.common["Authorization"] =
              "Bearer " + response.data.payload.shortToken.token;
          });
        }
        return Promise.reject(error);
      },
    );
  },
};
