/**
 * @author [Mochamad Arifin][Tandy Alam][Iman Akbar]
 * @email [mochamad.arifin@dwidasa.com][tandy.hutama@dwidasa.com][iman.akbar@dwidasa.com]
 * @create date 2021-01-11 12:51:34 
 * @modify date 2021-05-04 12:51:34
 * @desc [description]
 */
<template>
    <v-app class="bgColor">
        <v-container fluid fill-height class="">
            <v-row align="center" justify="center">
                <v-col cols="auto" sm="10" md="8" lg="8" class="">
                    <v-card flat class="white ma-5 pa-5" rounded>
                        <v-row align="center" justify="center" class="">
                            <v-col class="" cols="6" lg="6">
                                <v-row justify="center">
                                    <img src="@/assets/klinisia logo final 1.png" width="200px" height="90" alt="" class="">
                                </v-row>
                                <v-row justify="center">
                                    <p class="lblLupaPass">Change Password?</p>
                                </v-row>
                            </v-col>
                        </v-row>

                        <v-row align="center" justify="center" class="">
                            <v-col cols="12" lg="6">
                                <p class="lblDesc">Silahkan ubah password default Anda</p>
                            </v-col>
                        </v-row>

                        <v-row align="center" justify="center">
                            <v-col class="" cols="12" lg="6">
                                <alert :type="typeAlert" :message="textAlert" v-show="alertShow"></alert>

                                <!-- <p class="lblTfEmail2"> Reset Password Email{{reqParams.email}}</p> -->

                                <p class="lblTfEmail">New Password</p>
                                <v-text-field  :rules="passwordRules"  v-model="reqParams.password" placeholder="Enter new password" outlined></v-text-field>


                                <p class="lblTfEmail">Confirm New Password*</p>
                                <v-text-field :rules="passwordRules" v-model="reqParams.rePassword" placeholder="Enter confirm new password" outlined></v-text-field>
                            </v-col>
                        </v-row>

                        <v-row align="center" justify="center" class="">
                            <v-col cols="12" lg="6">
                                <v-btn @click="successReset" block large class="primary">Kirim</v-btn>
                            </v-col>
                             <!-- <v-col cols="12" lg="6">
                                <v-btn @click="getEmail" block large class="primary">Kirim</v-btn>
                            </v-col> -->
                        </v-row>
                        
                    </v-card>
                </v-col>
            </v-row>
        </v-container>       
    </v-app>
</template>

<script>
import AuthServices from "@/services/auth_services";
export default {
    name: 'ResetPassword',
    data:() => ({
        typeAlert: 'success', 
        namaemail:'',
        alertShow: false,
        textAlert: '',
        reqParams: {
            email: '',
            secUserForgetPwdIdAndCode: '',
            password: '',
            rePassword: '',
        },
         passwordRules: [
            v => !!v || 'Password is required',
            v => v.length >= 8 || 'Min 8 characters' 
        ],
    }),
    methods: {
        getEmail(){
         AuthServices.getDataEmail(this.reqParams.secUserForgetPwdIdAndCode).then((response1)=>{
         console.log('email',response1);
         this.reqParams.email = response1.data.payload.email
         this.namaemail = response1.data.payload.email
        })
        },
        successReset() {
            if (this.reqParams.password != '' && this.reqParams.rePassword != '') {     
                console.log(JSON.stringify(this.reqParams))
                this.$store.dispatch('auth/actDoForgotPassConfirm', this.reqParams).then(
                response => {
                        if(response.data.errors != '') {
                            this.typeAlert = 'error'
                            this.textAlert = response.data.error
                            this.alertShow = true
                            setTimeout(()=>{this.alertShow=false},5000)
                        }else{
                              this.$toast.success("Reset Password Berhasil ", {
                              type: "success",
                              position: "top-right",
                              duration: 5000,
                         });
                            this.$router.push('/login')
                        } 
                    }
                )
            }else{
                this.typeAlert = 'error'
                this.textAlert = "Inputan wajib diisi"
                this.alertShow = true
                setTimeout(()=>{this.alertShow=false},5000)
            }
        }
    },
    mounted() {
        this.reqParams.secUserForgetPwdIdAndCode = this.$route.params.token
        console.log(this.reqParams.secUserForgetPwdIdAndCode);
        this.reqParams.email= this.namaemail
        console.log(this.reqParams.email);
        this.getEmail()
    },

}
</script>

<style scoped>
    .bgColor {
        background: #EDF4FB;
    }
    .lblLupaPass {
        font-family: Inter;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 26px;
        display: flex;
        margin-top: -20;
        align-items: center;
        color: #2A8BF2;
    }
    .lblDesc {
        font-family: Inter;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 20px;
        align-items: center;
        text-align: center;

        color: #231F20;
    }
    .lblTfEmail {
        font-family: Inter;
        font-style: normal;
        font-weight: 500;
        font-size: 12px;
        line-height: 10px;
        display: flex;
        align-items: center;

        color: #696F79;
    }
</style>