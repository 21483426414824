/**
 * @author [Mochamad Arifin]
 * @email [mochamad.arifin@dwidasa.com]
 * @create date 2021-01-13 11:43:06
 * @modify date 2021-01-13 11:43:06
 * @desc [description]
 */


<template>
  <v-row>
    <v-col cols="12 mt-15">
      <v-card :class="'rounded-xl'" class="">
        <v-row align="center" justify="center">
          <v-col>
            <p class="lblInfo ml-9">Informasi Pasien</p>
            <v-divider class="blue"></v-divider>
            <v-row align="center" justify="center" class="ml-6 mt-3 mr-6">
              <v-col cols="12">
                <v-row no-gutters>
                  <v-icon class="colorIcon">arrow_drop_down</v-icon>
                  <div class="lblTitle">Keluhan Utama</div>
                </v-row>
              </v-col>
              <v-col cols="12" class="ma-0 pa-0">
                <v-textarea
                  maxlength="2000"
                  required
                  class="ma-0 pa-0 ml-13 mr-13 customTF"
                  outlined
                  v-model="keluhanUtama"
                  :value="keluhanUtama"
                  clearable
                  hide-details
                  dense
                  auto-grow
                  rows="1"
                />
              </v-col>
            </v-row>

            <v-row align="center" justify="center" class="ml-6 mr-6">
              <v-col cols="12">
                <v-row no-gutters>
                  <v-icon class="colorIcon">arrow_drop_down</v-icon>
                  <div class="lblTitle">Durasi Keluhan</div>
                </v-row>
              </v-col>
              <v-col cols="12" class="ma-0 pa-0">
                <v-select
                  class="ma-0 pa-0 ml-13 mr-13 customTF"
                  outlined
                  required
                  v-model="singleDurasiKeluhan"
                  :items="listDurasiKeluhan"
                  hide-details
                  dense
                >
                </v-select>
              </v-col>
            </v-row>

            <v-row align="center" justify="center" class="ml-6 mr-6">
              <v-col cols="12">
                <v-row no-gutters>
                  <v-icon class="float-right colorIcon">arrow_drop_down</v-icon>
                  <div class="lblTitle">Tingkat Keparahan</div>
                </v-row>
              </v-col>
              <v-col cols="12" class="ma-0 pa-0">
                <v-select
                  class="ma-0 pa-0 ml-13 mr-13 customTF"
                  maxlength="2000"
                  outlined
                  required
                  v-model="singleTingkatKeparahan"
                  :items="listTingkatKeparahan"
                  hide-details
                  dense
                >
                </v-select>
              </v-col>
            </v-row>

            <v-row align="center" justify="center" class="ml-6 mr-6">
              <v-col cols="12">
                <v-row no-gutters>
                  <v-icon class="float-right colorIcon">arrow_drop_down</v-icon>
                  <div class="lblTitle">Penyakit Penyerta</div>
                </v-row>
              </v-col>
              <v-col cols="12" class="ma-0 pa-0">
                <v-row class="ma-0 pa-0 ml-13 mr-13 customTF">
                  <v-select
                    outlined
                    required
                    v-model="penyakitPenyerta"
                    :items="listPenyakitPenyerta"
                    hide-details
                    multiple
                    @input="penyakitPenyertaChanged"
                    dense
                  >
                  </v-select>
                </v-row>
                <v-row class="ma-0 pa-0 ml-13 mr-13 mt-3 customTF">
                  <v-textarea
                    maxlength="2000"
                    outlined
                    required
                    v-model="penyakitPenyertaLainnya"
                    clearable
                    hide-details
                    label="Penyakit Penyerta Lainnya"
                    v-show="penyakitPenyerta.includes('DIS-OTHER')"
                    auto-grow
                    rows="1"
                    dense
                  />
                </v-row>
              </v-col>
            </v-row>

            <v-row align="center" justify="center" class="ml-6 mr-6">
              <v-col cols="12">
                <v-row no-gutters>
                  <v-icon class="float-right colorIcon">arrow_drop_down</v-icon>
                  <div class="lblTitle">Penyakit Keluarga</div>
                </v-row>
              </v-col>
              <v-col cols="12" class="ma-0 pa-0">
                <v-row class="ma-0 pa-0 ml-13 mr-13 customTF">
                  <v-select
                    outlined
                    required
                    v-model="penyakitKeluarga"
                    :items="listPenyakitKeluarga"
                    hide-details
                    multiple
                    @input="penyakitKeluargaChanged"
                    dense
                  >
                  </v-select>
                </v-row>
                <v-row class="ma-0 pa-0 ml-13 mr-13 mt-3 customTF">
                  <v-textarea
                    maxlength="2000"
                    outlined
                    required
                    v-model="penyakitKeluargaLainnya"
                    clearable
                    hide-details
                    label="Penyakit Keluarga Lainnya"
                    v-show="penyakitKeluarga.includes('DIS-OTHER')"
                    auto-grow
                    rows="1"
                    dense
                  />
                </v-row>
              </v-col>
            </v-row>

            <v-row align="center" justify="center" class="ml-6 mr-6">
              <v-col cols="12">
                <v-row no-gutters>
                  <v-icon class="float-right colorIcon">arrow_drop_down</v-icon>
                  <div class="lblTitle">Riwayat Operasi/Tindakan</div>
                </v-row>
              </v-col>
              <v-col cols="12" class="ma-0 pa-0">
                <v-textarea
                  maxlength="2000"
                  dense
                  class="ma-0 pa-0 ml-13 mr-13 customTF"
                  outlined
                  required
                  v-model="riwayatOperasi"
                  clearable
                  hide-details
                  auto-grow
                  rows="1"
                />
              </v-col>
            </v-row>

            <v-row align="center" justify="center" class="ml-12 mt-3 mr-6">
              <v-col cols="12" class="ma-0 pa-0">
                <div class="lblTitle">Alergi yang dimiliki</div>
              </v-col>
              <v-col cols="12" class="ma-0 mt-6 pa-0 mb-5">
                <v-row>
                  <v-btn dark color="green" rounded medium width="120"
                    >Makan</v-btn
                  >
                  <v-select
                    class="ml-5 mr-16 mb-3 pa-0 customTF"
                    outlined
                    required
                    dense
                    v-model="alergiMakanan"
                    :items="listAlergiMakanan"
                    hide-details
                    multiple
                    @input="alergiMakananChanged"
                  >
                  </v-select>
                </v-row>
                <v-row>
                  <v-textarea
                    maxlength="2000"
                    dense
                    class="ml-5 mr-16 mb-3 pa-0 customTF"
                    outlined
                    required
                    v-model="alergiMakananLainnya"
                    clearable
                    hide-details
                    label="Alergi Makanan Lainnya"
                    v-show="alergiMakanan.includes('ALGFOD-OTHER')"
                    auto-grow
                    rows="1"
                  />
                </v-row>
              </v-col>

              <v-col cols="12" class="ma-0 pa-0 mb-5">
                <v-row>
                  <v-btn dark color="primary" rounded medium width="120"
                    >Obat</v-btn
                  >
                  <v-select
                    class="ml-5 mr-16 mb-3 pa-0 customTF"
                    outlined
                    required
                    v-model="alergiObat"
                    :items="listAlergiObat"
                    hide-details
                    multiple
                    @input="alergiObatChanged"
                    dense
                  >
                  </v-select>
                </v-row>
                <v-row>
                  <v-textarea
                    maxlength="2000"
                    dense
                    class="ml-5 mr-16 mb-3 pa-0 customTF"
                    outlined
                    required
                    v-model="alergiObatLainnya"
                    clearable
                    hide-details
                    label="Alergi Obat Lainnya"
                    v-show="alergiObat.includes('ALGMED-OTHER')"
                    auto-grow
                    rows="1"
                  />
                </v-row>
              </v-col>

              <v-col cols="12" class="ma-0 pa-0 mb-5">
                <v-row>
                  <v-btn dark color="yellow darken-2" rounded medium width="120"
                    >Other</v-btn
                  >
                  <v-select
                    dense
                    class="ml-5 mr-16 mb-3 pa-0 customTF"
                    outlined
                    required
                    v-model="alergiLainnya"
                    :items="listAlergiLainnya"
                    hide-details
                    multiple
                    @input="alergiLainnyaChanged"
                  >
                  </v-select>
                </v-row>
                <v-row>
                  <v-textarea
                    maxlength="2000"
                    dense
                    class="ml-5 mr-16 mb-3 pa-0 customTF"
                    outlined
                    required
                    v-model="alergiLainnyaLainnya"
                    clearable
                    hide-details
                    label="Alergi Lainnya"
                    v-show="alergiLainnya.includes('ALGOTH-OTHER')"
                    auto-grow
                    rows="1"
                  />
                </v-row>
              </v-col>
            </v-row>

            <v-divider class="ml-8 mr-9 mt-7 grey"></v-divider>

            <v-row align="center" justify="center" class="ml-6 mr-6">
              <v-col cols="12">
                <v-row no-gutters>
                  <v-icon class="float-right colorIcon">arrow_drop_down</v-icon>
                  <div class="lblTitle">Obat Sedang Dikonsumsi/Obat Rutin</div>
                </v-row>
              </v-col>
              <v-col cols="12" class="ma-0 pa-0">
                <v-textarea
                  maxlength="2000"
                  dense
                  class="ma-0 pa-0 ml-13 mr-13 customTF chatingbro"
                  outlined
                  required
                  v-model="obat"
                  clearable
                  hide-details
                  auto-grow
                  rows="1"
                />
              </v-col>
            </v-row>

            <!-- <v-divider class="ml-8 mr-9 mt-7 grey"></v-divider> -->

            <v-row align="center" justify="center" class="ml-6 mr-6">
              <v-col cols="12">
                <v-row no-gutters>
                  <v-icon class="float-right colorIcon">arrow_drop_down</v-icon>
                  <div class="lblTitle">Notes</div>
                </v-row>
              </v-col>
              <v-col cols="12" class="ma-0 pa-0">
                <v-textarea
                  maxlength="2000"
                  class="ma-0 pa-0 ml-13 mr-13 customTF chatingbro"
                  outlined
                  dense
                  required
                  v-model="notes"
                  clearable
                  hide-details
                  auto-grow
                  rows="1"
                />
              </v-col>
            </v-row>

            <v-row>
              <alert
                :type="typeAlert"
                :message="textAlert"
                v-show="alertShow"
              ></alert>
            </v-row>

            <v-row>
              <v-col class="ml-9">
                <v-checkbox
                  label="Sesuai dengan persetujuan dokter"
                  v-model="isAgree"
                ></v-checkbox>
              </v-col>
              <v-col class="mr-9">
                <v-btn
                  class="float-right white--text"
                  :disabled="!isAgree"
                  color="#00CC6A"
                  large
                  rounded
                  @click="submitSummary"
                  >Submit</v-btn
                >
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  data: () => ({
    keluhanUtama: "",
    durasiKeluhan: [],
    listDurasiKeluhan: [],
    singleDurasiKeluhan: "",
    tingkatKeparahan: [],
    listTingkatKeparahan: [],
    singleTingkatKeparahan: "",
    penyakitPenyerta: [],
    listPenyakitPenyerta: [],
    penyakitPenyertaLainnya: "",
    penyakitKeluarga: [],
    listPenyakitKeluarga: [],
    penyakitKeluargaLainnya: "",
    riwayatOperasi: [],
    alergiMakanan: [],
    listAlergiMakanan: [],
    alergiMakananLainnya: "",
    alergiObat: [],
    listAlergiObat: [],
    alergiObatLainnya: "",
    alergiLainnya: [],
    listAlergiLainnya: [],
    alergiLainnyaLainnya: "",
    obat: "",
    notes: null,
    isAgree: false,
    patient: {},
    dataDurasiKeluhan: {},
    dataTingkatKeparahan: {},
    dataPenyakitPenyerta: {},
    dataPenyakitKeluarga: {},
    dataRiwayatOperasi: {},
    dataAlergiMakanan: {},
    dataAlergiObat: {},
    dataAlergiLainnya: {},
    typeAlert: "success",
    alertShow: false,
    textAlert: "",
    
  }),
  methods: {
    capitalizeFirstLetter: (str) => {
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
    getSummary() {
      console.log("HOHOHO summaryActivated");
      // this.$store.commit("consultation/mtnSummaryActivated", true);
      let statusMenu = this.getResponseTakeQueue.data.payload.consultation
        .summaryStatus;
      console.log("Status Menu Summary " + statusMenu);
      if (statusMenu) {
        console.log("MASUK SINI FALSE DOCTOR SUMMARY");
        // Call Doctor Patient
        this.$store
          .dispatch(
            "consultation/actDoctorSummary",
            window.localStorage.getItem("cntConsultationId")
          )
          .then((resolve) => {
            this.patient = resolve.data.payload;
            this.keluhanUtama = this.patient.mainComplaint;
            this.notes = this.patient.doctorNote;
            this.obat = this.patient.currentMedicine;
            if (this.patient.assessmentAnswers) {
              this.dataDurasiKeluhan = this.patient.assessmentAnswers.find(
                (a) => a.cntMstQuestionId == "ASSNA-PRD"
              );
              this.dataTingkatKeparahan = this.patient.assessmentAnswers.find(
                (a) => a.cntMstQuestionId == "ASSNA-LVL"
              );
              this.dataPenyakitPenyerta = this.patient.assessmentAnswers.find(
                (a) => a.cntMstQuestionId == "ASSNA-OWNDIS"
              );
              this.dataPenyakitKeluarga = this.patient.assessmentAnswers.find(
                (a) => a.cntMstQuestionId == "ASSNA-FAMDIS"
              );
              this.dataRiwayatOperasi = this.patient.assessmentAnswers.find(
                (a) => a.cntMstQuestionId == "ASSNA-SGR"
              );
              this.dataAlergiMakanan = this.patient.assessmentAnswers.find(
                (a) => a.cntMstQuestionId == "ASSNA-ALGFOD"
              );
              this.dataAlergiObat = this.patient.assessmentAnswers.find(
                (a) => a.cntMstQuestionId == "ASSNA-ALGMED"
              );
              this.dataAlergiLainnya = this.patient.assessmentAnswers.find(
                (a) => a.cntMstQuestionId == "ASSNA-ALGOTH"
              );

              this.$store
                .dispatch("consultation/actSummaryQuestion")
                .then((resolve) => {
                  let qDurasiKeluhan = resolve.data.payload.questions.find(
                    (q) => q.cntMstQuestionId == "ASSNA-PRD"
                  ).options;
                  let qtingkatKeparahan = resolve.data.payload.questions.find(
                    (q) => q.cntMstQuestionId == "ASSNA-LVL"
                  ).options;
                  let qpenyakitPenyerta = resolve.data.payload.questions.find(
                    (q) => q.cntMstQuestionId == "ASSNA-OWNDIS"
                  ).options;
                  let qpenyakitKeluarga = resolve.data.payload.questions.find(
                    (q) => q.cntMstQuestionId == "ASSNA-FAMDIS"
                  ).options;
                  let qriwayatOperasi = resolve.data.payload.questions.find(
                    (q) => q.cntMstQuestionId == "ASSNA-SGR"
                  ).options;
                  let qalergiMakanan = resolve.data.payload.questions.find(
                    (q) => q.cntMstQuestionId == "ASSNA-ALGFOD"
                  ).options;
                  let qalergiObat = resolve.data.payload.questions.find(
                    (q) => q.cntMstQuestionId == "ASSNA-ALGMED"
                  ).options;
                  let qalergiLainnya = resolve.data.payload.questions.find(
                    (q) => q.cntMstQuestionId == "ASSNA-ALGOTH"
                  ).options;

                  qDurasiKeluhan.map((element) =>
                    this.listDurasiKeluhan.push({
                      value: element.optionCode,
                      text: element.optionDescription,
                    })
                  );
                  this.singleDurasiKeluhan = this.dataDurasiKeluhan.optionSelectedIds[0];

                  qtingkatKeparahan.map((element) =>
                    this.listTingkatKeparahan.push({
                      value: element.optionCode,
                      text: element.optionDescription,
                    })
                  );
                  this.singleTingkatKeparahan = this.dataTingkatKeparahan.optionSelectedIds[0];

                  qpenyakitPenyerta.map((element) =>
                    this.listPenyakitPenyerta.push({
                      value: element.optionCode,
                      text: element.optionDescription,
                    })
                  );
                  this.penyakitPenyerta = this.dataPenyakitPenyerta.optionSelectedIds;
                  this.penyakitPenyertaLainnya = this.dataPenyakitPenyerta.optionNote;

                  qpenyakitKeluarga.map((element) =>
                    this.listPenyakitKeluarga.push({
                      value: element.optionCode,
                      text: element.optionDescription,
                    })
                  );
                  this.penyakitKeluarga = this.dataPenyakitKeluarga.optionSelectedIds;
                  this.penyakitKeluargaLainnya = this.dataPenyakitKeluarga.optionNote;

                  this.riwayatOperasi =
                    this.dataRiwayatOperasi.optionNote.trim() !== ""
                      ? this.dataRiwayatOperasi.optionNote
                      : qriwayatOperasi
                          .map((element) => {
                            return this.dataRiwayatOperasi.optionSelectedIds.includes(
                              element.cntMstAnswerOptionId
                            )
                              ? element.optionDescription
                              : "";
                          })
                          .filter((e) => e != "");

                  qalergiMakanan.map((element) =>
                    this.listAlergiMakanan.push({
                      value: element.optionCode,
                      text: element.optionDescription,
                    })
                  );
                  this.alergiMakanan = this.dataAlergiMakanan.optionSelectedIds;
                  this.alergiMakananLainnya = this.dataAlergiMakanan.optionNote;

                  qalergiObat.map((element) =>
                    this.listAlergiObat.push({
                      value: element.optionCode,
                      text: element.optionDescription,
                    })
                  );
                  this.alergiObat = this.dataAlergiObat.optionSelectedIds;
                  this.alergiObatLainnya = this.dataAlergiObat.optionNote;

                  qalergiLainnya.map((element) =>
                    this.listAlergiLainnya.push({
                      value: element.optionCode,
                      text: element.optionDescription,
                    })
                  );
                  this.alergiLainnya = this.dataAlergiLainnya.optionSelectedIds;
                  this.alergiLainnyaLainnya = this.dataAlergiLainnya.optionNote;
                });
            }
          });
      } else {
        // Call Summary Patient
        console.log("MASUK SINI FALSE PATIENT SUMMARY");
        this.$store
          .dispatch(
            "consultation/actPatientSummary",
            window.localStorage.getItem("cntConsultationId")
          )
          .then((resolve) => {
            this.patient = resolve.data.payload;
            this.keluhanUtama = this.patient.mainComplaint;
            this.notes = this.patient.doctorNote;
            this.obat = this.patient.currentMedicine;
            if (this.patient.assessmentAnswers) {
              this.dataDurasiKeluhan = this.patient.assessmentAnswers.find(
                (a) => a.cntMstQuestionId == "ASSNA-PRD"
              );
              this.dataTingkatKeparahan = this.patient.assessmentAnswers.find(
                (a) => a.cntMstQuestionId == "ASSNA-LVL"
              );
              this.dataPenyakitPenyerta = this.patient.assessmentAnswers.find(
                (a) => a.cntMstQuestionId == "ASSNA-OWNDIS"
              );
              this.dataPenyakitKeluarga = this.patient.assessmentAnswers.find(
                (a) => a.cntMstQuestionId == "ASSNA-FAMDIS"
              );
              this.dataRiwayatOperasi = this.patient.assessmentAnswers.find(
                (a) => a.cntMstQuestionId == "ASSNA-SGR"
              );
              this.dataAlergiMakanan = this.patient.assessmentAnswers.find(
                (a) => a.cntMstQuestionId == "ASSNA-ALGFOD"
              );
              this.dataAlergiObat = this.patient.assessmentAnswers.find(
                (a) => a.cntMstQuestionId == "ASSNA-ALGMED"
              );
              this.dataAlergiLainnya = this.patient.assessmentAnswers.find(
                (a) => a.cntMstQuestionId == "ASSNA-ALGOTH"
              );

              this.$store
                .dispatch("consultation/actSummaryQuestion")
                .then((resolve) => {
                  let qDurasiKeluhan = resolve.data.payload.questions.find(
                    (q) => q.cntMstQuestionId == "ASSNA-PRD"
                  ).options;
                  let qtingkatKeparahan = resolve.data.payload.questions.find(
                    (q) => q.cntMstQuestionId == "ASSNA-LVL"
                  ).options;
                  let qpenyakitPenyerta = resolve.data.payload.questions.find(
                    (q) => q.cntMstQuestionId == "ASSNA-OWNDIS"
                  ).options;
                  let qpenyakitKeluarga = resolve.data.payload.questions.find(
                    (q) => q.cntMstQuestionId == "ASSNA-FAMDIS"
                  ).options;
                  let qriwayatOperasi = resolve.data.payload.questions.find(
                    (q) => q.cntMstQuestionId == "ASSNA-SGR"
                  ).options;
                  let qalergiMakanan = resolve.data.payload.questions.find(
                    (q) => q.cntMstQuestionId == "ASSNA-ALGFOD"
                  ).options;
                  let qalergiObat = resolve.data.payload.questions.find(
                    (q) => q.cntMstQuestionId == "ASSNA-ALGMED"
                  ).options;
                  let qalergiLainnya = resolve.data.payload.questions.find(
                    (q) => q.cntMstQuestionId == "ASSNA-ALGOTH"
                  ).options;

                  qDurasiKeluhan.map((element) =>
                    this.listDurasiKeluhan.push({
                      value: element.optionCode,
                      text: element.optionDescription,
                    })
                  );
                  this.singleDurasiKeluhan = this.dataDurasiKeluhan.optionSelectedIds[0];

                  qtingkatKeparahan.map((element) =>
                    this.listTingkatKeparahan.push({
                      value: element.optionCode,
                      text: element.optionDescription,
                    })
                  );
                  this.singleTingkatKeparahan = this.dataTingkatKeparahan.optionSelectedIds[0];

                  qpenyakitPenyerta.map((element) =>
                    this.listPenyakitPenyerta.push({
                      value: element.optionCode,
                      text: element.optionDescription,
                    })
                  );
                  this.penyakitPenyerta = this.dataPenyakitPenyerta.optionSelectedIds;
                  this.penyakitPenyertaLainnya = this.dataPenyakitPenyerta.optionNote;

                  qpenyakitKeluarga.map((element) =>
                    this.listPenyakitKeluarga.push({
                      value: element.optionCode,
                      text: element.optionDescription,
                    })
                  );
                  this.penyakitKeluarga = this.dataPenyakitKeluarga.optionSelectedIds;
                  this.penyakitKeluargaLainnya = this.dataPenyakitKeluarga.optionNote;

                  this.riwayatOperasi =
                    this.dataRiwayatOperasi.optionNote.trim() !== ""
                      ? this.dataRiwayatOperasi.optionNote
                      : qriwayatOperasi
                          .map((element) => {
                            return this.dataRiwayatOperasi.optionSelectedIds.includes(
                              element.cntMstAnswerOptionId
                            )
                              ? element.optionDescription
                              : "";
                          })
                          .filter((e) => e != "");

                  qalergiMakanan.map((element) =>
                    this.listAlergiMakanan.push({
                      value: element.optionCode,
                      text: element.optionDescription,
                    })
                  );
                  this.alergiMakanan = this.dataAlergiMakanan.optionSelectedIds;
                  this.alergiMakananLainnya = this.dataAlergiMakanan.optionNote;

                  qalergiObat.map((element) =>
                    this.listAlergiObat.push({
                      value: element.optionCode,
                      text: element.optionDescription,
                    })
                  );
                  this.alergiObat = this.dataAlergiObat.optionSelectedIds;
                  this.alergiObatLainnya = this.dataAlergiObat.optionNote;

                  qalergiLainnya.map((element) =>
                    this.listAlergiLainnya.push({
                      value: element.optionCode,
                      text: element.optionDescription,
                    })
                  );
                  this.alergiLainnya = this.dataAlergiLainnya.optionSelectedIds;
                  this.alergiLainnyaLainnya = this.dataAlergiLainnya.optionNote;
                });
            }
          });
      }
    },
    penyakitPenyertaChanged() {
      if (this.penyakitPenyerta.includes("DIS-NODIS")) {
        this.penyakitPenyerta = ["DIS-NODIS"];
      }
    },
    penyakitKeluargaChanged() {
      if (this.penyakitKeluarga.includes("DIS-NODIS")) {
        this.penyakitKeluarga = ["DIS-NODIS"];
      }
    },
    alergiMakananChanged() {
      if (this.alergiMakanan.includes("ALGFOD-NOALG")) {
        this.alergiMakanan = ["ALGFOD-NOALG"];
      }
    },
    alergiObatChanged() {
      if (this.alergiObat.includes("ALGMED-NOALG")) {
        this.alergiObat = ["ALGMED-NOALG"];
      }
    },
    alergiLainnyaChanged() {
      if (this.alergiLainnya.includes("ALGOTH-NOALG")) {
        this.alergiLainnya = ["ALGOTH-NOALG"];
      }
    },
    submitSummary() {
      if (this.keluhanUtama === null || this.keluhanUtama == "") {
        this.typeAlert = "error";
        this.textAlert = "Keluhan Utama is Empty";
        this.alertShow = true;
        setTimeout(() => {
          this.alertShow = false;
        }, 5000);
      } else if (
        this.penyakitPenyertaLainnya === "" &&
        this.penyakitPenyerta.includes("DIS-OTHER")
      ) {
        this.typeAlert = "error";
        this.textAlert = "Penyakit Penyerta Lainnya Utama is Empty";
        this.alertShow = true;
        setTimeout(() => {
          this.alertShow = false;
        }, 5000);
      } else if (
        this.penyakitKeluargaLainnya === "" &&
        this.penyakitKeluarga.includes("DIS-OTHER")
      ) {
        this.typeAlert = "error";
        this.textAlert = "Penyakit Keluarga Lainnya Utama is Empty";
        this.alertShow = true;
        setTimeout(() => {
          this.alertShow = false;
        }, 5000);
      } else if (
        this.alergiMakananLainnya === "" &&
        this.alergiMakanan.includes("ALGFOD-OTHER")
      ) {
        this.typeAlert = "error";
        this.textAlert = "Alergi Makanan is Empty";
        this.alertShow = true;
        setTimeout(() => {
          this.alertShow = false;
        }, 5000);
      } else if (
        this.alergiObatLainnya === "" &&
        this.alergiObat.includes("ALGMED-OTHER")
      ) {
        this.typeAlert = "error";
        this.textAlert = "Alergi Obat is Empty";
        this.alertShow = true;
        setTimeout(() => {
          this.alertShow = false;
        }, 5000);
      } else if (
        this.alergiLainnyaLainnya === "" &&
        this.alergiLainnya.includes("ALGOTH-OTHER")
      ) {
        this.typeAlert = "error";
        this.textAlert = "Alergi Lainya is Empty";
        this.alertShow = true;
        setTimeout(() => {
          this.alertShow = false;
        }, 5000);
      } else if (this.riwayatOperasi === null || this.riwayatOperasi == "") {
        this.typeAlert = "error";
        this.textAlert = "Riwayat Operasi is Empty";
        this.alertShow = true;
        setTimeout(() => {
          this.alertShow = false;
        }, 5000);
      } else if (this.obat === null || this.obat == "") {
        this.typeAlert = "error";
        this.textAlert = "Obat is Empty";
        this.alertShow = true;
        setTimeout(() => {
          this.alertShow = false;
        }, 5000);
      } else {
        this.dataDurasiKeluhan.optionSelectedIds[0] = this.singleDurasiKeluhan;
        this.dataTingkatKeparahan.optionSelectedIds[0] = this.singleTingkatKeparahan;
        if (this.riwayatOperasi) {
          this.dataRiwayatOperasi.optionSelectedIds[0] = "SGR-OTHER";
          this.dataRiwayatOperasi.optionNote = this.riwayatOperasi.toString();
          this.dataRiwayatOperasi.optionNoSelectionStatus = false;
        } else {
          this.dataRiwayatOperasi.optionSelectedIds[0] = "SGR-NO";
          this.dataRiwayatOperasi.optionNote = "";
          this.dataRiwayatOperasi.optionNoSelectionStatus = true;
        }

        this.dataPenyakitPenyerta.optionSelectedIds = this.penyakitPenyerta;
        this.dataPenyakitPenyerta.optionNote = this.penyakitPenyerta.includes(
          "DIS-OTHER"
        )
          ? this.penyakitPenyertaLainnya
          : "";
        this.dataPenyakitPenyerta.optionNoSelectionStatus = this.penyakitPenyerta.includes(
          "DIS-NODIS"
        );

        this.dataPenyakitKeluarga.optionSelectedIds = this.penyakitKeluarga;
        this.dataPenyakitKeluarga.optionNote = this.penyakitKeluarga.includes(
          "DIS-OTHER"
        )
          ? this.penyakitKeluargaLainnya
          : "";
        this.dataPenyakitKeluarga.optionNoSelectionStatus = this.penyakitKeluarga.includes(
          "DIS-NODIS"
        );

        this.dataAlergiMakanan.optionSelectedIds = this.alergiMakanan;
        this.dataAlergiMakanan.optionNote = this.alergiMakanan.includes(
          "ALGFOD-OTHER"
        )
          ? this.alergiMakananLainnya
          : "";
        this.dataAlergiMakanan.optionNoSelectionStatus = this.alergiMakanan.includes(
          "ALGFOD-NOALG"
        );

        this.dataAlergiObat.optionSelectedIds = this.alergiObat;
        this.dataAlergiObat.optionNote = this.alergiObat.includes(
          "ALGMED-OTHER"
        )
          ? this.alergiObatLainnya
          : "";
        this.dataAlergiObat.optionNoSelectionStatus = this.alergiObat.includes(
          "ALGMED-NOALG"
        );

        this.dataAlergiLainnya.optionSelectedIds = this.alergiLainnya;
        this.dataAlergiLainnya.optionNote = this.alergiLainnya.includes(
          "ALGOTH-OTHER"
        )
          ? this.alergiLainnyaLainnya
          : "";
        this.dataAlergiLainnya.optionNoSelectionStatus = this.alergiLainnya.includes(
          "ALGOTH-NOALG"
        );

        let assessmentAnswers = [
          this.dataDurasiKeluhan,
          this.dataTingkatKeparahan,
          this.dataPenyakitPenyerta,
          this.dataPenyakitKeluarga,
          this.dataRiwayatOperasi,
          this.dataAlergiMakanan,
          this.dataAlergiObat,
          this.dataAlergiLainnya,
        ];
        let request = {
          cntAssessmentId: this.patient.cntAssessmentId,
          cntConsultationId: this.patient.cntConsultationId,
          assessmentType: this.patient.assessmentType,
          patientHeightCm: this.patient.patientHeightCm,
          patientWeightKg: this.patient.patientWeightKg,
          mainComplaint: this.keluhanUtama,
          doctorNote: this.notes,
          currentMedicine: this.obat,
          assessmentAnswers: assessmentAnswers,
        };
        console.log('req',JSON.stringify(request));
        this.$store
          .dispatch("consultation/actPostSummary", request)
          .then((response) => {
            if (response.data.error === "") {
              this.$store.dispatch("consultation/actSetTabAssesment", false);
              this.typeAlert = "success";
              this.textAlert = "Summary Successfully Created!";
              this.alertShow = true;
              setTimeout(() => {
                this.alertShow = false;
              }, 5000);
            } else {
              this.typeAlert = "error";
              this.textAlert = "Created Summary Failed!";
              this.alertShow = true;
              setTimeout(() => {
                this.alertShow = false;
              }, 5000);
            }
          });
      }
    },
  },
  computed: {
    getStatusDenganJanji() {
      return this.$store.getters["consultation/getDenganJanji"];
    },
    getResponseTakeQueue() {
      if (this.getStatusDenganJanji !== true) {
        return this.$store.getters["consultation/getResponseTakeQueue"];
      } else {
        return this.$store.getters["consultation/getResponseTakeQueueWithApp"];
      }
    },
  },
  mounted() {
    this.getStatusDenganJanji
    this.getSummary();
  },
  watch: {
    getResponseTakeQueue() {
      this.getSummary();
    },
    keluhanUtama: function (newValue) {
      this.keluhanUtama = this.capitalizeFirstLetter(newValue);
    },
    penyakitPenyertaLainnya: function (newValue) {
      this.penyakitPenyertaLainnya = this.capitalizeFirstLetter(newValue);
    },
    penyakitKeluargaLainnya: function (newValue) {
      this.penyakitKeluargaLainnya = this.capitalizeFirstLetter(newValue);
    },
    riwayatOperasi: function (newValue) {
      this.riwayatOperasi = this.capitalizeFirstLetter(newValue);
    },
    alergiMakananLainnya: function (newValue) {
      this.alergiMakananLainnya = this.capitalizeFirstLetter(newValue);
    },
    alergiObatLainnya: function (newValue) {
      this.alergiObatLainnya = this.capitalizeFirstLetter(newValue);
    },
    alergiLainnyaLainnya: function (newValue) {
      this.alergiLainnyaLainnya = this.capitalizeFirstLetter(newValue);
    },
    obat: function (newValue) {
      this.obat = this.capitalizeFirstLetter(newValue);
    },
  },
};
</script>

<style scoped>
.lblInfo {
  font-size: 16px;
  font-family: Nunito;
  font-weight: bold;
}
.lblTitle {
  color: #073059;
  font-weight: bold;
  font-size: 18px;
  font-family: Nunito;
}
.customTF {
  background: #edf4fb;
  border-style: none !important;
}

/* .chatingbro{
max-height: 120px;
overflow-y: auto;
 font-size: 18px;
    font-family: Nunito;
} */
@media only screen and (max-width: 1366px) {
  .lblTitle {
    color: #073059;
    font-weight: bold;
    font-size: 14px;
    font-family: Nunito;
  }
  .customTF {
    background: #edf4fb;
    border-style: none !important;
    font-size: 14px;
  }
  /* .chatingbro{
max-height: 70px;
overflow-y: auto;
font-size: 12px;
font-family: Nunito;
} */
}
</style>