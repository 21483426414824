/**
 * @author [Mochamad Arifin]
 * @email [mochamad.arifin@dwidasa.com]
 * @create date 2021-02-21 16:22:38
 * @modify date 2021-02-21 16:22:38
 * @desc [description]
 */

<template>
  <!-- <v-app>  -->
  <v-row>
    <v-col cols="12">
      <v-divider></v-divider>
    </v-col>

    <v-col cols="1" class="ml-5">
      <!-- <v-file-input
        v-model="files"
        hide-input
        @change="fil"
        clearable
        accept="image/*,.pdf,.doc"
        color="blue"
        rounded
        class="upload"
        prepend-icon="mdi-plus"
      > -->
        <!-- <img src="@/assets/iconAdd.png" @click="open" height="25" alt="" /> -->
      <!-- </v-file-input> -->
    </v-col>

    <v-col cols="7" class="">
      <v-textarea
        v-model="tfComment"
        class="ma-0 pa-0 chatingbro"
        placeholder="Type a message here"
        hide-details
        auto-grow
        rows="1"
        flat
        solo
        @keypress.enter="sendMessage"
        @keyup.enter="clearMessage"
      />
    </v-col>

    <!-- Sementara Di
    nonaktifkan tanggal 22 feb 2021 -->

    <!-- <v-col cols="7" class="">
      <v-text-field
        v-if="getStartTime == true"
        v-model="tfComment"
        class="ma-0 pa-0"
        placeholder="Type a message here"
        solo
        flat
        hide-details
        @keyup.enter="sendComment"
      /> -->

    <!-- <v-text-field
        v-if="getStartTime == false"
        v-model="tfComment"
        class="ma-0 pa-0"
        placeholder="Type a message here"
        solo
        flat
        hide-details
        disabled
        @keyup.enter="sendComment"
      />
    </v-col> -->

    <v-col cols="3" class="ma-0 pa-0">
      <v-row>
        <!-- <img
          src="@/assets/iconEmoji.png"
          height="20"
          class="ml-6 mt-7"
          alt=""
        /> -->
        <img
          src="@/assets/sendIcon.png"
          height="50"
          class="ml-5 mt-2"
          @click="sendComment"
          alt=""
        />
      </v-row>
    </v-col>
  </v-row>
  <!-- </v-app> -->
</template>

<script>
export default {
  data: () => ({
    tfComment: "",
    files: [],
    tex: false,
    fileType: "",
    uniqueId: "",
    urlFile: "",
  }),
  methods: {
    open() {},
    clearMessage: function (event) {
      if (event.shiftKey === true && event.key === "Enter") {
        console.log("New line added, message not sended");
        return;
      } else {
        this.tfComment = "";
      }
    },
    sendMessage: function (event) {
      if (event.shiftKey === true && event.key === "Enter") {
        console.log("New line added, message not sended");
        return;
      } else {
        if (this.tex === true) {
          this.uniqueId = this.files.size;
          this.tfComment = this.files.name;
          const file = this.files;
          this.tex = false;
          this.$qiscus
            .upload(this.files, function (error, progress, url) {
              if (error) {
                // on Error
                console.log(error);
                alert(error)
                this.$toast.open({
                message: "Upload Gambar Gagal",
                position: "top-right",
                type: "error",
                duration: 6000,
              });
              }
              if (progress) {
                // Do something when file are still uploading
                console.log(progress);
              }
              if (url) {
                // on Success
                console.log(url);
              }
            })
            .then((res) => {
              console.log("mboh", res);
              var pesan = this.$qiscus.generateFileAttachmentMessage({
                roomId: this.dataQiscusRoomId.id,
                text: file.name,
                url: res,
                filename: file.name,
                size: file.size,
                caption: "Some caption",
                extras: { key: "value" },
              });
              console.log(pesan);
              this.$qiscus
                .sendComment(
                  pesan.room_id,
                  pesan.message,
                  pesan.unique_id,
                  pesan.type,
                  pesan.payload,
                  pesan.extras
                )
                .then((res) => {
                  console.log("l", res);
                });
            });
            
          this.tfComment = "";
        }
        else{
        this.$qiscus.sendComment(this.dataQiscusRoomId.id, this.tfComment);
        this.tfComment = "";
        }

      }
    },
    fil() {
      console.log(this.files);
      this.tfComment = this.files.name;
      this.tex = true;
      console.log(this.tex);
    },
    sendFile() {
      if (this.files == "") {
        console.log("hoho");
        //  document.createElement("br");
      } else {
        // this.$qiscus.sendComment(this.dataQiscusRoomId.id, this.file);
        this.tfComment = "";
      }
    },
    sendComment() {
      if (this.tex === true) {
        if (this.tfComment == "") {
          console.log("hoho");
          //  document.createElement("br");
        } else {
          this.uniqueId = this.files.size;
          this.tfComment = this.files.name;
          const file = this.files;
          this.tex = false;
          this.$qiscus
            .upload(this.files, function (error, progress, url) {
              if (error) {
                // on Error
                console.log(error);
                alert(error)
                this.$toast.open({
                message: "Upload Gambar Gagal",
                position: "top-right",
                type: "error",
                duration: 6000,
              });
              }
              if (progress) {
                // Do something when file are still uploading
                console.log(progress);
              }
              if (url) {
                // on Success
                console.log(url);
              }
            })
            .then((res) => {
              console.log("mboh", res);
              var pesan = this.$qiscus.generateFileAttachmentMessage({
                roomId: this.dataQiscusRoomId.id,
                text: file.name,
                url: res,
                filename: file.name,
                size: file.size,
                caption: "Some caption",
                extras: { key: "value" },
              });
              console.log(pesan);
              this.$qiscus
                .sendComment(
                  pesan.room_id,
                  pesan.message,
                  pesan.unique_id,
                  pesan.type,
                  pesan.payload,
                  pesan.extras
                )
                .then((res) => {
                  console.log("l", res);
                });
            });
          this.tfComment = "";
        }
      } else {
        if (this.tfComment == "") {
          console.log("hoho");
          //  document.createElement("br");
        } else {
          this.$store.dispatch("consultation/actBoot", false);
          this.$qiscus.sendComment(this.dataQiscusRoomId.id, this.tfComment);
          this.tfComment = "";
          //  document.createElement("br");
        }
      }
    },
    capitalizeFirstLetter: (str) => {
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
  },
  computed: {
    dataQiscusRoomId() {
      return this.$store.getters["consultation/getResponseQiscus"];
    },
  },
  watch: {
    tfComment: function (newValue) {
      this.tfComment = this.capitalizeFirstLetter(newValue);
    },
  },
};
</script>

<style>
.chatingbro {
  overflow-y: auto;
  max-height: 120px;
  font-size: 12px;
}
.upload {
  margin-top: -5px;
}
@media only screen and (max-width: 1366px) {
  .chatingbro {
    overflow-y: auto;
    max-height: 100px;
    font-size: 12px;
  }
}
</style>