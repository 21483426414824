<template>
  <v-app v-show="questionFeature">
    <v-main class="bgColor">
      <v-row class="bgColor2 ma-0 pa-0">
        <!-- Patient List -->
        <v-col v-show="leftMenuTag == 1" lg="4" md="4">
          <QuestionList @clickedPatient="getClickedPatient" />
          <!-- <PatientList />       -->
        </v-col>

        <!-- Active Chat -->
        <v-col v-if="leftMenuTag == 2" lg="4" md="4">
          <ActiveChat />
        </v-col>

        <!-- Greeting Chat -->
        <v-col v-show="rightMenuTag == 1" cols="12" lg="8" md="8" class="bgColorChat" color="blue">
          <GreetingQuestion />
        </v-col>

        <!-- Detail question -->
        <v-col
          v-if="rightMenuTag == 2"
          cols="12"
          lg="8"
          md="8"
          class="bgColorDetailPatient"
          color="red"
        >
          <v-row align="center" justify="center">
            <DetailQuestion @leftMenuTag="1" />
          </v-row>
        </v-col>
      </v-row>
      <!-- </v-container>
      </v-col>-->
      <!-- </v-row> -->
      <!-- </v-container> -->
    </v-main>


  </v-app>

  
   
</template>



<script>
import AuthServices from "@/services/auth_services";
import QuestionServices from "@/services/question_services";
import ActiveChat from "@/components/chat/ActiveChat";
import QuestionList from "../components/QuestionList.vue";
import GreetingQuestion from "../components/GreetingQuestion.vue";
import DetailQuestion from "../components/DetailQuestion.vue";

export default {
  name: "Chat",
  data: () => ({
    leftMenuTag: 1,
    rightMenuTag: 1,
    uriParam: "",
    obat: [],
    timer: "",
    messageStatus: {
      status: false,
      message: "",
    },
    questionFeature: window.localStorage.getItem('questionFeature'),
  }),
  components: {
    ActiveChat,
    QuestionList,
    GreetingQuestion,
    DetailQuestion,
  },
  created() {
    if (window.localStorage.getItem("page") != null) {
      this.getQuestionList(window.localStorage.getItem("page"), 20);
      this.timer = setInterval(this.getQuestionList(window.localStorage.getItem("page"), 20), 5000);
    } else {
      this.getQuestionList(0, 20);
      this.timer = setInterval(this.getQuestionList(0, 20), 5000);
    }
  },

  computed: {
    getDialogStatus() {
      return this.$store.getters["consultation/getDialogStatus"];
    },
    getGretingChat() {
      return this.$store.getters["consultation/getGreting"];
    },
  },
  mounted() {
    this.callRefreshToken();
    this.getnomer();
  },
  watch: {},
  methods: {
    getnomer() {
      this.rightMenuTag = this.getGretingChat;
    },
    callRefreshToken() {
      const params = {
        userId: window.localStorage.getItem("secUserId"),
        deviceType: "browser",
        deviceCode: "123",
        longToken: window.localStorage.getItem("longToken"),
        role: window.localStorage.getItem("role"),
      };
      console.log("PARAMS " + params.longToken);
      AuthServices.doRefreshToken(params)
        .then((response) => {
          console.log("MASUK SINI");
          console.log(
            "TOKEN REFRESH " +
            JSON.stringify(response.data.payload.shortToken.token)
          );
          window.localStorage.removeItem("shortToken");
          window.localStorage.setItem(
            "shortToken",
            response.data.payload.shortToken.token
          );
          console.log(
            "TOKEN STORAGE " + window.localStorage.getItem("shortToken")
          );
        })
        .catch((err) => {
          console.log(err);
          window.localStorage.clear();
          window.localStorage.removeItem("in");
          this.$router.push("/login");
        });
    },

    getClickedPatient(value) {
      this.rightMenuTag = value;
      console.log("rightMenuTag", this.rightMenuTag);
    },

    getQuestionList(page, limit) {
      console.log("kadie ");
      QuestionServices.getQeustionList(page, limit)
        .then((response) => {
          this.$store.dispatch(
            "question/setPatientList",
            response.data.payload.content
          );
        })
        .catch((e) => {
          console.log("ada yg error", e);
          window.localStorage.removeItem("page");
        });
    },
  },

  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>

<style scoped>
.bgColor {
  background: #5c95ff;
}
.bgColor2 {
  background: #edf4fb;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  width: 100%;
  height: 100%;
}
.bgColorChat {
  background: #edf4fb;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  width: 100%;
  height: 99%;
  mix-blend-mode: multiply;
  box-shadow: 0px 4px 50px rgba(68, 133, 253, 0.5);
  border-radius: 50px 0px 0px 50px;
}
.bgColorDetailPatient {
  background: #ffffff;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  width: 100%;
  height: 99%;
  box-shadow: 0px 4px 50px rgba(68, 133, 253, 0.5);
}
.bgColorDivider {
  background: #84a6f2;
}
.textStyleDialog {
  text-align: center;
  font-size: 16px;
}
.textStyleDialog1 {
  text-align: center;
  font-size: 16px;
  color: white;
}
</style>
