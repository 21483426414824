import QuestionServices from "@/services/question_services";

export const question = {
  namespaced: true,
  state: {
    listPatient: [],
    responseTakeQuestion: [],
    responseComment: [],
    responseFinish: [],
    responseReject: [],
    alertStatus: false,
    allowed: false,

  },

  mutations: {
    getListQuestion: (state, value) => {
      state.listPatient = value;
    },
    setResponseTakeQuestion: (state, value) => {
      state.responseTakeQuestion = value;
    },

    setResponseComment: (state, value) => {
      state.responseComment = value;

    },
    setResponseFinish: (state, value) => {
      state.responseFinish = value;

    },
    setResponseReject: (state, value) => {
      state.responseReject = value;

    },
    mtnalertStatus: (state, value) => {
      state.alertStatus = value;
    },
    mtnallowed: (state, value) => {
      state.allowed = value;
    },

  },

  actions: {
    setPatientList({
      commit
    }, value) {
      commit("getListQuestion", value);
    },

    postTakeQuestion({
      commit
    }, value) {
      return QuestionServices.getQuestion(value).then(
        (response) => {
          commit("setResponseTakeQuestion", response);
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error);
        },
      );
    },

    postComment({
      commit
    }, value) {
      return QuestionServices.comment(value).then(
        (response) => {
          commit("setResponseComment", response);
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error);
        },
      );
    },

    putComment({
      commit
    }, value) {
      return QuestionServices.editComment(value).then(
        (response) => {
          commit("setResponseComment", response);
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error);
        },
      );
    },

    postFinish({
      commit
    }, value) {
      return QuestionServices.finish(value).then(
        (response) => {
          commit("setResponseFinish", response);
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error);
        },
      );
    },


    postReject({
      commit
    }, value) {
      return QuestionServices.reject(value).then(
        (response) => {
          commit("setResponseReject", response);
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error);
        },
      );
    },

    actalertStatus({
      commit
    }, value) {
      commit("mtnalertStatus", value);
    },

    actallowed({
      commit
    }, value) {
      commit("mtnallowed", value);
    },
    


  },

  getters: {
    getListQuestion: (state) => state.listPatient,
    getResponseTakeQuestion: (state) => state.responseTakeQuestion,
    getResponseComment: (state) => state.responseComment,
    getResponseFinish: (state) => state.responseFinish,
    getResponseReject: (state) => state.responseReject,
    getalertStatus: (state) => state.alertStatus,
    getallowed: (state) => state.allowed,


  },
};