import Vue from "vue";
import moment from "moment";

Vue.filter("patientHistoryDate", (value) =>
  value != null ? moment(String(value)).format("DD MMM 'YY") : "",
);
Vue.filter("patientHistoryTime", (value) =>
  value != null ? moment(String(value)).format("hh:mm") : "",
);

Vue.filter("objectiveDate", (value) =>
  value != null ? moment(String(value)).format("DD MMM YYYY") : "",
);
Vue.filter("dashboardDate", (value) =>
  value != null ? moment(String(value)).format("dddd MMMM YYYY") : "",
);
Vue.filter("paymentListDate", (value) =>
  value != null ? moment(String(value)).format("LT") : "",
);
Vue.filter("diagramDate", (value) =>
  value != null ? moment(String(value)).format("LLL") : "",
);

Vue.filter("birthDate", (value) => {
  const age = moment.duration(moment().diff(moment(value, "YYYY-MM-DD")));
  return value != null
    ? moment(
        `${age.years()}-${age.months()}-${age.days()}`,
        "YYYY-MM-DD",
      ).format("YY [y /] MM [m /] D [d]")
    : "";
});
