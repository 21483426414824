/** * @author [Mochamad Arifin][Tandy Alam][Iman Akbar] * @email
[mochamad.arifin@dwidasa.com][tandy.hutama@dwidasa.com][iman.akbar@dwidasa.com]
* @create date 2021-01-11 12:51:34 * @modify date 2021-05-04 12:51:34 * @desc
[description] */

<template>
  <v-app>
    <v-main class="bgColor">
      <!-- <v-container fluid fill-height class="bgColor2"> -->
      <!-- <v-row> -->
      <div v-if="statusObat == true">
        <v-dialog v-model="getDialogStatus.isActive" persistent max-width="450">
          <v-card height="135" color="red">
            <v-card-title class="headline"></v-card-title>
            <v-card-text
              ><p class="textStyleDialog1">
                <v-icon color="white darken-2"> mdi-werning </v-icon>Data Obat
                Belum Di Submit
              </p></v-card-text
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn small color="white" outlined dark @click="closeDialog"
                >Close</v-btn
              >
              <!-- <v-btn color="#00CC6A" dark @click="finishChat">Akhiri</v-btn> -->
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>

      <div v-else>
        <v-dialog v-model="getDialogStatus.isActive" persistent max-width="450">
          <v-card height="160">
            <v-card-title class="headline"></v-card-title>
            <v-card-text class="textStyleDialog"
              >Akhiri sesi dengan
              {{ getDialogStatus.patientName }} ?</v-card-text
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="#000000" text dark @click="closeDialog"
                >Batal</v-btn
              >
              <v-btn color="#00CC6A" dark @click="finishChat">Akhiri</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </div>
      <!-- <v-col> -->
      <!-- <v-container fluid fill-height class="red "> -->
      <!-- {{dataPatient}} -->
      <v-row class="bgColor2 ma-0 pa-0">
        <!-- Patient List -->
        <v-col v-show="leftMenuTag == 1" lg="4" md="4">
          <PatientList @clickedPatient="getClickedPatient" />
          <!-- <PatientList />       -->
        </v-col>

        <!-- Active Chat -->
        <v-col v-if="leftMenuTag == 2" lg="4" md="4">
          <ActiveChat />
        </v-col>

        <!-- Greeting Chat -->
        <v-col
          v-show="rightMenuTag == 1"
          cols="12"
          lg="8"
          md="8"
          class="bgColorChat"
          color="blue"
        >
          <GreetingChat />
        </v-col>

        <!-- Detail Patient -->
        <v-col
          v-if="rightMenuTag == 2"
          cols="12"
          lg="8"
          md="8"
          class="bgColorDetailPatient"
          color="red"
        >
          <v-row align="center" justify="center">
            <DetailPatient @leftMenuTag="startChat" />
          </v-row>
        </v-col>
      </v-row>
      <!-- </v-container>
      </v-col>-->
      <!-- </v-row> -->
      <!-- </v-container> -->
    </v-main>
  </v-app>
</template>

<script>
import AuthServices from "@/services/auth_services";
import ConsultationService from "@/services/consultation_services";
import PatientList from "@/components/PatientList";
import ActiveChat from "@/components/chat/ActiveChat";
import DetailPatient from "@/components/DetailPatient";
import GreetingChat from "@/components/GreetingChat";

export default {
  name: "Chat",
  data: () => ({
    leftMenuTag: 1,
    rightMenuTag: 1,
    uriParam: "",
    status_obat: false,
    obat: [],
    timer: "",
    messageStatus: {
      status: false,
      message: "",
    },
    testDataDummy: [
      {
        id: "",
        nama: "",
      },
    ],
  }),
  components: {
    PatientList,
    ActiveChat,
    DetailPatient,
    GreetingChat,
  },
  created() {
    if (this.getStatusDenganJanji !== true) {
      this.getQueue();
      this.timer = setInterval(this.getQueue, 5000);
    } else {
      clearInterval(this.timer);
      this.listPasien2;
    }

  },

  computed: {
    getStatusDenganJanji() {
      return this.$store.getters["consultation/getDenganJanji"];
    },
    listPasien2() {
      return this.$store.getters["consultation/getListPatient2"];
    },
    getDialogStatus() {
      return this.$store.getters["consultation/getDialogStatus"];
    },
    statusObat() {
      return this.$store.getters["consultation/getStatusObat"];
    },
    getGretingChat() {
      return this.$store.getters["consultation/getGreting"];
    },
  },
  mounted() {
    this.getStatusDenganJanji;
    this.callRefreshToken();
    this.getnomer();
    this.status_obat = window.localStorage.getItem("isdoctor");
    this.obat = window.localStorage.getItem("detailobat");
    console.log("hai", this.status_obat);
    console.log("hai", this.obat);
    this.statusObat;
  },
  watch: {
    //  getnomer(){
    //    console.log('nilai ', this.getGretingchat);
    //     this.rightMenuTag = this.getGretingchat
    //     console.log(this.rightMenuTag);
    //   },
  },
  methods: {
    getnomer() {
      console.log("nilai ", this.getGretingChat);
      this.rightMenuTag = this.getGretingChat;
      console.log("nomer", this.rightMenuTag);
    },
    callRefreshToken() {
      // console.log("trial");
      const params = {
        userId: window.localStorage.getItem("secUserId"),
        deviceType: "browser",
        deviceCode: "123",
        longToken: window.localStorage.getItem("longToken"),
        role: window.localStorage.getItem("role"),
      };
      console.log("PARAMS " + params.longToken);
      AuthServices.doRefreshToken(params)
        .then((response) => {
          console.log("MASUK SINI");
          console.log(
            "TOKEN REFRESH " +
              JSON.stringify(response.data.payload.shortToken.token)
          );
          window.localStorage.removeItem("shortToken");
          window.localStorage.setItem(
            "shortToken",
            response.data.payload.shortToken.token
          );
          console.log(
            "TOKEN STORAGE " + window.localStorage.getItem("shortToken")
          );
        })
        .catch((err) => {
          console.log(err);
          window.localStorage.clear();
          window.localStorage.removeItem("in");
          this.$router.push("/login");
        });
    },
    isSummaryActivated() {
      //return this.$refs.refDetailPatient.isSummaryEdit();
      return this.$store.getters.isSummaryActivated;
    },
    startChat(value) {
      this.leftMenuTag = value;
    },
    getClickedPatient(value) {
      this.rightMenuTag = value;
      console.log("rightMenuTag", this.rightMenuTag);
    },
    finishChat() {
      this.uriParam = window.localStorage.getItem("cntConsultationId");
      this.$store
        .dispatch("consultation/actFinishChat", this.uriParam)
        .then((response) => {
          if (response.data.error != "") {
            //      var second  = 0
            //  window.localStorage.setItem('time', second)
            const alert = {
              type: "error",
              message: response.data.error,
              isAlert: true,
            };
            var Time = 0;
            window.localStorage.removeItem("in");
            window.localStorage.setItem("time", Time);
            window.localStorage.removeItem("time");
            localStorage.removeItem("endTime");
            this.$store.dispatch("consultation/actShowAlert", alert);
            this.$store.dispatch("consultation/actDialogStatus", false);
          } else {
            var second = 0;
            window.localStorage.setItem("time", second);
            localStorage.removeItem("in");
            // window.localStorage.removeItem("time");
            localStorage.removeItem("endTime");
            window.location.reload();
            localStorage.removeItem("in");
            this.currentComponent = "Subjective";
            this.isSummaryDisabled = true;
            this.showButtonStyle = 1;
            this.changeButton = 1;
            this.$store.dispatch("consultation/actSetTabAssesment", true);
            this.$store.dispatch("consultation/actSetTabPlanning", true);
            this.$qiscus.exitChatRoom();
            this.$store.dispatch("consultation/actDialogStatus", false);
            this.$router.push({ path: "/home" });
            this.$store.dispatch("consultation/actShowTime", false);
            localStorage.removeItem("in");
            var openChat = 1;
            window.localStorage.setItem("chat", openChat);
          }
        });
    },
    closeDialog() {
      this.$store.dispatch("consultation/actDialogStatus", false);
    },
    getQueue() {
      ConsultationService.Queue()
        .then((response) => {
          this.$store.dispatch(
            "consultation/setPatientList",
            response.data.payload,
            (this.status_obat = response.data.payload.prescriptionStatus)
          );
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },

  beforeDestroy() {
    clearInterval(this.timer);
  },
};
</script>

<style scoped>
.bgColor {
  background: #5c95ff;
}
.bgColor2 {
  background: #edf4fb;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  width: 100%;
  height: 100%;
}
.bgColorChat {
  background: #edf4fb;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  width: 100%;
  height: 99%;
  mix-blend-mode: multiply;
  box-shadow: 0px 4px 50px rgba(68, 133, 253, 0.5);
  border-radius: 50px 0px 0px 50px;
}
.bgColorDetailPatient {
  background: #ffffff;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  width: 100%;
  height: 99%;
  overflow-y: auto;
  box-shadow: 0px 4px 50px rgba(68, 133, 253, 0.5);
}
.bgColorDivider {
  background: #84a6f2;
}
.textStyleDialog {
  text-align: center;
  font-size: 16px;
}
.textStyleDialog1 {
  text-align: center;
  font-size: 16px;
  color: white;
}
</style>
