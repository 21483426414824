/**
 * @author [Mochamad Arifin]
 * @email [mochamad.arifin@dwidasa.com]
 * @create date 2021-02-21 16:22:17
 * @modify date 2021-02-21 16:22:17
 * @desc [description]
 */

<template>  
    <v-container  class="chatContainer">
        <v-row>
                <HeaderChat />   
        </v-row>

        <v-row class="ma-0 pa-0">
                <CommentChat /> 
        </v-row>

        <v-row class="ma-0 pa-0">
            
                <FooterChat />
         
        </v-row>
    </v-container>
</template>

<script>
import HeaderChat from '@/components/chat/HeaderChat'
import CommentChat from '@/components/chat/CommentChat'
import FooterChat from '@/components/chat/FooterChat'

export default {
    components: {
        HeaderChat,
        CommentChat,
        FooterChat,
    }
}
</script>

<style scoped>

.chatContainer {
    background: #FFFFFF;
    width: 100%;
    height: 100%;
    filter: drop-shadow(0px 4px 4px rgba(99, 196, 233, 0.3));
    border-radius: 30px;
}

@media only screen and (max-width: 1366px) {
.chatContainer {
    background: #FFFFFF;
    width: 100%;
    height: 98%;
    filter: drop-shadow(0px 4px 4px rgba(99, 196, 233, 0.3));
    border-radius: 33px;
}
}

</style>