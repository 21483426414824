/**
 * @author [Mochamad Arifin]
 * @email [mochamad.arifin@dwidasa.com]
 * @create date 2021-01-24 18:12:45
 * @modify date 2021-01-24 18:12:45
 * @desc [description]
 */

import Vue from "vue";
import Vuex from "vuex";

import {
  auth
} from "./stores/auth.module";
import {
  consultation
} from "./stores/consultation.module";
import {
  question
} from "./stores/question.module";

import {
  chat
} from "./stores/chat.module";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    consultation,
    auth,
    chat,
    question,
  },
});