<template>
  <v-container>
    <v-row class="ml-6 mr-10 mt-5">
      <h3 style="font-family: Nunito; color: #073059">{{ dataTakeQuestion.data.payload.question.title }}</h3>
    </v-row>

    <v-row class="ml-6 mr-10 mt-5">
      <label style="
          font-family: Nunito;
          color: #073059;
          font-weight: 800;
          font-size: 12px;
          text-transform: capitalize;
        ">{{ dataTakeQuestion.data.payload.question.userName }}</label>
      <span class="ml-3" style="
          font-family: Nunito;
          color: #0089bd;
          font-weight: 800;
          font-size: 12px;
        ">{{ dataTakeQuestion.data.payload.question.category.categoryName }}</span>
      <span class="ml-3" style="
          font-family: Nunito;
          color: #0089bd;
          font-weight: 800;
          font-size: 12px;
        ">{{ dataTakeQuestion.data.payload.question.countComment }} Balasan</span>
      <span class="ml-3" style="
          font-family: Nunito;
          color: #8692a6;
          font-weight: 800;
          font-size: 12px;
        ">{{ dataTakeQuestion.data.payload.question.createdOn | timeago }}</span>
      <span class="ml-3 question-status">
        {{
            dataTakeQuestion.data.payload.question.questionStatusCode
        }}
      </span>
    </v-row>

    <v-row class="ml-6 mr-10 mt-5" align="center" justify="left">
      <p class="question-value">{{ dataTakeQuestion.data.payload.question.question }}</p>
    </v-row>

    <div>
      <img v-if="dataTakeQuestion.data.payload.question.attachmentImageUrl !== ''" 
        :src="dataTakeQuestion.data.payload.question.attachmentImageUrl" alt="" class="imageQuestion"
        @click="showDialog" />
    </div>

    <v-divider class="ml-6 mr-10"></v-divider>
    <!-- comment -->

    <!-- <div
      :style="this.dataTakeQuestion.data.payload.question.countComment > 0 ? 'height:400px;  overflow-y: auto;' : ''"
    >-->

    <div v-for="(item, index) in this.dataTakeQuestion.data.payload.comment" v-bind:key="item.frmCommentId">
      <v-item class="mt-10 ml-6 mr-10" :class="item.userTypeCode == 'doctor' ? 'doctor-answer' : 'patient-answer'
      ">
        <p>
          <!-- <img class src="@/assets/date.png" style="float:right; margin-left:5px" /> -->
          <label 
          v-show="item.userTypeCode == 'doctor' ? true : false" for="imageInput" @click="item.userTypeCode == 'doctor' ? selectItem(item, index) : ''"  
            style="
              float:right; 
              margin-left:5px;
              font-family: Nunito;
              color: #073059;
              font-weight: 800;
              font-size: 12px;">
                Edit
                <img class src="@/assets/edit.png" 
                  style=" width: 15px; height: 15px;float:right; margin-left:5px ; margin-top:1px ;" />
          </label>
          <label style="
              font-family: Nunito;
              color: #073059;
              font-weight: 800;
              font-size: 13px;
            ">{{ item.userName }}</label>
          <br />
          <span class="ml-0" style="
              font-family: Nunito;
              color: #8692a6;
              font-weight: 800;
              font-size: 12px;
            ">{{ item.createdOn | shortDate }}</span>
          <br />
          <br />
          <span>
            <div v-html="item.comment"></div>
          </span>
          <editor 
            v-if="idx == index ? true : false"
            v-model="paramsEdit.bodyParams.comment"  api-key="8qslhtpl1h6nid1vhs1j7dg5vlf874wkkawh6ojghkazc26i" :init="{  
              height: 100,
              branding: false,
              menubar: false,

            }" initial-value=""/>

          <v-btn v-if="idx == index ? true : false"  color="primary" elevation="2" small style="font-family: Nunito; margin-top: 10px;" @click="putComment"
            :disabled="dataTakeQuestion.data.payload.question.questionStatusCode != 'Active' || allowed == false">Ubah
            Jawaban </v-btn>

          <v-btn v-if="idx == index ? true : false"  color= "#caf0f8" elevation="2" small style="font-family: Nunito; 
            margin-top: 10px; margin-left: 10px;" @click="hideEditor"
            :disabled="dataTakeQuestion.data.payload.question.questionStatusCode != 'Active' || allowed == false">
            <span class="blue-text">Batal</span>
          </v-btn>
        </p>
      </v-item>
    </div>
    <!-- </div> -->

    <v-row class="ml-6 mr-10 mt-5">
      <v-row align="center">
        <v-col>
          <v-alert v-if="alert" v-model="alert" class="mt-5" style="font-family: Nunito; font-size:12px;" border="top"
            colored-border type="error" elevation="2" dismissible>Jawaban masih kosong, silahkan isi jawaban anda
          </v-alert>

          <v-alert v-model="info" class="mt-5" style="font-family: Nunito; font-size:12px;" border="top" colored-border
            type="success" elevation="2" dismissible>Jawaban telah berhasil dikirim</v-alert>
          <label style="
              font-family: Nunito;
              color: #073059;
              font-weight: 800;
              font-size: 14px;
            ">Jawab</label>

          <!-- <v-textarea name="comment" filled auto-grow v-model="params.bodyParams.comment" counter ref="comment">
          </v-textarea> -->

          <!-- <editor :disabled="allowed == false" v-model="params.bodyParams.comment" 
            api-key="8qslhtpl1h6nid1vhs1j7dg5vlf874wkkawh6ojghkazc26i" :init="{
              menubar: false,
              plugins: '', toolbar: 'undo redo | formatselect | bold italic underline removeformat | alignleft aligncenter alignright alignjustify | bullist numlist outdent indent',
              forced_root_block: false,
              branding: false,
              
            }" /> -->
          <editor :disabled="allowed == false" v-model="params.bodyParams.comment"  api-key="8qslhtpl1h6nid1vhs1j7dg5vlf874wkkawh6ojghkazc26i" :init="{  
            height: 300,
            branding: false,
            menubar: false,
            plugins: [
               'autolink',
              'lists', 'link', 'image', 'charmap', 'preview', 'anchor', 'searchreplace', 'visualblocks',
               'fullscreen', 'insertdatetime', 'media', 'table', 'help',
            ],
            toolbar:
              'undo redo | casechange blocks |formatselect | bold italic underline | \
                               alignleft aligncenter alignright alignjustify | \
                               bullist numlst checklist outdent indent | removeformat'
          }" initial-value=""/>
          <br />
          <!-- <v-btn color="primary" elevation="2" small style="font-family: Nunito" @click="postComment"
            :disabled="dataTakeQuestion.data.payload.question.questionStatusCode != 'Active' || allowed == false">Kirim
            Jawaban </v-btn> -->
          <v-btn color="primary" elevation="2" small style="font-family: Nunito" @click="postComment"
            :disabled="dataTakeQuestion.data.payload.question.questionStatusCode != 'Active' || allowed == false">Kirim
            Jawaban </v-btn>
          <br />
          <br />
          <v-divider></v-divider>

          <br />
          <br />

          <v-row style="float: right; font-family: Nunito" class="mr-0 mb-10">
            <v-dialog v-model="dialogFinish" persistent max-width="500">
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="primary" elevation="2" small style="float: right; font-family: Nunito" v-bind="attrs"
                  v-on="on"
                  :disabled="dataTakeQuestion.data.payload.question.countComment == 0 || dataTakeQuestion.data.payload.question.questionStatusCode != 'Active' || allowed == false">
                  Akhiri Sesi</v-btn>
              </template>

              <v-card>
                <v-card-title class="text-h5">Konfirmasi</v-card-title>
                <v-card-text>Akhiri sesi tanya jawab ini ?</v-card-text>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn text @click="dialogFinish = false">Batal</v-btn>
                  <v-btn color="green darken-1" text @click="postFinish">Akhiri</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>

            <v-dialog v-model="dialogReject" scrollable max-width="500px">
              <template v-slot:activator="{ on, attrs }">
                <v-btn color="success" elevation="2" small style="float: right; font-family: Nunito" class="ml-5"
                  v-bind="attrs" v-on="on"
                  :disabled="dataTakeQuestion.data.payload.question.questionStatusCode != 'Active' || allowed == false">
                  Tolak Pertanyaan
                </v-btn>
              </template>
              <v-card>
                <v-card-title>Pilih Alasan Penolakan</v-card-title>
                <v-divider></v-divider>
                <v-card-text style="height: 300px; widht">
                  <v-radio-group v-model="reason">
                    <v-radio checked label="Mengandung unsur promosi" value="Mengandung unsur promosi"></v-radio>
                    <v-radio label="Mengandung unsur SARA & Politik" value="Mengandung unsur SARA & Politik"></v-radio>
                    <v-radio label="Mengandung pesan atau kalimat yang tidak pantas"
                      value="Mengandung pesan atau kalimat yang tidak pantas"></v-radio>
                    <v-radio label="anyaan duplikasi dengan pertanyaan lain"
                      value="Pertanyaan duplikasi dengan pertanyaan lain"></v-radio>
                  </v-radio-group>
                </v-card-text>
                <v-divider></v-divider>
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn text @click="dialogReject = false">Batal</v-btn>
                  <v-btn color="green darken-1" text @click="postReject">Tolak Pertanyaan</v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-row>
        </v-col>
      </v-row>
    </v-row>
    <div>
    <div v-if="dialogVisible">
      <div class="overlay" @click="hideDialog"></div>
      <div class="dialog" :style="{ width: dialogWidth + 'px', height: dialogHeight + 'px' }">
        <div class="column-layout">
          <v-icon class="icon-close" color="black" @click="hideDialog">mdi-close</v-icon>
          <img v-if="dataTakeQuestion.data.payload.question.attachmentImageUrl !== ''" 
          :src="dataTakeQuestion.data.payload.question.attachmentImageUrl" alt="" class="imageQuestion" />
        </div>
      </div>
    </div>
    </div>
  </v-container>
</template>

<script>
import Moment from "moment";
import Vue from "vue";
// import Editor from '@tinymce/tinymce-vue'
// import tinymce from 'tinymce/tinymce';
// import 'tinymce/themes/modern/theme';
/* tinymce-vue */
import Editor from '@tinymce/tinymce-vue';

Vue.filter("shortDate", function (value) {
  return Moment(value).locale("id").format("LL H:m");
});
Vue.filter("timeago", function (value) {
  return Moment(value).locale("id").fromNow();
});



export default {
  components: {
    'editor': Editor
  },
  data: () => ({
    dialog: false,
    dialogReject: false,
    dialogFinish: false,
    alert: false,
    info: false,
    selectedItem: null,
    editorValue: '0',
    idx:-1,
    showImageFlag: false,
    selectedItemIndex: {},
    reason: 'Mengandung unsur promosi', 
    params: {
      questionId: "",
      commentId:"",
      bodyParams: { comment: "" },
    },
    paramsEdit: {
      questionId: "",
      commentId:"",
      bodyParams: { comment: "" },
    },
    paramsReject: {
      questionId: "",
      bodyParams: { rejectDescription: "" }
    },
    dialogVisible: false,
    dialogWidth: 600, 
    dialogHeight: 500,
  }),
  mounted() {
    this.dataTakeQuestion;
    this.gambarUrl = this.dataTakeQuestion.data.payload.question.userName
    console.log("kadie 1");

  },
  computed: {

    dataTakeQuestion() {
      return this.$store.getters["question/getResponseTakeQuestion"];

    },

    alertStatus() {
      return this.$store.getters["question/getalertStatus"];

    },

    allowed() {
      return this.$store.getters["question/getallowed"];

    },

  },
  watch: {
      dataTakeQuestion() {
        this.idx = -1;
        this.params.bodyParams.comment = '';
        this.paramsEdit.bodyParams.comment = '';
      }
  },
  methods: {
    selectItem(item, index) {
      this.selectedItem = item;
      this.paramsEdit.commentId = item.frmCommentId;
      this.paramsEdit.bodyParams.comment = item.comment;

      this.idx = index;
    },

    postComment() {
      if (this.params.bodyParams.comment != "") {


        let maxCount = 1000
        let countComment = this.params.bodyParams.comment.replace(/(<([^>]+)>)/ig, '').length
        if (countComment > maxCount) {
          alert("Maaf jawaban anda lebih dari " + maxCount + " karakter");
          return;
        }
        this.params.questionId = this.dataTakeQuestion.data.payload.question.frmQuestionId
        this.$store
          .dispatch("question/postComment", this.params)
          .then((response) => {
            if (response.data.error != "") {
              this.alert = true
              this.params.bodyParams.comment = "";
              this.valueAlert = response.data.error;
            } else {
              this.info = true;
              this.params.bodyParams.comment = "";

              this.$store.dispatch("question/postTakeQuestion", this.params.questionId)

            }
          });

      } else {
        this.alert = true
        this.params.bodyParams.comment = "";

      }
    },

    putComment() {
      if (this.paramsEdit.bodyParams.comment != "") {


        let maxCount = 1000
        let countComment = this.paramsEdit.bodyParams.comment.replace(/(<([^>]+)>)/ig, '').length
        if (countComment > maxCount) {
          alert("Maaf jawaban anda lebih dari " + maxCount + " karakter");
          return;
        }
        this.paramsEdit.questionId = this.dataTakeQuestion.data.payload.question.frmQuestionId
        console.log("questionId : " + this.paramsEdit.questionId);
        console.log("comment : " + this.paramsEdit.commentId);
        console.log("jawaban : " + this.paramsEdit.bodyParams.comment);
        this.$store
          .dispatch("question/putComment", this.paramsEdit)
          .then((response) => {
            if (response.data.error != "") {
              this.alert = true
              this.paramsEdit.bodyParams.comment = "";
              this.valueAlert = response.data.error;
              this.idx = -1;
             console.log("valueAlert : " + response);
            } else {
              this.info = true;
              this.paramsEdit.bodyParams.comment = "";
              this.idx = -1;
              this.$store.dispatch("question/postTakeQuestion", this.paramsEdit.questionId)

            }
          });

      } else {
        this.alert = true
        this.paramsEdit.bodyParams.comment = "";

      }
    },

    postFinish() {
      this.$store
        .dispatch("question/postFinish", this.dataTakeQuestion.data.payload.question.frmQuestionId)
        .then(() => {
          this.$store.dispatch("question/postTakeQuestion", this.dataTakeQuestion.data.payload.question.frmQuestionId)
          this.dialogFinish = false
        });
    },
    postReject() {
      this.paramsReject.questionId = this.dataTakeQuestion.data.payload.question.frmQuestionId
      this.paramsReject.bodyParams.rejectDescription = this.reason
      this.$store
        .dispatch("question/postReject", this.paramsReject)
        .then(() => {
          this.$store.dispatch("question/postTakeQuestion", this.dataTakeQuestion.data.payload.question.frmQuestionId)
          this.dialogReject = false
        });
    },
    showDialog() {
      this.dialogVisible = true;
    },
    hideDialog() {
      this.dialogVisible = false;
    },

    hideEditor() {
      this.idx = -1;
    }
  },
};
</script>

<style>

.imageQuestion {
  width: 150px;
  height: 100px;
  margin-left: 25px;
  border-radius: 5px;
}

.comment {
  position: absolute;
  left: 0%;
  right: 0%;
  top: -2.51%;
  bottom: 0%;

  /* Background/BG White */
  background: #ffffff;

  /* Border/Primary Border */
  border: 1px solid #cfd4e1;
  box-sizing: border-box;
  border-radius: 10px;
}

.question-status {
  /* Label */

  /* Auto layout */
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 4px 10px;

  position: static;

  left: 304px;
  top: 6px;

  /* Mood/Warning */
  background: #ffc043;
  border-radius: 10px;

  /* Inside auto layout */
  flex: none;
  order: 5;
  flex-grow: 0;
  margin: 0px 10px;

  position: static;
  left: 10px;
  top: 4px;

  font-family: Nunito;
  font-style: normal;
  font-weight: 600;
  font-size: 14px;
  line-height: 14px;

  /* identical to box height, or 100% */
  display: flex;
  align-items: center;
  text-align: center;

  color: #ffffff;

  /* Inside auto layout */
  flex: none;
  order: 0;
  flex-grow: 0;
  margin: 0px 0px;
}

.question-value {
  font-family: Nunito;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  padding-bottom: 30px;
  color: #073059;

  /* Inside auto layout */
}

.doctor-answer {
  font-family: Nunito;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;

  color: #073059;
  background: #f7f7f7;
  padding: 20px;
}

.patient-answer {
  font-family: Nunito;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 150%;
  background: #eef7ff;

  color: #073059;
  padding: 20px;
}
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.6);
}

.dialog {
  flex-direction: column;
  grid-row: 1 / -1;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background: transparent;
  padding: 20px;
  border-radius: 5px;
  z-index: 9999;
}

.dialog img {
  width: 500px;
  height: 450px;
  margin-top: 30px;
  object-fit: contain;
  object-position: center;
  max-width: 100%;
  max-height: 100%;
}

.column-layout {
  display: flex;
  flex-direction: column;
}

.icon-close {
  padding: 8px;
  left: 90%;
  width: 20px;
  height: 20px;
}

.blue-text {
  font-weight: normal;
  color: #073059;
}

</style>