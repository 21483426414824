import axios from "axios";
import headers from "@/services/headers";
// const API_URL = "https://dev-api.klinisia.id/doctor/forum/question/"; //prod
const API_URL = "https://dev-api.klinisia.id/doctor/forum/question/"; //dev

class QuestionServices {

  // Question List
  async getQeustionList(page, limit) {
    console.log("page service = " + page);
    return await axios.get(API_URL + "list" + "?page=" + page + "&size=" + limit, {
      headers: headers(),
    });
  }

  // get question
  async getQuestion(questionId) {
    return await axios.get(
      API_URL + questionId, {
        headers: headers(),
      },
    );
  }

  // post comment
  async comment(params) {
    return await axios.post(API_URL + params.questionId + "/comment", params.bodyParams, {
      headers: headers(),
    });
  }

  // put comment
  async editComment(params) {
    console.log('paramEdit ' + params);
    return await axios.put(API_URL + params.questionId + "/comment/" + params.commentId, params.bodyParams, {
      headers: headers(),
    });
  }

  // finish
  async finish(questionId) {
    return await axios.post(API_URL + questionId + "/finish", {
      headers: headers(),
    });
  }

  // reject
  async reject(params) {
    return await axios.post(API_URL + params.questionId + "/reject", params.bodyParams, {
      headers: headers(),
    });
  }
}

export default new QuestionServices();