/**
 * @author [Mochamad Arifin] [Tandy Alam]
 * @email [mochamad.arifin@dwidasa.com]
 * @create date 2021-01-24 18:13:14
 * @modify date 2021-01-24 18:13:14
 * @desc [description]
 */

export default function Headers() {
  let shortToken = window.localStorage.getItem("shortToken");
  if (shortToken != null) {
    return {
      "Access-Control-Allow-Origin": "*",
      "Content-Type": "application/json",
      Authorization: "Bearer " + shortToken,
      feType: "doctor",
      feVersion: "2.7.36-20211025",
    };
  } else {
    return {};
  }
}
