/**
 * @author [Mochamad Arifin]
 * @email [mochamad.arifin@dwidasa.com]
 * @create date 2021-01-26 11:36:45
 * @modify date 2021-01-26 11:36:45
 * @desc [description]
 */

import axios from "axios";
import headers from "@/services/headers";
// const API_URL = "https://dev-api.klinisia.id/doctor/pharmacy/master/"; //prof
const API_URL = "https://dev-api.klinisia.id/doctor/pharmacy/master/"; //dev
//const API_URL = "https://demo-dev-api.klinisia.id/doctor/pharmacy/master/"; //demo

class PharmacyServices {
  async getMasterUnitType() {
    return await axios.get(API_URL + "unit-type", {
      headers: headers(),
    });
  }
  getMasterSearchMedice(query) {
    return axios.get(API_URL + "search-medicine?q=" + query, {
      headers: headers(),
    });
  }
  async getMasterPeriodType() {
    return await axios.get(API_URL + "period-type", {
      headers: headers(),
    });
  }
  async getMasterMediceType() {
    return await axios.get(API_URL + "medicine-type", {
      headers: headers(),
    });
  }
  async getMasterLovs() {
    return await axios.get(API_URL + "lovs", {
      headers: headers(),
    });
  }
  async getMasterInstructionType() {
    return await axios.get(API_URL + "instruction-type", {
      headers: headers(),
    });
  }
  async getMasterHtuType() {
    return await axios.get(API_URL + "how-to-use-type", {
      headers: headers(),
    });
  }
}

export default new PharmacyServices();
