/**
 * @author [Mochamad Arifin]
 * @email [mochamad.arifin@dwidasa.com]
 * @create date 2021-02-21 16:22:29
 * @modify date 2021-02-21 16:22:29
 * @desc [description]
 */
<template>
  <v-container>
    <v-list v-chat-scroll class="messageChat">
      <v-list-item
        v-for="(listComments, key) in getCommentChat.comments"
        :key="key"
      >
        <v-row>
          <v-col>
            <v-container
              v-if="listComments.username_as != isMe"
              fluid
              class="buble"
            >
              <v-row class="ma-0 pa-0">
                <div class="chattext" v-if="listComments.type == 'text'">
                  <p>{{ listComments.message }}</p>
                  <div v-if="listComments.isSent == true">
                    <vue-audio :file="file1" :autoPlay="true" hidden />
                  </div>
                  <div v-if="listComments.isSent == false">
                    <vue-audio :file="file1" :autoPlay="false" hidden />
                  </div>
                </div>
                <div v-if="listComments.type == 'file_attachment'" class="mr-3">
                  <div @click="handleAttachment(listComments)">
                    <embed
                      :src="listComments.payload.url"
                      width="100%"
                      height="100%"
                      alt=""
                    />
                    <a class="txtImg"> Click Here To Open File </a>
                  </div>
                  <div v-if="listComments.isSent == true">
                    <vue-audio :file="file1" :autoPlay="true" hidden />
                  </div>
                  <div v-if="listComments.isSent == false">
                    <vue-audio :file="file1" :autoPlay="false" hidden />
                  </div>
                </div>
              </v-row>
            </v-container>
          </v-col>

          <v-col
            v-if="
              listComments.message ==
              'Selamat datang Bapak/Ibu di layanan telemedicine kami. Apakah saat ini saya terhubung dengan Bapak/Ibu ' +
                pasien +
                ' ?' +
                'Perkenankan saya ' +
                doctor +
                ', untuk memulai konsultasi ini.'
            "
          >
            <v-container
              v-if="listComments.username_as == isMe"
              fluid
              class="bublebot"
            >
              <v-row class="ma-0 pa-0">
                <div class="chattext" v-if="listComments.type == 'text'">
                  <p>{{ listComments.message }}</p>
                </div>

                <div
                  v-if="listComments.type == 'file_attachment'"
                  class="mr-3"
                  @click="handleAttachment(listComments)"
                >
                  <div>
                    <embed
                      :src="listComments.payload.url"
                      width="100%"
                      height="100%"
                      alt=""
                    />
                    <a class="txtImg2"> Click Here To Open File </a>
                  </div>
                </div>
              </v-row>
            </v-container>
          </v-col>

          <v-col
            v-else-if="
              listComments.message == 'Waktu konsultasi Anda tinggal 5 menit lagi.'
            "
          >
            <v-container
              v-if="listComments.username_as == isMe"
              fluid
              class="bublebot"
            >
              <v-row class="ma-0 pa-0">
                <div class="chattext" v-if="listComments.type == 'text'">
                  <p>{{ listComments.message }}</p>
                </div>

                <div
                  v-if="listComments.type == 'file_attachment'"
                  class="mr-3"
                  @click="handleAttachment(listComments)"
                >
                  <div>
                    <embed
                      :src="listComments.payload.url"
                      width="100%"
                      height="100%"
                      alt=""
                    />
                    <a class="txtImg2"> Click Here To Open File </a>
                  </div>
                </div>
              </v-row>
            </v-container>
          </v-col>
          <v-col v-else>
            <v-container
              v-if="listComments.username_as == isMe"
              fluid
              class="bubleme"
            >
              <v-row class="ma-0 pa-0">
                <div class="chattext" v-if="listComments.type == 'text'">
                  <p>{{ listComments.message }}</p>
                </div>

                <div
                  v-if="listComments.type == 'file_attachment'"
                  class="mr-3"
                  @click="handleAttachment(listComments)"
                >
                  <div>
                    <embed
                      :src="listComments.payload.url"
                      width="100%"
                      height="100%"
                      alt=""
                    />
                    <a class="txtImg2"> Click Here To Open File </a>
                  </div>
                </div>
              </v-row>
            </v-container>
          </v-col>
        </v-row>
      </v-list-item>
    </v-list>
    <br />
    <v-row v-if="getStartTime == true" justify="center" class="time">
      <v-chip label small :color="colors" class="timerChattext">
        <p class="timerChat mt-4">Time Remaining {{ time2 }}</p>
        <!-- <p class="timerChat ml-2 mt-4">{{minute}}</p>
        <p class="timerChat mt-4">:</p> -->
        <!-- <p class="timerChat mt-4">{{time2}}</p> -->
      </v-chip>
    </v-row>
    <v-row v-if="getStartTime == false" justify="center" class="time">
      <v-chip label small color="#FA3D6D">
        <p class="timerChat mt-4 wchat">Waktu Chating Telah Berakhir</p>
      </v-chip>
    </v-row>
  </v-container>
</template>
<script>
import useSound from "vue-use-sound";
import notif from "@/assets/ios_notification.mp3";
import VueAudio from "vue-audio";
import moment from "moment";
export default {
  data: () => ({
    date: moment(60 * 1 * 1000),
    tanggal: moment("30:00", "mm:ss"),
    tanggal1: moment("05:00", "mm:ss"),
    tanggal3: moment("01:00", "mm:ss"),
    tanggal2: moment("00:00", "mm:ss"),
    tanggal4: moment("04:59", "mm:ss"),
    file1: notif,
    isMe: "",
    participant: "",
    dialog: false,
    isImg: true,
    bootChat: "",
    img: "",
    alt: "",
    gdocs: "",
    commentId: [],
    // newTime: 1800000,
    newTime: "",
    colors: "",
    textcolors: "",
    riwayatTime: "",
    tfComment: "",
    katakata: "",
    distance: window.localStorage.getItem("endTime"),
    second: null,
    minute: null,
    kirim: false,
    x: 0,
    pasien: "",
    doctorName: "",
  }),
  components: {
    "vue-audio": VueAudio,
  },
  computed: {
    time5: function () {
      return this.tanggal3.format("mm:ss");
    },
    time6: function () {
      return this.tanggal4.format("mm:ss");
    },
    time4: function () {
      return this.tanggal1.format("mm:ss");
    },
    time3: function () {
      return this.tanggal2.format("mm:ss");
    },
    time2: function () {
      return this.tanggal.format("mm:ss");
    },
    getCommentChat() {
      return this.$store.getters["consultation/getResponseQiscus"];
    },
    Boot() {
      return this.$store.getters["consultation/getBoot"];
    },
    doctor() {
      return this.$store.getters["auth/getDataFullName"];
    },
    getChatTimer() {
      return this.$store.getters["consultation/getTimeChat"];
    },
    getStartTime() {
      return this.$store.getters["consultation/getShowTime"];
    },
    dateInMilliseconds() {
      return Math.trunc(this.newTime / 1000);
    },
    // seconds() {
    //   console.log('distance' ,this.distance)
    //   return Math.floor((this.distance % (1000 * 60)) / 1000)
    // },
    // minutes() {
    //   return (
    //     Math.floor((this.distance % (1000 * 60 * 60)) / (1000 * 60))
    //   );
    // },
  },
  methods: {
    setBoot() {
      console.log(this.getCommentChat);
    },
    setup() {
      const [play] = useSound(notif);
      // console.log("music play");
      return {
        play,
      };

      //  var sound = new Audio(notif)
      //  sound.play();
    },
    // gettime() {
    //   if (this.getStartTime) {
    //     console.log("get timer ", this.getChatTimer);
    //     var T = window.setInterval(() => {
    //       var now = new Date().getTime();
    //       console.log(now);
    //       var waktu = this.getChatTimer - now;
    //       this.second = Math.floor((waktu % (1000 * 60)) / 1000);
    //       this.minute = Math.floor((waktu % (1000 * 60 * 60)) / (1000 * 60));
    //       console.log("minutes ", this.minute);
    //       console.log("seconds ", this.second);
    //       console.log("distance1 ", now);
    //       this.colors = "#88FFC6";
    //       this.textcolors = "#0D8A4E";
    //       if (this.minute < 5) {
    //         this.colors = "#FCFA3D";
    //         this.textcolors = "#0D8A4E";
    //         this.tfComment = "Waktu Konsultasi Kurang 5 Menit lagi";
    //         this.$qiscus.sendComment(this.getCommentChat.id, this.tfComment);
    //       }
    //       if (this.minute < 1) {
    //         this.colors = "#FA3D6D";
    //         this.textcolors = "white";
    //       }
    //       if (this.minute === 0 && this.second === 0) {
    //         clearInterval(T);
    //         this.$store.dispatch("consultation/actShowTime", false);
    //         localStorage.removeItem("endTime");
    //       }
    //     }, 1000);
    //     // }
    //   }
    // },
    handleAttachment(obj) {
      window.open(obj.payload.url);
    },
  },
  mounted() {
    window.localStorage.removeItem("time");
    this.getCommentChat;
    this.doctor
    this.isMe = window.localStorage.getItem("fullName");
    if (this.getStartTime) {
      if (localStorage.getItem("in") !== null) {
        this.tanggal = moment(`${localStorage.getItem("in")}`, "mm:ss");
      }
      setInterval(() => {
        if (this.time2 === this.time3) {
          clearInterval(yes);
        }
      }, 100);
      if (this.time2 === this.time3) {
        // var nyoba = this.time2.toString()
        clearInterval(yes);
        // window.localStorage.removeItem("interval");
        this.$store.dispatch("consultation/actShowTime", false);
      } else {
        // this.try()
        this.colors = "#88FFC6";
        this.textcolors = "#0D8A4E";
        var yes = setInterval(() => {
          if (window.localStorage.getItem("time") !== null) {
            clearInterval(yes);
            window.localStorage.removeItem("in");
            //  console.log(localStorage.getItem("time"));
          } else {
            this.tanggal = moment(this.tanggal.subtract(1, "s"));
            localStorage.setItem("in", this.time2);
            // console.log(localStorage.getItem("time"));
            if (this.time2 < this.time4) {
              this.colors = "#FCFA3D";
              this.kirim = true;
            }
            if (this.time2 < this.time6) {
              this.colors = "#FCFA3D";
              this.textcolors = "#0D8A4E";
              this.kirim = false;
              // this.tfComment = "Waktu Konsultasi Kurang 5 Menit lagi";
              // this.$qiscus.sendComment(this.getCommentChat.id, this.tfComment);
            }
            if (this.time2 < this.time5) {
              this.colors = "#FFA0A0";
              this.textcolors = "#FFFFFF";
            }

            if (this.time2 === this.time3) {
              // var nyoba = this.time2.toString()
              clearInterval(yes);
              //  / window.localStorage.removeItem("interval");
              this.$store.dispatch("consultation/actShowTime", false);
            }
            if (this.kirim === true) {
              // this.bootChat = "bublebot";
              this.$store.dispatch("consultation/actBoot", true);
              this.tfComment = "Waktu konsultasi Anda tinggal 5 menit lagi.";
              this.$qiscus.sendComment(this.getCommentChat.id, this.tfComment);
            } else {
              // console.log("false");
              // this.bootChat = "bubleme";
              this.$store.dispatch("consultation/actBoot", false);
            }
          }
        }, 1000);
      }
      console.log(this.date.format("mm:ss"));
    }
  },
  watch: {
    getCommentChat() {
      for (
        let index = 0;
        index < this.getCommentChat.participants.length;
        index++
      ) {
        const element = this.getCommentChat.participants[1];
        this.participant = element.username;
        const element2 = this.getCommentChat.participants[0];
        this.doctorName = element2.username;
      }
      this.pasien = this.getCommentChat.name;
      this.katakata =
        "Selamat datang Bapak/Ibu di layanan telemedicine kami. Apakah saat ini saya terhubung dengan Bapak/Ibu " +
        this.pasien +
        " ?" +
        "\n" +
        "Perkenankan saya " +
        this.doctorName +
        ", untuk memulai konsultasi ini.";
      console.log("jj", this.katakata);
      // for (let i = 0; i < this.getCommentChat.comments.length; i++) {
      //   console.log("i", this.getCommentChat.comments[i].message);
      //   if (
      //     this.getCommentChat.comments[i].message ===
      //     'Waktu Konsultasi Kurang 5 Menit lagi'
      //   ) {
      //     this.bootChat = "bublebot";
      //   } else {
      //     this.bootChat = "bubleme";
      //   }
      // }
    },
  },
};
</script>
<style scoped>
.txtImg {
  font-size: 12px;
  color: white;
  font-family: Nunito;
}
.txtImg2 {
  font-size: 12px;
  color: rgb(3, 3, 3);
  font-family: Nunito;
}
.v-list {
  height: 68vh;
}
.buble {
  background: linear-gradient(90.54deg, #60a9f6 0%, #2a8bf2 100%);
  border-top-left-radius: 0px;
  border-bottom-left-radius: 13px;
  border-top-right-radius: 13px;
  border-bottom-right-radius: 13px;
  margin-top: 1px;
  color: white;
  overflow-y: auto;
  box-shadow: 10px 10px 25px rgba(42, 139, 242, 0.1),
    15px 15px 35px rgba(42, 139, 242, 0.05),
    10px 10px 50px rgba(42, 139, 242, 0.1);
  max-width: 250px;
}
.bubleme {
  background: #ffffff;
  border-top-left-radius: 13px;
  border-bottom-left-radius: 13px;
  border-top-right-radius: 13px;
  border-bottom-right-radius: 0px;
  margin-top: 1px;
  border: 1px solid rgba(112, 124, 151, 0.25);
  box-sizing: border-box;
  box-shadow: 10px 10px 25px rgba(112, 124, 151, 0.05),
    15px 15px 35px rgba(112, 124, 151, 0.05);
}
.bublebot {
  background: #4cf2a6;
  border-top-left-radius: 13px;
  border-bottom-left-radius: 13px;
  border-top-right-radius: 13px;
  border-bottom-right-radius: 0px;
  margin-top: 1px;
  color: #ffffff;
  border: 1px solid rgba(112, 124, 151, 0.25);
  box-sizing: border-box;
  box-shadow: 10px 10px 25px rgba(112, 124, 151, 0.05),
    15px 15px 35px rgba(112, 124, 151, 0.05);
}
.timerChat {
  color: #000000;
  font-weight: bold;
  font-size: 12px;
}
.wchat {
  color: #ffffff;
}
.chips-container {
  color: #2a8bf2;
}
.messageChat {
  overflow-y: auto;
}
.chattext {
  font-family: Nunito;
  font-size: 12px;
  margin-top: 0%;
  white-space: pre-line;
  word-wrap: break-word;
  max-width: 300px;

}

@media only screen and (max-width: 1366px) {
  .chattext {
    font-family: Nunito;
    font-size: 11px;
    margin-top: 0%;
    white-space: pre-line;
    word-wrap: break-word;
    max-width: 200px;
  }
  .v-list {
    height: 60vh;
  }
  .buble {
    background: linear-gradient(90.54deg, #60a9f6 0%, #2a8bf2 100%);
    border-top-left-radius: 0px;
    border-bottom-left-radius: 13px;
    border-top-right-radius: 13px;
    border-bottom-right-radius: 13px;
    margin-top: 1px;
    color: white;
    overflow-y: auto;
    box-shadow: 10px 10px 25px rgba(42, 139, 242, 0.1),
      15px 15px 35px rgba(42, 139, 242, 0.05),
      10px 10px 50px rgba(42, 139, 242, 0.1);
    max-width: 250px;
  }
  .bubleme {
    background: #ffffff;
    border-top-left-radius: 13px;
    border-bottom-left-radius: 13px;
    border-top-right-radius: 13px;
    border-bottom-right-radius: 0px;
    margin-top: 1px;
    border: 1px solid rgba(112, 124, 151, 0.25);
    box-sizing: border-box;
    box-shadow: 10px 10px 25px rgba(112, 124, 151, 0.05),
      15px 15px 35px rgba(112, 124, 151, 0.05);
    min-width: 175px;
    max-width: 250px;
  }
}
</style>