/**
 * @author [Mochamad Arifin][Tandy Alam][Iman Akbar]
 * @email [mochamad.arifin@dwidasa.com][tandy.hutama@dwidasa.com][iman.akbar@dwidasa.com]
 * @create date 2021-01-11 12:51:34 
 * @modify date 2021-05-04 12:51:34
 * @desc [description]
 */

<template>
    <v-app>
        <v-main class="bgColor">
            <v-container fill-height fluid class="bgColor2">
                <v-row align="center" justify="center">
                    <v-col>
                        <v-card class="ma-5 pa-5">
                            <v-row align="center" justify="center">
                                <v-col>
                                    <v-row justify="center">
                                        <img src="@/assets/klinisia logo final 1.png" width="200" height="90" alt="" class="">
                                    </v-row>
                                    <v-row justify="center" class="mt-3">
                                        <p class="lblLupaPass">Change Password?</p>
                                    </v-row>
                                </v-col>
                            </v-row>

                            <v-row align="center" justify="center" class="">
                                <v-col cols="12" lg="6">
                                    <p class="lblDesc">Silahkan ubah password default Anda</p>
                                </v-col>
                            </v-row>

                            <v-row align="center" justify="center">
                                <v-col class="" cols="12" lg="6">
                                    <v-alert :value="alertAuthError" type="error">
                                        <p>{{valueAlert}}</p>
                                    </v-alert>
                                    <v-form
                                      ref="form"
                                     v-model="valid"
                                     lazy-validation>
                                    <p class="lblTfEmail">Old Password*</p>
                                    <v-text-field 
                                        v-model="params.oldPassword" 
                                        placeholder="Enter old password" 
                                        outlined
                                        required
                                        counter
                                        :rules="passwordRules"
                                        :type="showOldPass ? 'text' : 'password' "
                                        :append-icon="showOldPass ? 'mdi-eye' : 'mdi-eye-off'"
                                        @click:append = "showOldPass = !showOldPass"
                                    >
                                    </v-text-field>


                                    <p class="lblTfEmail">New Password*</p>
                                    <v-text-field
                                        v-model="params.newPassword" 
                                        placeholder="Enter new password" 
                                        outlined
                                        required
                                        counter
                                        :rules="passwordRules"
                                        :type="showNewPass ? 'text' : 'password' "
                                        :append-icon="showNewPass ? 'mdi-eye' : 'mdi-eye-off'"
                                        @click:append = "showNewPass = !showNewPass"
                                    >
                                    </v-text-field>


                                    <p class="lblTfEmail">Confirm New Password*</p>
                                    <v-text-field 
                                        v-model="params.reNewPassword" 
                                        placeholder="Enter confrim new password" 
                                        outlined
                                        required
                                        counter
                                        :rules="passwordRules"
                                        :type="showReNewPass ? 'text' : 'password' "
                                        :append-icon="showReNewPass ? 'mdi-eye' : 'mdi-eye-off'"
                                        @click:append = "showReNewPass = !showReNewPass"
                                    >
                                    </v-text-field>
                                    </v-form>
                                </v-col>
                            </v-row>

                            <v-row align="center" justify="center">
                                <v-col cols="12" lg="6">
                                    <v-btn @click="successReset" block large class="primary" >Kirim</v-btn>
                                </v-col>
                            </v-row>

                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
        </v-main>
    </v-app>
</template>

<script>
// import axios from 'axios'

export default {
    name: 'ChangePass',
    data: () => ({
        alertAuthError: false,
        valueAlert: '',
        showOldPass: false,
        showNewPass: false,
        showReNewPass: false,
        params: {
            oldPassword: '',
            newPassword: '',
            reNewPassword: '',
        },
        passwordRules: [
            v => !!v || 'Password is required',
            v => v.length >= 8 || 'Min 8 characters', 
        ],
    }),
    methods: {
        successReset() {
            if (this.params.oldPassword != '' && this.params.newPassword != '' && this.params.reNewPassword != '') {     
                if (this.params.newPassword === this.params.reNewPassword) {
                    this.$store.dispatch('auth/actChangePass', this.params).then(
                    response => {
                            if(response.data.error != "") {
                                this.alertAuthError = true
                                this.valueAlert = response.data.error
                            }else{
                                this.$router.push('/home')
                            } 
                        }
                    )   
                }else {
                    this.alertAuthError = true
                    this.valueAlert = "Password Tidak Sama!!"
                    this.$refs.form.validate()
                }
            }else{
                this.alertAuthError = true
                this.valueAlert = "Inputan wajib diisi"
                this.$refs.form.validate()
                // this.showOldPass = true
            }
        }
    },    
}
</script>

<style scoped>
.bgColor {
  background: #5C95FF;
}
.bgColor2 {
  background: #EDF4FB;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  width: 100%;
  height: 100%;
}
.lblLupaPass {
        font-family: Inter;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 26px;
        display: flex;
        align-items: center;
        margin-top: -35;
        color: #2A8BF2;
    }
.lblDesc {
    font-family: Inter;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    align-items: center;
    text-align: center;
      margin-top: -25;
    color: #231F20;
}
.lblTfEmail {
    font-family: Inter;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 10px;
    display: flex;
    align-items: center;

    color: #696F79;
}
</style>