/**
 * @author [Mochamad Arifin]
 * @email [mochamad.arifin@dwidasa.com]
 * @create date 2021-01-15 17:17:08
 * @modify date 2021-01-15 17:17:08
 * @desc [description]
 */


<template>
  <v-row class="ml-13 mr-13">
    <v-col cols="12">
      <div class="lblTitle">Diet</div>
      <!-- <v-text-field
        v-model="params.bodyParams.dietNote"
        class="customTF"
        placeholder="Add Notes"
        outlined
        hide-details
        :readonly="isAgree"
        clearable
      > 
      </v-text-field> -->

      <v-textarea
        maxlength="2000"
        v-model="params.bodyParams.dietNote"
        class="customTF"
        placeholder="Add Notes"
        outlined
        dense
        hide-details
        :readonly="isAgree"
        clearable
        auto-grow
        rows="1"
        rounded
      />
    </v-col>
    <v-col cols="12">
      <div class="lblTitle">Aktivitas</div>
      <!-- <v-text-field
        v-model="params.bodyParams.activityNote"
        class="customTF"
        placeholder="Add Notes"
        outlined
        hide-details
        :readonly="isAgree"
        clearable
      >
      </v-text-field> -->
      <v-textarea
        maxlength="2000"
        v-model="params.bodyParams.activityNote"
        class="customTF"
        placeholder="Add Notes"
        outlined
        dense
        rounded
        hide-details
        :readonly="isAgree"
        clearable
        auto-grow
        rows="1"
      />
    </v-col>
    <v-col cols="12">
      <v-checkbox
        v-model="isAgree"
        label="Tidak ada anjuran untuk pasien"
        color="info"
        hide-details
        @change="clearTF"
      ></v-checkbox>
    </v-col>
    <v-col cols="12">
      <!-- <alert :type="typeAlert" :message="textAlert" v-show="alertShow"></alert> -->
       <v-alert class="" text dense :type="typeAlert" v-show="alertShow">{{textAlert}}</v-alert>
      <v-btn
        class="float-right white--text"
        @click="postSugestion"
        rounded
        color="#00CC6A"
      >
        Submit
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import ConsultationService from "@/services/consultation_services";
export default {
  data: () => ({
    diet: "",
    activity: "",
    isAgree: false,
    typeAlert: "success",
    alertShow: false,
    textAlert: "",
    params: {
      uriParam: window.localStorage.getItem("cntConsultationId"),
      bodyParams: {
        cntSuggestionId: "",
        cntConsultationId: window.localStorage.getItem("cntConsultationId"),
        dietStatus: false,
        dietNote: "",
        activityStatus: false,
        activityNote: "",
        noSuggestionStatus: false,
        toEntityUpdate: {
          cntSuggestionId: "",
          cntConsultationId: window.localStorage.getItem("cntConsultationId"),
          dietStatus: false,
          dietNote: "",
          activityStatus: false,
          activityNote: "",
          noSuggestionStatus: false,
          createdBy: window.localStorage.getItem("cntConsultationId"),
          createdOn: new Date(),
          updatedBy: window.localStorage.getItem("cntConsultationId"),
          updatedOn: new Date(),
          active: true,
          deleted: true,
        },
      },
    },
  }),
  mounted() {
    this.getStatusDenganJanji
    let statusMenu = this.getResponseTakeQueue.data.payload.consultation
      .suggestionStatus;
    console.log("Status Menu Sugstion " + statusMenu);
    if (statusMenu) {
      ConsultationService.getSugestion(
        window.localStorage.getItem("cntConsultationId")
      ).then((response) => {
        console.log("Plan Get Sugestion " + JSON.stringify(response));
        this.params.bodyParams.dietNote = response.data.payload.dietNote;
        this.params.bodyParams.activityNote =
          response.data.payload.activityNote;
      });
    }
  },
  computed: {
    getStatusDenganJanji() {
      return this.$store.getters["consultation/getDenganJanji"];
    },
    getResponseTakeQueue() {
      if (this.getStatusDenganJanji !== true) {
        return this.$store.getters["consultation/getResponseTakeQueue"];
      } else {
        return this.$store.getters["consultation/getResponseTakeQueueWithApp"];
      }
    },
  },
  methods: {
    capitalizeFirstLetter: (str) => {
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
    postSugestion() {
      if (this.params.bodyParams.dietNote.length != 0) {
        this.params.bodyParams.dietStatus = true;
        this.params.bodyParams.toEntityUpdate.dietStatus = true;
        this.params.bodyParams.toEntityUpdate.dietNote = this.params.bodyParams.dietNote;
      }
      if (this.params.bodyParams.activityNote.length != 0) {
        this.params.bodyParams.activityStatus = true;
        this.params.bodyParams.toEntityUpdate.activityStatus = true;
        this.params.bodyParams.toEntityUpdate.activityNote = this.params.bodyParams.activityNote;
      }
      this.params.bodyParams.noSuggestionStatus = this.isAgree;
      this.params.bodyParams.toEntityUpdate.noSuggestionStatus = this.isAgree;

      if (this.isAgree) {
        this.$store
          .dispatch("consultation/actPostSugestion", this.params)
          .then((response) => {
            if (response.data.error != "") {
              this.typeAlert = "error";
              this.textAlert = response.data.error;
            } else {
              this.typeAlert = "success";
              this.textAlert = "Data berhasil di input";
            }
            (this.alertShow = true),
              setTimeout(() => {
                this.alertShow = false;
              }, 5000);
          });
      } else if (
        this.params.bodyParams.dietNote === "" &&
        this.params.bodyParams.activityNote === ""
      ) {
        this.typeAlert = "error";
        this.textAlert = "Tidak ada data yang diinput";
        this.alertShow = true;
        setTimeout(() => {
          this.alertShow = false;
        }, 5000);
      } else {
        this.$store
          .dispatch("consultation/actPostSugestion", this.params)
          .then((response) => {
            if (response.data.error != "") {
              this.typeAlert = "error";
              this.textAlert = response.data.error;
            } else {
              this.typeAlert = "success";
              this.textAlert = "Data berhasil di input";
            }
            (this.alertShow = true),
              setTimeout(() => {
                this.alertShow = false;
              }, 5000);
          });
      }
    },
    clearTF() {
      if (this.isAgree) {
        this.params.bodyParams.dietNote = "";
        this.params.bodyParams.activityNote = "";
      }
    },
    watch: {
      // firstName: function(newValue) {
      //     this.firstName = this.capitalizeFirstLetter(newValue);
      //   },
      //   firstName: function(newValue) {
      //     this.firstName = this.capitalizeFirstLetter(newValue);
      //   },
    },
  },
};
</script>

<style scoped>
.customTF {
  background: #EDF4FB;
  /* border: 1px solid #EBF0FF;
  box-sizing: border-box;
  border-radius: 20px;
  max-height: 120px; */
overflow-y: scroll;
font-size: 14px;
}
.lblTitle {
  font-size: 18px;
  color: #073059;
}
</style>