/**
 * @author [Mochamad Arifin]
 * @email [mochamad.arifin@dwidasa.com]
 * @create date 2021-02-04 16:43:54
 * @modify date 2021-02-04 16:43:54
 * @desc [description]
 */

export const chat = {
  namespaced: null,
  state: {
    isOnline: {},
    greting: 1,
  },
  mutations: {
    mtnGreting: (state, value) => {
      state.greting = value;
    },
    mtnStatusOnline: (state, value) => {
      state.isOnline = value;
    },
  },
  actions: {
    actGreting({ commit }, value) {
      commit("mtnGreting", value);
      console.log("oooh ", value);
    },
    actStatusOnline({ commit }, value) {
      commit("mtnStatusOnline", value);
    },
  },
  getters: {
    getIsOnline: (state) => state.isOnline,
    getGreting: (state) => state.greting,
  },
};
