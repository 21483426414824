/**
 * @author [Mochamad Arifin]
 * @email [mochamad.arifin@dwidasa.com]
 * @create date 2021-01-13 11:42:35
 * @modify date 2021-01-13 11:42:35
 * @desc [description]
 */


<template>
    <v-row>
      <v-col cols="12 mt-15">
        <v-container fluid fill-height  class="customContainer">
          <v-row align="center" justify="center">
            <v-col>
              <v-tabs fixed-tabs slider-color="blue">
                <v-tab @click="currentComponent = 'Obat'">
                  <img src="@/assets/iconobat.png" width="30" height="30" class="mr-1" alt="">
                  <div class="menuTabStyle">Obat</div>
                </v-tab>
                <v-tab @click="currentComponent = 'Diagnostik'">
                  <img src="@/assets/icondiagnosis.png" width="30" height="30" class="mr-1" alt="">
                  <div class="menuTabStyle">Diagnostik</div>
                </v-tab>
                <v-tab @click="currentComponent = 'Laboratorium'">
                  <img src="@/assets/iconlab.png" width="30" height="30" class="mr-1" alt="">
                  <div class="menuTabStyle">Laboratorium</div>
                </v-tab>
                <v-tab @click="currentComponent = 'SaranLain'">
                  <div class="menuTabStyle">Saran Lainnya</div>
                </v-tab>
              </v-tabs>

              <!-- tab items -->
              
                 <v-tabs-items vertical class="">
                    <v-card max-height="300" class="scroll pa-4" flat>
                        <v-row>
                            <keep-alive>
                                <component :is="currentComponent"/>
                            </keep-alive>
                        </v-row>
                    </v-card>
                </v-tabs-items>
             
            </v-col>
          </v-row>
        </v-container>
      </v-col>
    </v-row>
</template>

<script>
import Obat from '@/components/Obat'
import Diagnostik from '@/components/Diagnostik'
import Laboratorium from '@/components/Laboratorium'
import SaranLain from '@/components/SaranLain'

export default {
  data: () => ({
    currentComponent: 'Obat',
    currentTab:0,
    tabMenuPlanigTag: 1,
  }),
  components: {
    Obat,
    Diagnostik,
    Laboratorium,
    SaranLain,
  },
}
</script>

<style scoped>
.scroll {
    display: flex !important;
    flex-direction: column;
}
.v-card {
    background: #ffffff;
    overflow-y: scroll;
     overflow-x: hidden;
}
/* .v-card:hover {
    overflow-y: scroll;
   
} */
.v-tab {
    text-transform: none !important;
}
.customContainer {
  background: #FFFFFF;
  box-shadow: 0px 4px 18px rgba(68, 133, 253, 0.25);
  border-radius: 10px;
 
}
.menuTabStyle {
  color: black;
  font-size: 14px;
  line-height: 25px;
  font-family: Nunito;
}
@media only screen and (max-width: 1366px) {
.menuTabStyle {
  color: black;
  font-size: 12px;
  line-height: 20px;
  font-family: Nunito;
}
}
</style>