/**
 * @author [Mochamad Arifin]
 * @email [mochamad.arifin@dwidasa.com]
 * @create date 2020-12-28 15:37:07
 * @modify date 2020-12-28 15:37:07
 * @desc [description]
 */

<template>
  <nav>
    <v-navigation-drawer app permanent v-model="drawer" dark color="#5C95FF" width="100">
      <v-list class="ml-3" flat>
        <v-list-item class="mb-12">
          <v-list-item-content>
            <img src="@/assets/iconLogo.png" height="60" alt />
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <v-list class="navigasi1" flat>
        <v-list-item @click="dashboard">
          <img src="@/assets/bx_bxs-home-smile.png" class="ml-4" width="40" height="35" alt />
        </v-list-item>
        <v-list-item>
          <p class="name">Dashboard</p>
        </v-list-item>

        <v-list-item @click="calender">
          <img src="@/assets/calender.png" class="ml-6" width="25" height="25" alt />
        </v-list-item>
        <v-list-item>
          <p class="ml-3 name">Kalender</p>
        </v-list-item>

        <v-list-item @click="chatgreeting">
          <img src="@/assets/bi_chat-square-dots-fill.png" class="ml-6" width="25" height="25" alt />
        </v-list-item>
        <v-list-item>
          <p class="ml-5 name">Chat</p>
        </v-list-item>

        <v-list-item @click="question" v-show="questionFeature">
          <img src="@/assets/question.png" class="ml-6" width="25" height="25" alt />
        </v-list-item>
        <v-list-item v-show="questionFeature">
          <p class="ml-2 name">Question</p>
        </v-list-item>
      </v-list>

      <v-list style="position: absolute; bottom: 0" class="ml-3" flat>
        <v-list-item @click="changepass">
          <img src="@/assets/ant-design_setting-filled.png" class="ml-2" width="30" height="30" alt />
        </v-list-item>
        <v-list-item>
          <p class="name">Setting</p>
        </v-list-item>
        <v-list-item @click="logout">
          <v-icon class="ml-3">fas fa-sign-out-alt</v-icon>
        </v-list-item>
        <v-list-item>
          <p class="name">Log Out</p>
        </v-list-item>
      </v-list>
    </v-navigation-drawer>
  </nav>
</template>
<script src="https://code.iconify.design/1/1.0.7/iconify.min.js"></script>
<script>
// import NotificationServices from "@/services/notificationServices";
export default {
  name: "app-drawer",
  data: () => ({
    drawer: false,
    paramsLogout: {
      secUserId: window.localStorage.getItem("secUserId"),
      deviceType: "browser",
      deviceCode: "123",
      longToken: window.localStorage.getItem("longToken"),
    },
    questionFeature: window.localStorage.getItem('questionFeature')


  }),

  methods: {
    logout() {
      // NotificationServices.deleteTokenfcm().then((response1) => {
      //   console.log(response1);
      //   console.log("berhasil delete");
      // });
      this.$store
        .dispatch("auth/actDoLogout", this.paramsLogout)
        .then((response) => {
          console.log(response);
          window.localStorage.clear();
          window.localStorage.removeItem('in');
          this.$router.push("/login");
          window.location.reload();
        });

    },
    changepass() {
      this.$router.push("/changepass");
    },
    calender() {
      this.$router.push("/calender");
    },
    chatgreeting() {
      this.$router.push("/chat");
    },

    question() {
      this.$router.push("/question");
    },

    dashboard() {
      this.$router.push("/home");
      this.$store.dispatch("consultation/actSetTabAssesment", true);
      this.$store.dispatch("consultation/actSetTabPlanning", true);
    },
  },
};
</script>

<style scoped>
.labelMenu {
  text-align: center;
}
.navigasi1 {
  margin-top: 0;
}

@media only screen and (max-width: 1920px) {
  .navigasi1 {
    margin-top: -20px;
  }
  .name {
    font-size: 14px;
  }
}

.v-list-item {
  min-height: 36px;
}



</style>


