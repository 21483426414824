/**
 * @author [Mochamad Arifin]
 * @email [mochamad.arifin@dwidasa.com]
 * @create date 2020-12-28 15:37:40
 * @modify date 2020-12-28 15:37:40
 * @desc [description]
 */

<template>
  <v-app class="ma-0 pa-0">
    <Navbar />
    <router-view />
  </v-app>
</template>

<script>
import Navbar from "@/components/Navbar";

export default {
  name: "Layout",
  components: {
    Navbar,
  },
};
</script>