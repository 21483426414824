/**
 * @author [Tandy ALam]
 * @email [mochamad.arifin@dwidasa.com]
 * @create date 2021-01-24 18:13:22
 * @modify date 2021-01-24 18:13:22
 * @desc [description]
 */

import axios from "axios";

const API_URL = "https://dev-api.klinisia.id/notification/"; //prod
// const API_URL = "https://dev-api.klinisia.id/notification/"; //dev
//const API_URL = "https://demo-dev-api.klinisia.id/doctor/consultation/"; //demo

class NotificationServices {
  async getTokenfcm() {
    return await axios.get(API_URL + "fcm/token");
  }

  async registerTokenfcm(bodyParams) {
    return await axios.post(API_URL + "fcm/token/register", bodyParams);
  }
  async deleteTokenfcm() {
    return await axios.delete(API_URL + "fcm/token/clear");
  }
}

export default new NotificationServices();
