/**
 * @author [Mochamad Arifin]
 * @email [mochamad.arifin@dwidasa.com]
 * @create date 2021-01-18 22:19:13
 * @modify date 2021-01-18 22:19:13
 * @desc [description]
 */


<template>
  <v-row>
    <v-col cols="12">
      <v-container fluid fill-height class="py-0">
        <v-row>
          <v-col cols="4">
            <v-combobox
              placeholder="Category"
              v-model="filterBy"
              :items="category"
              solo
              flat
              dense
              hide-details
              class="customComboBox"
            ></v-combobox>
          </v-col>
          <v-col cols="8">
            <v-text-field
              solo
              dense
              flat
              placeholder="Search by Keyword"
              append-icon="search"
              hide-details
              class="customTf"
              v-model="query"
            ></v-text-field>
          </v-col>

          <v-col cols="12">
            <div class="lblTitle ml-3">Indikasi</div>
          </v-col>
          <v-col cols="12">
            <v-textarea
              maxlength="2000"
              solo
              dense
              flat
              placeholder="Tambahkan Indikasi Untuk Pasien"
              hide-details
              v-model="indikasi"
              class="customTf chatingbro"
              auto-grow
              rows="1"
            />
          </v-col>
        </v-row>
      </v-container>
    </v-col>

    <v-col class="ml-7" cols="12" v-if="chipData.length >= 1">
      <v-row>
        <v-list
          class="ml-4"
          cols="6"
          v-for="(item, key) in chipData"
          :key="key"
        >
          <v-chip
            close
            color="#68FFB7"
            text-color="#073059"
            class="chip-font"
            @click:close="[(item.selectionStatus = false), isChecked(item)]"
            label
            >{{ item.diagnosticItemName }}</v-chip
          >
        </v-list>
      </v-row>
    </v-col>

    <v-col class="ml-7" cols="12" v-if="chipData.length < 1">
      <v-row>
        <p class="subheading font-weight-medium pt-4">
          Pemeriksaan yang diminta harap diberi tanda
        </p>
        <img
          class="mt-3 ml-2"
          src="@/assets/checkicon.png"
          width="25"
          height="25"
          alt
        />
      </v-row>
    </v-col>

    <v-col cols="12" class="py-0">
      <v-row>
        <v-col>
          <v-list>
            <v-col
              v-for="(obj, key) in filteredDiagnostik"
              :key="key"
              class="mb-5 px-5"
            >
              <v-row>
                <v-container fluid fill-height class="contentTable">
                  {{ obj.diagnosticGroupName }}
                  <v-row align="center" justify="center" class="mt-3">
                    <v-col>
                      <v-list class="scrollableList">
                        <v-row no-gutters>
                          <v-col
                            cols="6"
                            v-for="(item, i) in obj.items"
                            :key="i"
                          >
                            <v-checkbox
                              class="mt-2 mx-4 pt-0"
                              v-model="item.selectionStatus"
                              @change="isChecked(item)"
                            >
                              <template v-slot:label>
                                <span class="checkboxLabel">{{
                                  item.diagnosticItemName
                                }}</span>
                              </template></v-checkbox
                            >
                          </v-col>
                        </v-row>
                      </v-list>
                    </v-col>
                  </v-row>
                </v-container>
              </v-row>
            </v-col>
          </v-list>
        </v-col>
      </v-row>
    </v-col>
    <v-row>
      <v-alert
        class="alertnih ml-5"
        text
        dense
        type="success"
        v-show="isSuccess"
        >{{ message }}</v-alert
      >
      <v-alert class="alertnih ml-5" text dense type="error" v-show="isError">{{
        message
      }}</v-alert>
    </v-row>
    <v-col>
      <v-btn
        small
        rounded
        dark
        width="120"
        color="#00CC6A"
        class="float-right mr-5"
        style="font-size: 13px"
        @click="submitDiagnostik"
        >Submit</v-btn
      >
    </v-col>
  </v-row>
</template>

<script>
import GeneralServices from "@/services/general_services";
import ConsultationServices from "@/services/consultation_services";

export default {
  data: () => ({
    isSuccess: false,
    isError: false,
    message: "",
    query: "",
    filterBy: "All",
    category: ["All"],
    localDiagnostik: [],
    indikasi: "",
    chipData: [],
    status: [],
    diagnostik: [],
    diagnostikid: "",
  }),
  methods: {
    capitalizeFirstLetter: (str) => {
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
    getDiagnostik() {
      let statusMenu =
        this.getResponseTakeQueue.data.payload.consultation.diagnosticStatus;
      console.log("Status Menu " + JSON.stringify(statusMenu));
      if (statusMenu) {
        ConsultationServices.getDiagnostic(
          window.localStorage.getItem("cntConsultationId")
        ).then((response) => {
          console.log(response);
          this.indikasi = response.data.payload.diagnosticNote;
          this.diagnostik = response.data.payload.groups;
          console.log("ini diagnostik", JSON.stringify(this.diagnostik));
          this.diagnostik.map((groupsname) => {
            let itemname = groupsname.items;
            this.status = groupsname["items"];
            this.diagnostikid = groupsname.radDiagnosticGroupId;
            console.log("ini status", this.status);
            itemname.map((itemgrup) => {
              this.chipData.push(itemgrup);
            });
          });
        });
      }
      GeneralServices.getMasterRadiology().then((response) => {
        this.localDiagnostik = response.data.payload.groups;
        console.log("lab", JSON.stringify(this.localDiagnostik));
        this.category.push(
          ...this.localDiagnostik.map((e) => e.diagnosticGroupName)
        );
        //  this.chipData = [];
        // }
      });
    },
    isChecked(item) {
      if (item.selectionStatus) {
        this.chipData.push(item);
      } else {
        this.chipData = this.chipData.filter(
          (element) => element.itemOrder !== item.itemOrder
        );
      }
    },
    submitDiagnostik() {
      // const submittedDiagnostik = this.localDiagnostik.filter(obj => {
      //     obj.items = obj.items.filter(item => item.selectionStatus == true).length == 0 ? '' : obj.items.filter(item => item.selectionStatus == true)
      //     return obj.items
      // });

      let payload = {
        uriParam: window.localStorage.getItem("cntConsultationId"),
        body: {
          diagnosticFormId: "",
          diagnosticNote: this.indikasi,
          groups: this.localDiagnostik,
        },
      };
      console.log("ini payload", payload);
      if (this.indikasi == "") {
        this.message = "Indikasi harus diisi";
        this.isError = true;
        setTimeout(() => {
          this.isError = false;
        }, 5000);
      } else {
        ConsultationServices.postDiagnostic(payload)
          .then((response) => {
            console.log("log", response);
            this.message = "Diagnostik Created!";
            (this.isSuccess = true),
              setTimeout(() => {
                this.isSuccess = false;
              }, 5000);
          })
          .catch(() => {
            this.message = "Cannot post multiple checklist";
            (this.isError = true),
              setTimeout(() => {
                this.isError = false;
              }, 5000);
          });
      }
    },
  },
  computed: {
    getResponseTakeQueue() {
      if (this.getStatusDenganJanji !== true) {
        return this.$store.getters["consultation/getResponseTakeQueue"];
      } else {
        return this.$store.getters["consultation/getResponseTakeQueueWithApp"];
      }
    },
        getStatusDenganJanji() {
      return this.$store.getters["consultation/getDenganJanji"];
    },
    filteredDiagnostik() {
      if (this.filterBy !== "") {
        if (this.filterBy !== "All" && this.query === "") {
          return this.localDiagnostik.filter((obj) =>
            obj.diagnosticGroupName
              .toLowerCase()
              .includes(this.filterBy.toLowerCase())
          );
        }
      }
      return this.localDiagnostik.filter((obj) => {
        return (
          (obj.items.find((item) => {
            return item.diagnosticItemName
              .toLowerCase()
              .includes(this.query.toLowerCase());
          }) &&
            obj.items.filter((item) => {
              let iman = item.radDiagnosticItemId;
              this.diagnostik.map((element) => {
                element.items.filter(function (item2) {
                  if (item2.radDiagnosticItemId == iman)
                    return (item.selectionStatus = item2.selectionStatus);
                });
              });
            })) ||
          obj.diagnosticGroupName
            .toLowerCase()
            .includes(this.query.toLowerCase())
        );
      });
    },
    //   filteredDiagnostik() {
    //     if (this.filterBy !== "") {
    //       if (this.filterBy !== "All" && this.query === "") {
    //         return this.localDiagnostik.filter((obj) =>
    //           obj.diagnosticGroupName
    //             .toLowerCase()
    //             .includes(this.filterBy.toLowerCase())
    //         );
    //       }
    //     }
    //     return this.localDiagnostik.filter((obj) => {
    //     return (
    //         obj.diagnosticGroupName
    //           .toLowerCase()
    //           .includes(this.query.toLowerCase()) ||

    //   obj.items.filter((item) => {
    //   let iman = item.radDiagnosticItemId;
    //   this.diagnostik.map(element => {
    //      element.items.filter(function (item2) {
    //      if (item2.radDiagnosticItemId == iman)
    //       return  item.selectionStatus = item2.selectionStatus ;
    //    })
    //   });
    //   return item.diagnosticItemName
    //     .toUpperCase()
    //     .includes(this.query.toUpperCase());
    // })
    //       );

    //     });
    //   },
  },
  mounted() {
    this.getStatusDenganJanji;
    this.getDiagnostik();
  },
  watch: {
    indikasi: function (newValue) {
      this.indikasi = this.capitalizeFirstLetter(newValue);
    },
  },
};
</script>

<style scoped>
.scrollableList {
  /* height: 19vh; */
  overflow-y: auto;
  border-radius: 10px;
}
.contentTable {
  background: #e9f5fc;
  border-radius: 15px;
}
.customComboBox {
  border: 1px solid rgba(43, 146, 228, 0.55);
  box-sizing: border-box;
  border-radius: 10px;
  font-size: 12px;
}
.customTf {
  border: 1px solid rgba(37, 37, 37, 0.55);
  box-sizing: border-box;
  border-radius: 10px;
}
.lblTitle {
  font-weight: bold;
  font-size: 18px;
  line-height: 25px;
  text-align: left;
  color: #2a8bf2;
  font-family: Nunito;
}
.chatingbro {
  max-height: 120px;
  overflow-y: auto;
  font-size: 18px;
  font-family: Nunito;
}
@media only screen and (max-width: 1366px) {
  .customComboBox {
    border: 1px solid rgba(43, 146, 228, 0.55);
    box-sizing: border-box;
    border-radius: 10px;
    font-size: 14px;
  }
  .customTf {
    border: 1px solid rgba(43, 146, 228, 0.55);
    box-sizing: border-box;
    border-radius: 10px;
    font-size: 14px;
  }
  .lblTitle {
    font-weight: bold;
    font-size: 14px;
    line-height: 25px;
    text-align: left;
    color: #2a8bf2;
    font-family: Nunito;
  }
  .checkboxLabel {
    font-size: 12px;
    line-height: 25px;
    text-align: left;
    font-family: Nunito;
  }
  .chip-font {
    font-size: 13px;
    font-family: Nunito;
  }
  .alertnih {
    font-size: 13px;
    font-family: Nunito;
    margin-top: -12;
  }
}
</style>