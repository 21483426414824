import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import vuetify from "./plugins/vuetify";
import store from "./store";
import "./helper/filters";
import Alert from "./helper/Alert";
import VueApexCharts from "vue-apexcharts";
import QiscusCore from "./lib/SDKCore";
import Interceptors from "./services/axios_interceptor";
import VueChatScroll from "vue-chat-scroll";
import VueToast from "vue-toast-notification";
import BootstrapVue from 'bootstrap-vue';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import "vue-toast-notification/dist/theme-sugar.css";
import "vuetify/dist/vuetify.min.css";

// import useSound from "vue-use-sound";

// Initialize Firebase
import firebase from "firebase/app";
import "firebase/messaging";

firebase.initializeApp({
  apiKey: "AIzaSyByOg2p6Dwfk6TzKmofEcPAVIJCKz4o6Xc",
  authDomain: "telemedistra-303902.firebaseapp.com",
  projectId: "telemedistra-303902",
  storageBucket: "telemedistra-303902.appspot.com",
  messagingSenderId: "285459137820",
  appId: "1:285459137820:web:6579525ccf59be1f6d66d9",
  measurementId: "G-MCTY3JGMTZ",
});

navigator.serviceWorker
  .register("firebase-messaging-sw.js")
  .then((registration) => {
    const messaging = firebase.messaging();
    messaging.useServiceWorker(registration);
  })
  .catch((err) => {
    console.log(err);
  });
// fiebase configs end

Vue.use(VueChatScroll);
Vue.use(VueApexCharts);
Vue.use(VueToast);
Vue.use(BootstrapVue);

// Vue.use(useSound);
Vue.prototype.$qiscus = QiscusCore;
Vue.component("apexchart", VueApexCharts);
Vue.component("alert", Alert);

Interceptors.intercept();

Vue.config.productionTip = false;

new Vue({
  router,
  vuetify,
  store,
  render: (h) => h(App),
}).$mount("#app");
