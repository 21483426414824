/**
 * @author [Mochamad Arifin]
 * @email [mochamad.arifin@dwidasa.com]
 * @create date 2021-01-17 23:27:35
 * @modify date 2021-01-17 23:27:35
 * @desc [description]
 */


<template>
    <v-row align="center" justify="center" class="ma-5 pa-5">
        <v-col class="">
            <v-row align="center" justify="center" class="ma-0 pa-0">
                <img src="@/assets/chatgreeting.png" width="300" height="300" alt="" class="">                       
            </v-row>
            <v-row align="center" justify="center" class="ma-5 pa-0">
                <p class="titleGreetingChat">Klik Salah Satu Pertanyaan</p>
            </v-row>

            <v-row align="center" justify="center" class="">
                <p class="subtitleGreetingChat">Untuk Menjawab</p>
            </v-row>
        </v-col>
    </v-row>
</template>

<script>
export default {

}
</script>

<style scoped>
.titleGreetingChat {
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 28px;
    line-height: 88.9%;
    /* or 32px */

    text-align: center;

    color: #2B92E4;
}
.subtitleGreetingChat {
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 26px;
    line-height: 88.9%;
    /* or 32px */

    text-align: center;

    color: #073059;
}
</style>