/**
 * @author [Mochamad Arifin]
 * @email [mochamad.arifin@dwidasa.com]
 * @create date 2021-01-24 18:13:04
 * @modify date 2021-01-24 18:13:04
 * @desc [description]
 */

import ConsultationServices from "@/services/consultation_services";

export const consultation = {
  namespaced: true,
  state: {
    btnstatus: true,
    listPatient: [],
    listPatient2: [],
    greting: 1,
    responseTakeQueue: [],
    responseTakeWithApp: [],
    statusTabSummary: true,
    statusTabAssesment: true,
    statusTabPlanning: true,
    dialogStatus: false,
    leftMenu: false,
    rightMenu: false,
    year: null,
    month: null,

    // alert
    showAlert: "",
    // Plan
    responseGetPrescription: [],
    responsePostSugestion: [],
    responsePostPrescription: [],

    // Subjective
    responseCntHistory: [],

    // Chat Start and Finish
    responseStartChat: [],
    responseFinishChat: [],
    responseQiscus: {},

    // Summary
    patientSummary: [],
    doctorSummary: [],
    summaryQuestion: [],

    // Assesment
    responseGetDiagnosis: [],
    responsePostDiagnosis: [],
    postSummary: [],
    detailPatient: [],
    // objective
    objective: [],
    showTime: false,
    statusObat: false,
    acceptStatus: false,
    photoProfile: "",
    timechat: null,
    denganJanji: false,
    warning: false,
    warningnama: "",
    warningjam: "",
    boot: false,
    // untuk state tab summary
    // isSummaryActivated: false,
  },

  mutations: {
    mtnWarning: (state, value) => {
      state.warning = value;
    },
    mtnBoot: (state, value) => {
      state.boot = value;
    },
    mtnWarningNama: (state, value) => {
      state.warningnama = value;
    },
    mtnWarningJam: (state, value) => {
      state.warningjam = value;
    },
    mtnDenganJanji: (state, value) => {
      state.denganJanji = value;
    },
    mtnDetailPatient: (state, value) => {
      state.detailPatient = value;
    },
    mtnYear: (state, value) => {
      state.year = value;
    },
    mtnMonth: (state, value) => {
      state.month = value;
    },
    mtnBtnStatus: (state, value) => {
      state.btnstatus = value;
    },
    mtnPhoto: (state, value) => {
      state.photoProfile = value;
    },
    mtnGreting: (state, value) => {
      state.greting = value;
    },
    mtnAcceptStatus: (state, value) => {
      state.acceptStatus = value;
    },
    mtnStatusObat: (state, value) => {
      state.statusObat = value;
    },
    mtnShowTime: (state, value) => {
      state.showTime = value;
    },
    getDataListPatient: (state, value) => {
      state.listPatient = value;
    },
    getDataListPatient2: (state, value) => {
      state.listPatient2 = value;
    },

    setResponseTakeQueue: (state, value) => {
      state.responseTakeQueue = value;
    },
    setResponseTakeWithApp: (state, value) => {
      state.responseTakeWithApp = value;
    },

    //  left and right Menu
    mtnActLeftMenu: (state, value) => {
      state.leftMenu = value;
    },
    mtnActRightMenu: (state, value) => {
      state.rightMenu = value;
    },

    //  Set Status Menu Tab
    mtnSetSummaryTab: (state, value) => {
      state.statusTabSummary = value;
    },
    mtnSetAssesmentTab: (state, value) => {
      state.statusTabAssesment = value;
    },
    mtnSetPlanningTab: (state, value) => {
      state.statusTabPlanning = value;
    },
    //  End Set Status Menu Tab

    //  Set Status Dialog
    mtnDialogStatus: (state, value) => {
      state.dialogStatus = value;
    },

    mtnShowAlert: (state, value) => {
      state.showAlert = value;
    },

    //  Chat Qiscus
    mtnGetChatQiscus: (state, value) => {
      state.responseQiscus = value;
    },

    //  Plan
    mtnGetPrescription: (state, value) => {
      state.responseGetPrescription = value;
    },

    mtnPostSugestion: (state, value) => {
      state.responsePostSugestion = value;
    },
    mtnPostPrescription: (state, value) => {
      state.responsePostPrescription = value;
    },
    //  End Plan

    //  Consultation History
    mtnCntHistory: (state, value) => {
      state.responseCntHistory = value;
    },

    // Chat Start and Finish
    mtnStartChat: (state, value) => {
      state.responseStartChat = value;
    },
    mtnFinishChat: (state, value) => {
      state.responseFinishChat = value;
    },
    // End Chat Start and Finish

    // Summary
    mtnPatientSummary: (state, value) => {
      state.patientSummary = value;
    },
    mtnDoctorSummary: (state, value) => {
      state.doctorSummary = value;
    },
    // summarygetSummaryQuestion
    mtnSummaryQuestion: (state, value) => {
      state.summaryQuestion = value;
    },

    // postSUmmary
    mtnPostSummary: (state, value) => {
      state.postSummary = value;
    },
    // End Summary

    // Assesment
    mtnGetDiagnosis: (state, value) => {
      state.responseGetDiagnosis = value;
    },
    mtnPostDiagnosis: (state, value) => {
      state.responsePostDiagnosis = value;
    },
    // End Assesmet

    // Objective
    mtnGetObjective: (state, value) => {
      state.objective = value;
    },

    // untuk state tab summary
    // mtnSummaryActivated: (state, value) => {
    //   state.isSummaryActivated = value;
    // },
    mtnStatusTime: (state, value) => {
      state.timechat = value;
    },
  },

  actions: {
    actDenganJanji({ commit }, value) {
      commit("mtnDenganJanji", value);
      console.log(value);
    },
    actBoot({ commit }, value) {
      commit("mtnBoot", value);
      console.log(value);
    },
    actWarning({ commit }, value) {
      commit("mtnWarning", value);
      console.log(value);
    },
    actWarningNama({ commit }, value) {
      commit("mtnWarningNama", value);
      console.log(value);
    },
    actWarningJam({ commit }, value) {
      commit("mtnWarningJam", value);
      console.log(value);
    },
    actDetailPatient({ commit }, value) {
      commit("mtnDetailPatient", value);
      console.log(value);
    },
    actYear({ commit }, value) {
      commit("mtnYear", value);
    },
    actMonth({ commit }, value) {
      commit("mtnMonth", value);
    },
    actBtnStatus({ commit }, value) {
      commit("mtnBtnStatus", value);
      console.log("berhasil");
    },
    actStatusTime({ commit }, value) {
      commit("mtnStatusTime", value);
    },
    actGreting({ commit }, value) {
      commit("mtnGreting", value);
    },
    actPhoto({ commit }, value) {
      commit("mtnPhoto", value);
    },
    actAcceptStatus({ commit }, value) {
      commit("mtnAcceptStatus", value);
    },
    actStatusObat({ commit }, value) {
      commit("mtnStatusObat", value);
    },
    //set time
    actShowTime({ commit }, value) {
      commit("mtnShowTime", value);
    },
    setPatientList({ commit }, value) {
      commit("getDataListPatient", value);
      // console.log("japra", value);
      // return Promise.resolve(value)
    },
    setPatientList2({ commit }, value) {
      commit("getDataListPatient2", value);
      // console.log(value);
      // return Promise.resolve(value)
    },

    postTakeQueue({ commit }, value) {
      return ConsultationServices.takeQueue(value).then(
        (response) => {
          commit("setResponseTakeQueue", response);
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error);
        },
      );
    },
    postTakeQueueWithApp({ commit }, value) {
      return ConsultationServices.takeQueueWithApp(value).then(
        (response) => {
          commit("setResponseTakeWithApp", response);
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error);
        },
      );
    },

    //  left and right Menu
    actLeftMenu({ commit }, value) {
      commit("mtnActLeftMenu", value);
    },
    actRightMenu({ commit }, value) {
      commit("mtnActRightMenu", value);
    },

    //  Set Status Menu Tab
    actSetTabSummary({ commit }, value) {
      commit("mtnSetSummaryTab", value);
    },
    actSetTabAssesment({ commit }, value) {
      commit("mtnSetAssesmentTab", value);
    },
    actSetTabPlanning({ commit }, value) {
      commit("mtnSetPlanningTab", value);
    },
    //  End Set Status Menu Tab

    // Set Dialog Status
    actDialogStatus({ commit }, value) {
      commit("mtnDialogStatus", value);
    },

    actShowAlert({ commit }, value) {
      commit("mtnShowAlert", value);
    },

    //  Plan
    actPostSugestion({ commit }, value) {
      return ConsultationServices.postSugestion(value).then(
        (response) => {
          commit("mtnPostSugestion", response);
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error);
        },
      );
    },

    actPostPrescription({ commit }, value) {
      return ConsultationServices.postPrescription(value).then((response) => {
        commit("mtnPostPrescription", response);
        return Promise.resolve(response);
      });
    },

    actGetPrescription({ commit }, value) {
      return ConsultationServices.getPrescription(value).then((response) => {
        commit("mtnGetPrescription", response);
        return Promise.resolve(response);
      });
    },

    //  End Plan

    //  Consultation History
    actCntHistory({ commit }, value) {
      return ConsultationServices.consultationHistory(value).then(
        (response) => {
          commit("mtnCntHistory", response);
          return Promise.resolve(response);
        },
      );
    },

    // Chat Start and Finish
    actStartChat({ commit }, value) {
      return ConsultationServices.startChat(value).then((response) => {
        commit("mtnStartChat", response);
        return Promise.resolve(response);
      });
    },
    actFinishChat({ commit }, value) {
      return ConsultationServices.finishChat(value).then((response) => {
        commit("mtnFinishChat", response);
        return Promise.resolve(response);
      });
    },

    actQisqusRoomId({ commit }, value) {
      commit("mtnGetChatQiscus", value);
      console.log("GET ROOOM ID ON MODULE ", value);
    },
    // End Chat Start and Finish

    // Summary
    actPatientSummary({ commit }, value) {
      return ConsultationServices.patientSummary(value).then((response) => {
        commit("mtnPatientSummary", response);
        return Promise.resolve(response);
      });
    },

    actDoctorSummary({ commit }, value) {
      return ConsultationServices.doctorSummary(value).then((response) => {
        commit("mtnDoctorSummary", response);
        return Promise.resolve(response);
      });
    },

    actSummaryQuestion({ commit }) {
      return ConsultationServices.summaryQuestion().then((response) => {
        commit("mtnSummaryQuestion", response);
        return Promise.resolve(response);
      });
    },

    actPostSummary({ commit }, value) {
      return ConsultationServices.postSummary(value).then((response) => {
        commit("mtnPostSummary", response);
        return Promise.resolve(response);
      });
    },
    // End Summary

    // Assesment
    actPostDiagnosis({ commit }, value) {
      return ConsultationServices.postDiagnosis(value).then(
        (response) => {
          commit("mtnPostDiagnosis", value);
          return Promise.resolve(response);
        },
        (error) => {
          return Promise.reject(error);
        },
      );
    },

    actgetDiagnosis({ commit }, value) {
      return ConsultationServices.getDiagnosis(value).then((response) => {
        commit("mtnGetDiagnosis", response);
        return Promise.resolve(response);
      });
    },
    // End Assesment

    // get Objective
    actGetObjective({ commit }, value) {
      return ConsultationServices.getObjective(value).then((response) => {
        commit("mtnGetObjective", response);
        return Promise.resolve(response);
      });
    },
    // End Objective

    // untuk state tab summary
  },

  getters: {
    getBoot: (state) => state.boot,
    getDetailPatient: (state) => state.detailPatient,
    getWarning: (state) => state.warning,
    getWarningNama: (state) => state.warningnama,
    getWarningJam: (state) => state.warningjam,
    getDenganJanji: (state) => state.denganJanji,
    getYear: (state) => state.year,
    getMonth: (state) => state.month,
    getBtnStatus: (state) => state.btnstatus,
    getTimeChat: (state) => state.timechat,
    getPhoto: (state) => state.photoProfile,
    getAcceptStatus: (state) => state.acceptStatus,
    getStatusObat: (state) => state.statusObat,
    // timechat
    getShowTime: (state) => state.showTime,
    getListPatient: (state) => state.listPatient,
    getListPatient2: (state) => state.listPatient2,
    getResponseTakeQueue: (state) => state.responseTakeQueue,
    getResponseTakeQueueWithApp: (state) => state.responseTakeWithApp,
    getStatusTabSummary: (state) => state.statusTabSummary,
    getStatusTabAssesment: (state) => state.statusTabAssesment,
    getStatusTabPlanning: (state) => state.statusTabPlanning,
    getDialogStatus: (state) => state.dialogStatus,

    // right and lefr Menu
    getLeftMenuStatus: (state) => state.leftMenu,
    getRightMenuStatus: (state) => state.rightMenu,

    // alert
    getShowAlert: (state) => state.showAlert,

    //  Plan
    getResponsePrescription: (state) => state.responseGetPrescription,

    //  Consultation History
    getResponseCntHistory: (state) => state.responseCntHistory,

    // Chat Start and Finish
    getResponseStartChat: (state) => state.responseStartChat,
    getResponseFinishChat: (state) => state.responseFinishChat,
    getResponseQiscus: (state) => state.responseQiscus,

    // Summary
    getPatientSummary: (state) => state.patientSummary,
    getDoctorSummary: (state) => state.doctorSummary,
    getSummaryQuestion: (state) => state.summaryQuestion,

    // Assesment
    getResponseGetDiagnosis: (state) => state.responseGetDiagnosis,
    getPostSummary: (state) => state.postSummary,
    getObjective: (state) => state.objective,
    getGreting: (state) => state.greting,
    // untuk state tab summary
    isSummaryActivated: (state) => state.isSummaryActivated,
  },
};
