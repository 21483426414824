var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('v-list',{directives:[{name:"chat-scroll",rawName:"v-chat-scroll"}],staticClass:"messageChat"},_vm._l((_vm.getCommentChat.comments),function(listComments,key){return _c('v-list-item',{key:key},[_c('v-row',[_c('v-col',[(listComments.username_as != _vm.isMe)?_c('v-container',{staticClass:"buble",attrs:{"fluid":""}},[_c('v-row',{staticClass:"ma-0 pa-0"},[(listComments.type == 'text')?_c('div',{staticClass:"chattext"},[_c('p',[_vm._v(_vm._s(listComments.message))]),(listComments.isSent == true)?_c('div',[_c('vue-audio',{attrs:{"file":_vm.file1,"autoPlay":true,"hidden":""}})],1):_vm._e(),(listComments.isSent == false)?_c('div',[_c('vue-audio',{attrs:{"file":_vm.file1,"autoPlay":false,"hidden":""}})],1):_vm._e()]):_vm._e(),(listComments.type == 'file_attachment')?_c('div',{staticClass:"mr-3"},[_c('div',{on:{"click":function($event){return _vm.handleAttachment(listComments)}}},[_c('embed',{attrs:{"src":listComments.payload.url,"width":"100%","height":"100%","alt":""}}),_c('a',{staticClass:"txtImg"},[_vm._v(" Click Here To Open File ")])]),(listComments.isSent == true)?_c('div',[_c('vue-audio',{attrs:{"file":_vm.file1,"autoPlay":true,"hidden":""}})],1):_vm._e(),(listComments.isSent == false)?_c('div',[_c('vue-audio',{attrs:{"file":_vm.file1,"autoPlay":false,"hidden":""}})],1):_vm._e()]):_vm._e()])],1):_vm._e()],1),(
            listComments.message ==
            'Selamat datang Bapak/Ibu di layanan telemedicine kami. Apakah saat ini saya terhubung dengan Bapak/Ibu ' +
              _vm.pasien +
              ' ?' +
              'Perkenankan saya ' +
              _vm.doctor +
              ', untuk memulai konsultasi ini.'
          )?_c('v-col',[(listComments.username_as == _vm.isMe)?_c('v-container',{staticClass:"bublebot",attrs:{"fluid":""}},[_c('v-row',{staticClass:"ma-0 pa-0"},[(listComments.type == 'text')?_c('div',{staticClass:"chattext"},[_c('p',[_vm._v(_vm._s(listComments.message))])]):_vm._e(),(listComments.type == 'file_attachment')?_c('div',{staticClass:"mr-3",on:{"click":function($event){return _vm.handleAttachment(listComments)}}},[_c('div',[_c('embed',{attrs:{"src":listComments.payload.url,"width":"100%","height":"100%","alt":""}}),_c('a',{staticClass:"txtImg2"},[_vm._v(" Click Here To Open File ")])])]):_vm._e()])],1):_vm._e()],1):(
            listComments.message == 'Waktu konsultasi Anda tinggal 5 menit lagi.'
          )?_c('v-col',[(listComments.username_as == _vm.isMe)?_c('v-container',{staticClass:"bublebot",attrs:{"fluid":""}},[_c('v-row',{staticClass:"ma-0 pa-0"},[(listComments.type == 'text')?_c('div',{staticClass:"chattext"},[_c('p',[_vm._v(_vm._s(listComments.message))])]):_vm._e(),(listComments.type == 'file_attachment')?_c('div',{staticClass:"mr-3",on:{"click":function($event){return _vm.handleAttachment(listComments)}}},[_c('div',[_c('embed',{attrs:{"src":listComments.payload.url,"width":"100%","height":"100%","alt":""}}),_c('a',{staticClass:"txtImg2"},[_vm._v(" Click Here To Open File ")])])]):_vm._e()])],1):_vm._e()],1):_c('v-col',[(listComments.username_as == _vm.isMe)?_c('v-container',{staticClass:"bubleme",attrs:{"fluid":""}},[_c('v-row',{staticClass:"ma-0 pa-0"},[(listComments.type == 'text')?_c('div',{staticClass:"chattext"},[_c('p',[_vm._v(_vm._s(listComments.message))])]):_vm._e(),(listComments.type == 'file_attachment')?_c('div',{staticClass:"mr-3",on:{"click":function($event){return _vm.handleAttachment(listComments)}}},[_c('div',[_c('embed',{attrs:{"src":listComments.payload.url,"width":"100%","height":"100%","alt":""}}),_c('a',{staticClass:"txtImg2"},[_vm._v(" Click Here To Open File ")])])]):_vm._e()])],1):_vm._e()],1)],1)],1)}),1),_c('br'),(_vm.getStartTime == true)?_c('v-row',{staticClass:"time",attrs:{"justify":"center"}},[_c('v-chip',{staticClass:"timerChattext",attrs:{"label":"","small":"","color":_vm.colors}},[_c('p',{staticClass:"timerChat mt-4"},[_vm._v("Time Remaining "+_vm._s(_vm.time2))])])],1):_vm._e(),(_vm.getStartTime == false)?_c('v-row',{staticClass:"time",attrs:{"justify":"center"}},[_c('v-chip',{attrs:{"label":"","small":"","color":"#FA3D6D"}},[_c('p',{staticClass:"timerChat mt-4 wchat"},[_vm._v("Waktu Chating Telah Berakhir")])])],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }