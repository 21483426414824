/**
 * @author [Mochamad Arifin]
 * @email [mochamad.arifin@dwidasa.com]
 * @create date 2021-01-13 11:42:59
 * @modify date 2021-01-13 11:42:59
 * @desc [description]
 */


<template>
  <v-row align="center" justify="center">
    <v-col cols="12 mt-15">
      <v-card :class="'rounded-xl'">
        <v-row align="center" justify="center" class="ml-9 mr-9">
          <v-col cols="12" class="ml-9 mr-8">
            <div class="lblTitle ml-5">Diagnosis Utama</div>
          </v-col>
          <v-col class="ml-1" lg="3" md="2">
            <div class="lblIcdCode">ICD CODE</div>
          </v-col>

          <v-col class="ma-0 pa-0" lg="8" md="9">
            <v-autocomplete
              dense
              v-model="diagnosisSearch"
              outlined
              class="fontauto"
              :items="dataSearch"
              item-text="icdName"
              item-value="icdCode"
              return-object
              placeholder="ICD CODE"
              :search-input.sync="diagnosisWatchSearch"
              hide-details
              append-icon
              @change="getValueDiagnosis"
            ></v-autocomplete>
          </v-col>

          <v-col cols="12">
            <v-row class="ml-7">
              <v-list v-for="(diagnosischip, key) in chipDiagnosis" :key="key">
                <v-chip
                  class="ma-2 chips"
                  close
                  color="#88FFC6"
                  label
                  @click:close="removeChip(key)"
                  >{{ diagnosischip.icdCode }}
                  {{ diagnosischip.icdName }}</v-chip
                >
              </v-list>
            </v-row>
          </v-col>

          <v-col cols="12">
            <v-textarea
              maxlength="2000"
              v-model="params.bodyParams.mainNote"
              class="ml-7 mr-5 mt-1 fonttext"
              placeholder="Add Notes"
              prepend-inner-icon="add_circle"
              outlined
              hide-details
              dense
              auto-grow
              rows="1"
              rounded
            />
          </v-col>
        </v-row>

        <v-row align="center" justify="center" class="ml-9 mr-9">
          <v-col cols="12" class="ml-9 mr-8">
            <div class="lblTitle ml-5 pa-0">Lainnya</div>
          </v-col>

          <v-col class="ml-1" cols="3">
            <div class="lblIcdCode">ICD CODE</div>
          </v-col>

          <v-col class="ma-0 pa-0" cols="8">
            <v-autocomplete
              v-model="otherSearch"
              outlined
              dense
              class="fontauto"
              :items="otherDataSearch"
              item-text="icdName"
              item-value="icdCode"
              return-object
              placeholder="ICD CODE"
              :search-input.sync="otherWatchSearch"
              hide-details
              append-icon
              @change="getValueOther"
            ></v-autocomplete>
          </v-col>

          <v-col cols="12">
            <v-row class="ml-7">
              <v-list v-for="(chipOther, key) in chipOther" :key="key">
                <v-chip
                  class="ma-2 chips"
                  close
                  color="#88FFC6"
                  label
                  @click:close="removeChipOther(key)"
                  >{{ chipOther.icdCode }} {{ chipOther.icdName }}</v-chip
                >
              </v-list>
            </v-row>
          </v-col>
          <v-col cols="12">
            <v-textarea
              maxlength="2000"
              v-model="params.bodyParams.otherNote"
              class="ml-7 mr-5 mt-1 fonttext"
              placeholder="Add Notes"
              prepend-inner-icon="add_circle"
              outlined
              hide-details
              auto-grow
              rounded
              rows="1"
              dense
            />
          </v-col>
          <alert
            :type="typeAlert"
            :message="textAlert"
            v-show="alertShow"
          ></alert>
        </v-row>

        <v-row>
          <v-col class="mr-9">
            <v-btn
              class="float-right"
              large
              dark
              rounded
              color="#00CC6A"
              width="120"
              @click="submitAssesment"
              >submit</v-btn
            >
          </v-col>
        </v-row>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
import ConsultationService from "@/services/consultation_services";

export default {
  data: () => ({
    diagnosisSearch: "",
    showList: false,
    pageSearch: false,
    otherSearch: "",
    dataSearch: [],
    chipDiagnosis: [],
    chipOther: [],
    otherDataSearch: [],
    diagnosisWatchSearch: "",
    otherWatchSearch: "",
    typeAlert: "success",
    alertShow: false,
    textAlert: "",
    filteredDiagnosisSearch: [],
    filteredOtherSearch: [],
    params: {
      uriParam: window.localStorage.getItem("cntConsultationId"),
      bodyParams: {
        mainNote: "",
        otherNote: "",
        mainCntMstIcdIds: [],
        otherCntMstIcdIds: [],
      },
    },
  }),
  methods: {
    capitalizeFirstLetter: (str) => {
      return str.charAt(0).toUpperCase() + str.slice(1);
    },
    getHistoryInput() {
      let statusAssesmentTab = this.getTabAssementStatus;
      console.log("status " + statusAssesmentTab);
      if (!statusAssesmentTab) {
        this.$store
          .dispatch(
            "consultation/actgetDiagnosis",
            window.localStorage.getItem("cntConsultationId")
          )
          .then((response) => {
            console.log("hahaha " + JSON.stringify(response));
            var icdna = response.data.payload.detailsMap.main;
            var icdother = response.data.payload.detailsMap.other;
            icdna.map((namaicd) => {
              this.chipDiagnosis.push({
                icdCode: namaicd.icdCode,
                icdName: namaicd.icdName,
                icdId : namaicd.cntMstIcdId,
              });
              this.params.bodyParams.mainCntMstIcdIds.push(namaicd.cntMstIcdId);
            });
            console.log("hhhjjj" + this.params.bodyParams.mainCntMstIcdIds);

            icdother.map((namaicdother) => {
              this.chipOther.push({
                icdCode: namaicdother.icdCode,
                icdName: namaicdother.icdName,
                icdId : namaicdother.cntMstIcdId
              });
              this.params.bodyParams.otherCntMstIcdIds.push(
                namaicdother.cntMstIcdId
              );
            });
            console.log("gogoggo " + JSON.stringify(this.chipDiagnosis));
            this.diagnosisSearch = this.params.bodyParams.mainCntMstIcdIds;
            this.otherSearch = this.params.bodyParams.otherCntMstIcdIds;
            this.params.bodyParams.mainNote =
              response.data.payload.diagnosisMainNote;
            this.params.bodyParams.otherNote =
              response.data.payload.diagnosisOtherNote;

            console.log("gogoggo " + JSON.stringify(this.diagnosisSearch));
            console.log("lllllll " + JSON.stringify(this.otherSearch));
            console.log(
              "params " + JSON.stringify(this.params.bodyParams.otherNote)
            );
            console.log(
              "oparams " + JSON.stringify(this.params.bodyParams.otherNote)
            );
          });
      }
    },
    getValueDiagnosis() {
      this.chipDiagnosis.push(this.diagnosisSearch);
      this.params.bodyParams.mainCntMstIcdIds.push(
        this.diagnosisSearch.cntMstIcdId
      );
    },
    getValueOther() {
      this.chipOther.push(this.otherSearch);
      this.params.bodyParams.otherCntMstIcdIds.push(this.otherSearch.cntMstIcdId);
    },
    removeChip(key) {
      this.chipDiagnosis.splice(key, 1);
      this.params.bodyParams.mainCntMstIcdIds.splice(key, 1);
    },
    removeChipOther(key) {
      this.chipOther.splice(key, 1);
      this.params.bodyParams.otherCntMstIcdIds.splice(key, 1);
    },
    submitAssesment() {
      if (this.diagnosisSearch.length == 0 || this.otherSearch.length == 0) {
        this.typeAlert = "error";
        this.textAlert = "ICD Code Wajib Diisi";
        this.alertShow = true;
        setTimeout(() => {
          this.alertShow = false;
        }, 5000);
      } else {
        this.$store.dispatch("consultation/actSetTabPlanning", false);
        this.$store
          .dispatch("consultation/actPostDiagnosis", this.params)
          .then((response) => {
            if (response.data.error != "") {
              // Error
              this.typeAlert = "error";
              this.textAlert = response.data.error;
            } else {
              // Success
              this.typeAlert = "success";
              this.textAlert = "Success";
            }
            this.alertShow = true;
            setTimeout(() => {
              this.alertShow = false;
            }, 5000);
          })
          .catch((error) => {
            this.typeAlert = "error";
            this.textAlert = error;
            this.alertShow = true;
            setTimeout(() => {
              this.alertShow = false;
            }, 5000);
          });
      }
    },
  },
  mounted() {
    this.getHistoryInput();
  },
  computed: {
    getTabAssementStatus: {
      get() {
        return this.$store.getters["consultation/getStatusTabAssesment"];
      },
      set() {},
    },
  },
  watch: {
    // params.bodyParams.mainNote: function(newValue) {
    //       this.params.bodyParams.mainNote = this.capitalizeFirstLetter(newValue);
    //     },
    //     firstName: function(newValue) {
    //       this.firstName = this.capitalizeFirstLetter(newValue);
    //     },
    diagnosisWatchSearch(value) {
      if (value != null) {
        if (value.length >= 3) {
          ConsultationService.getSearchIcdCode(value).then((response) => {
            this.dataSearch = response.data.payload;
          });
        }
      }
    },

    otherWatchSearch(value) {
      if (value.length >= 3) {
        ConsultationService.getSearchIcdCode(value).then((response) => {
          this.otherDataSearch = response.data.payload;
        });
      }
    },
  },
};
</script>

<style scoped>
.v-select.v-select--is-menu-active .v-input__icon--append .v-icon {
  transform: rotate(0);
}

.v-text-field >>> input {
  color: rgba(68, 133, 253, 0.69);
}
.lblTitle {
  font-weight: bold;
  font-size: 20px;
}
.lblIcdCode {
  font-size: 18px;
  color: #073059;
}
.roundeTextField {
  background: #edf4fb;
  border: 2px solid #edf7ff;
  border-radius: 30px;
  max-height: 125px;
  overflow-y: scroll;
}
.chips {
  font-size: 16px;
}
.fontauto {
  font-size: 16px;
}
@media only screen and (max-width: 1366px) {
  .lblTitle {
    font-weight: bold;
    font-size: 16px;
  }
  .chips {
    font-size: 12px;
  }
  .lblIcdCode {
    font-size: 16px;
    color: #073059;
  }
  .fontauto {
    font-size: 12px;
  }
  .roundeTextField {
    font-size: 14px;
  }
  .fonttext {
    font-size: 14px;
  }
}
</style>