/**
 * @author [Mochamad Arifin]
 * @email [mochamad.arifin@dwidasa.com]
 * @create date 2021-01-17 23:29:10
 * @modify date 2021-01-17 23:29:10
 * @desc [description]
 */


<template>
  <v-app class="grey lighten-4 app1">
    <router-view />
  </v-app>
</template>

<script>
import AuthServices from "@/services/auth_services";
// import firebase from "firebase/app";
// import "firebase/messaging";
export default {
  name: "App",

  data: () => ({
    //
    id: null,
    isOnline: null,
    lastOnline: null,
    timer: null,
    test: false,
    login: false,
    StatusAccept: false,
    params: {
      userId: "",
      deviceType: "browser",
      deviceCode: "123",
      longToken: "",
    },
  }),
  beforeCreate() {
    this.$qiscus.init({
      // prod
      AppId: "kawan-seh-g857ffuuw9b",
      // dev
      // AppId: 'qchatsdk--wvxntkmayhr',
      //AppId: 'sdksample',
      // AppappIdVCall:'kawansehat-PnXE9JMZHFl7tVKX',
      // v-call
      // prod
      // dev

      mode: "widget",
      options: {
        loginSuccessCallback: function (data) {
          // successfully login
          console.log("LOGIN BERHASIL", data);
          //  alert("LOGIN ROOM CHAT BERHASIL ")
        },
        loginErrorCallback: function (data) {
          // failed login
          console.log("LOGIN GAGAL " + data);
          alert("LOGIN ROOM CHAT GAGAL ")
        },
        newMessagesCallback: function (messages) {
          // On receive a new messages
          console.log("Message Callback " + JSON.stringify(messages));
        },
        commentDeliveredCallback: function (data) {
          // On comment delivered
          console.log("On comment delivered", data);
        },
        commentReadCallback: function (data) {
          // On comment has been read by user
          console.log("On comment has been read by user ", data);
        },

        presenceCallback: function (data) {
          // On user change it "online" status
          console.log("STATUS ONLINE " + data);
          // console.log('STATUS ONLINE 2 ' + userId)
          var isOnline = data.split(":")[0] === "1";
          var lastOnline = new Date(Number(data.split(":")[1]));
          console.log("STATUS ONLINE 2" + data);
          console.log("isOnline ", isOnline);
          console.log(isOnline);
          // if(isOnline === false){
          //   var stats = 1
          //   window.localStorage.setItem('status', stats)
          //   console.log(data.isOnline);
          //   console.log("keuar");
          // }else if(data.isOnline){
          //   console.log(data.isOnline);
          //   var statson = 0
          //   window.localStorage.setItem('status', statson)
          //   console.log("masuk")
          // }
          if (data.isOnline) {
            console.log(data.isOnline);
            var statson = 0;
            window.localStorage.setItem("status", statson);
            console.log("masuk");
          } else {
            var stats = 1;
            window.localStorage.setItem("status", stats);
            console.log(data.isOnline);
            console.log("keuar");
          }
          console.log(lastOnline);
        },
        typingCallback: function (data) {
          // On user typing
          console.log("On user typing ", data);
        },
        onReconnectCallback: function (data) {
          // On chat SDK realtime adapter successfully reconnect to server
          console.log("onReconnectCallback " + data);
        },
        commentDeletedCallback: function (data) {
          // On comment deleted
          console.log("On comment delete", data);
        },
        roomClearedCallback: function (data) {
          // On user successfully cleared a room messages
          console.log(data);
        },
      },
    }).then((res)=>{
      console.log(res);
    });
  },
  methods: {
    // receiveMessage() {
    //   try {
    //     firebase.messaging().onMessage((payload) => {
    //       console.log("payload ", payload);
    //       this.showToast(payload);
    //       this.accept(payload);
    //       // this.StatusAccept = payload.data.patientAcceptStatus
    //       // this.$store.dispatch("consultation/actAcceptStatus", payload.data.patientAcceptStatus);
    //       // console.log(this.StatusAccept);
    //     });
    //   } catch (e) {
    //     console.log(e);
    //   }
    // },
    // accept(payload) {
    //   this.$store.dispatch(
    //     "consultation/actAcceptStatus",
    //     payload.data.patientAcceptStatus
    //   );
    // },
    // showToast(payload) {
    //   this.$toast.open({
    //     message: payload.notification.body,
    //     position: "top-right",
    //     type: "info",
    //     duration: 6000,
    //     onClick: () => {
    //       window.location = payload.notification.click_action;
    //     },
    //   });
    //   //  this.$store.dispatch("consultation/actAcceptStatus", payload.data.patientAcceptStatus);
    // },

    callRefreshToken() {
      console.log("trial");
      const params = {
        userId: window.localStorage.getItem("secUserId"),
        deviceType: "browser",
        deviceCode: "123",
        longToken: window.localStorage.getItem("longToken"),
        role: window.localStorage.getItem("role"),
      };
      console.log("PARAMS " + params.longToken);
      AuthServices.doRefreshToken(params)
        .then((response) => {
          console.log("MASUK SINI");
          console.log(
            "TOKEN REFRESH " +
              JSON.stringify(response.data.payload.shortToken.token)
          );
          window.localStorage.removeItem("shortToken");
          window.localStorage.setItem(
            "shortToken",
            response.data.payload.shortToken.token
          );
          console.log(
            "TOKEN STORAGE " + window.localStorage.getItem("shortToken")
          );
        })
        .catch((err) => {
          console.log(err);
          window.localStorage.clear();
          window.localStorage.removeItem("in");
          this.$router.push("/login");
        });
    },
  },
  // created() {
  //   try {
  //     firebase
  //       .messaging()
  //       .requestPermission()
  //       .then(() => {
  //         console.log("Notification permission granted");
  //         firebase
  //           .messaging()
  //           .getToken()
  //           .then((token) => {
  //             window.console.log("token ", token);

  //             // localStorage.setItem("tokenfcm", token)
  //             // console.log(this.id);
  //             // var navigator_info = window.navigator;
  //             // var screen_info = window.screen;
  //             // var uid = navigator_info.mimeTypes.length;
  //             // uid += navigator_info.userAgent.replace(/\D+/g, "");
  //             // uid += navigator_info.plugins.length;
  //             // uid += screen_info.height || "";
  //             // uid += screen_info.width || "";
  //             // uid += screen_info.pixelDepth || "";
  //             // console.log("token A ", uid);
  //             // var d = new Date().getTime();
  //             // this.id = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
  //             //   /[xy]/g,
  //             //   function (c) {
  //             //     var r = (d + Math.random() * 16) % 16 | 0;
  //             //     d = Math.floor(d / 16);
  //             //     return (c == "x" ? r : (r & 0x3) | 0x8).toString(16);
  //             //   }
  //             // );
  //             // console.log("token b", this.id);
  //             this.$store.dispatch("auth/actDataFcmtoken", token);
  //             window.localStorage.setItem("a", token)
  //             this.receiveMessage();
  //           });
  //       })
  //       .catch((err) => {
  //         this.$toast.error("Unable to get token, Silahkan Ubah Pengaturan Notifikasi Pada Browser Anda, dari Block Menjadi Allow", {
  //           type: "error",
  //           position: "top-right",
  //           duration: 6000,
  //         });
  //         console.log("Unable to get token ", err);
  //       });
  //   } catch (e) {
  //     this.$toast.error(e, {
  //       type: "error",
  //       position: "top-right",
  //       duration: 4000,
  //     });
  //     console.log(e);
  //   }
  // },
  mounted() {
    setInterval(this.callRefreshToken, 1800000);
    console.log("asdasd");
    if (window.localStorage.getItem("longToken") !== null) {
      setInterval(this.callRefreshToken(), 1800000);
    }
  },
};
</script>

<style>
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@200;300;400;500;600&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Nunito:wght@200;300;400&display=swap");
@import "./assets/style.css";
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Mulish:wght@200;300;400;500&family=Poppins:wght@100;200;300;400&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400&display=swap");
::-webkit-scrollbar {
  width: 5px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #ffffff;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(228, 228, 228);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #2b92e4;
}
@media screen and (min-width: 1360px) and (max-width: 1768px) {
  v-app {
    max-width: 100px;
    max-height: 60px;
  }
}
</style>
