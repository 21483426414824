/**
 * @author [Mochamad Arifin][Tandy Alam][Iman Akbar]
 * @email [mochamad.arifin@dwidasa.com][tandy.hutama@dwidasa.com][iman.akbar@dwidasa.com]
 * @create date 2021-01-11 12:51:34 
 * @modify date 2021-05-04 12:51:34
 * @desc [description]
 */
<template>
    <v-app>
        <v-container fluid fill-height class="bgColor">
            <v-row align="center" justify="center" class="">
                <v-col cols="auto" sm="10" md="8" lg="6" class="">
                    <v-card flat class=" ma-15 pa-15">
                        <v-row justify="center" class="">
                            <v-col cols="12" class=" ma-5 pa-0">
                                <v-row justify="center">
                                    <img src="@/assets/email.png" width="75" height="75" alt="">
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-row justify="center" class="">
                            <v-col class="ma-5 pa-0">
                                <v-row justify="center">
                                    <v-col cols="12" lg="10" class="justify-center">
                                        <p class="lblTitleSuccess">Reset Password Berhasil</p>
                                        <p class="centerText">Silahkan cek email Anda untuk mendapatkan password baru</p>
                                    </v-col>
                                </v-row>
                            </v-col>
                        </v-row>
                        <v-row justify="center">
                            <v-col cols="12" md="9" lg="9" class="yellow ma-5 pa-0">
                                <v-btn block @click="goToLogin" class="primary">Kembali ke Halaman Login</v-btn>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
            </v-row>
        </v-container>
</v-app>

</template>

<script>
export default {
    name: 'SuccessResetPass',
    data:() => ({
        
    }),
    methods: {
        goToLogin() {
            this.$router.push('/login')
        }
    },
}
</script>

<style scoped>
    .bgColor {
        background: #EDF4FB;
    }
    .lblTitleSuccess {
        font-family: Inter;
        font-style: normal;
        font-weight: bold;
        font-size: 36px;
        text-align: center;
        line-height: 44px;
        align-items: center;

        color: #2A8BF2;
    }
    .centerText {
        text-align: center;
    }
</style>