<template>
  <v-row class="mt-0">
    <v-col cols="4">
      <p class="ml-15 texttotal">Total Bulan Ini</p>
      <h4 class="ml-15 textjumlah">
        {{totalPatient}} <span class="textpasien">Pasien</span>
      </h4>
    </v-col>
    <v-col cols="4">
      <p class="texttotal">Pasien Aktif</p>
      <h4 class="textjumlah">{{activePatient}} <span class="textpasien">Pasien</span></h4>
    </v-col>
    <v-col cols="4">
      <p class="texttotal">Pasien Dibatalkan</p>
      <h4 class="textjumlah">{{cancelPatient}} <span class="textpasien">Pasien</span></h4>
    </v-col>
  </v-row>
</template>
<script>
import WithAppServices from "@/services/withAppServices";
export default {
  data: () => ({
    dataSumary: [],
    monthData: "",
    yearData: "",
    activePatient:0,
    cancelPatient:0,
    totalPatient:0
  }),
  mounted() {
    console.log(this.dataYear);
    console.log(this.dataMonth);
    this.getSummary();
  },
  methods: {
    getSummary() {
      let data = {
        month: this.dataMonth,
        year: this.dataYear,
        timezone : window.localStorage.getItem("timeZoneId")
      };
      console.log(data);
      WithAppServices.getSummaryMyApp(data).then((res) => {
        console.log("summary app", res);
        if (res.data.error !== "") {
          this.$toast.open({
            message: res.data.error,
            position: "top-right",
            type: "error",
            duration: 6000,
          });
        } else {
          this.activePatient = res.data.payload.activePatient
          this.cancelPatient = res.data.payload.cancelPatient
          this.totalPatient = res.data.payload.totalPatient
        }
      });
    },
  },
  computed: {
    dataYear() {
      return this.$store.getters["consultation/getYear"];
    },
    dataMonth() {
      return this.$store.getters["consultation/getMonth"];
    },
  },
};
</script>

<style  scoped>
.text-nama {
  font-size: 12px;
  font-family: Nunito;
}
.image-profile {
  width: 35px;
  height: 35px;
}
.btnmouth {
  border: 1px;
  box-sizing: border-box;
  border-radius: 20px 0px 0px 20px;
}

.textweek {
  font-family: Nunito;
  transform: rotate(180deg);
}
.textmouth {
  font-family: Nunito;
}

.todayitem {
  font-family: Nunito;
  font-style: normal;
  font-size: 10px;
  font-weight: 400;
}
.hr {
  margin-top: -15px;
}
.statustext {
  font-family: Nunito;
  font-style: normal;
  font-size: 13px;
  color: #00cc6a;
  text-decoration-line: underline;
}
.statustextbatal {
  font-family: Nunito;
  font-style: normal;
  font-size: 13px;
  color: #ff3737;
  text-decoration-line: underline;
}
.statusnama {
  font-family: Nunito;
  font-style: normal;
  font-weight: 600;
  font-size: 13px;
  /* identical to box height, or 100% */
  margin-top: -12px;
  text-decoration-line: underline;
  margin-top: -35px;
  /* klinisia biru 2 */
  color: #074291;
}
.statusnomerhp {
  font-family: Nunito;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  margin-top: -35px;
}
.colbtn {
  margin-top: -37px;
}
.cardtampil {
  overflow-y: auto;
  overflow-x: hidden;
}
.texttotal {
  font-family: Nunito;
  font-style: normal;
  font-weight: 500;
  font-size: 10px;
}
.textjumlah {
  font-family: Nunito;
  font-style: normal;
  font-weight: 900;
  font-size: 20px;
  margin-top: -17px;
}
.textpasien {
  font-family: Nunito;
  font-style: normal;
  font-weight: 600;
  font-size: 10px;
}
.textevent {
  font-family: Nunito;
  font-weight: 500;
}
.patientName {
  font-weight: bold;
  font-size: 14px;
  font-family: Nunito;
}
.patientGender {
  color: #777777;
  font-size: 12px;
  font-family: Nunito;
}
.imgicon {
  width: 12px;
  height: 12px;
  margin-top: -15px;
}
.bio {
  font-size: 8px;
  color: #414042;
  font-family: Nunito;
  font-weight: 400;
  margin-top: -15px;
}
.titleMenu {
  font-weight: bold;
  font-size: 12px;
  font-family: Nunito;
}
.scroll {
  display: flex !important;
  flex-direction: column;
  max-height: 375px;
}
.cardtab {
  background: #ffffff;
  overflow-y: scroll;
  overflow-x: hidden;
}
</style>