/**
 * @author [Mochamad Arifin]
 * @email [mochamad.arifin@dwidasa.com]
 * @create date 2021-01-15 17:17:28
 * @modify date 2021-01-15 17:17:28
 * @desc [description]
 */


<template>
  <v-row align="center" justify="center">
    <v-col class="ml-1">
      <!-- Row 1 -->
      <v-row>
        <v-col cols="12" lg="5" class="">
          <div class="titleContent">Nama Obat</div>
          <v-autocomplete
            dense
            v-model="inputNamaObat"
            required
            solo
            flat
            :items="dataSearch"
            item-text="medicineBrandName"
            item-value="phrMedicineBrandId"
            return-object
            :search-input.sync="search"
            hide-details
            clearable
            append-icon=""
            @change="changedObat()"
            class="customStyleTF"
          >
          </v-autocomplete>
        </v-col>

        <v-col cols="12" lg="3" class="">
          <div class="titleContent">Bentuk</div>
          <v-combobox
            dense
            v-model="typeObat"
            :items="itemsObat"
            item-value="value"
            item-text="label"
            solo
            flat
            hide-details
            class="customStyleTF"
          ></v-combobox>
        </v-col>

        <v-col cols="12" lg="4">
          <div class="titleContent">Kekuatan</div>
          <v-row class="ma-0 pa-0">
            <v-col cols="6" class="ma-0 pa-0">
              <v-text-field
                dense
                v-model="powerMg"
                solo
                flat
                required
                type="text"
                hide-details
                class="customStyleTF"
                min="0"
              >
              </v-text-field>
            </v-col>
            <v-col cols="6" class="ma-0 pa-0 contenMg">
              <v-combobox
                dense
                v-model="typeUnit"
                solo
                flat
                :items="itemUnit"
                item-text="label"
                item-value="value"
                return-object
                hide-details
                class="customStyleTF"
              >
              </v-combobox>
            </v-col>
          </v-row>
        </v-col>
      </v-row>

      <!-- Row 2 -->
      <v-row class="">
        <v-col cols="12" lg="6" class="ma-0 pa-0">
          <v-row>
            <v-col cols="3" class="ml-3 mr-3 ma-0 pa-0">
              <v-row align="center" justify="center">
                <v-col class="ml-3">
                  <div class="titleContent">Frekuensi</div>
                  <v-text-field
                    dense
                    v-model="frekuensiObat"
                    solo
                    flat
                    required
                    type="number"
                    hide-details
                    class="customStyleTF"
                    min="0"
                  >
                  </v-text-field>
                </v-col>
                <div class="mt-5 lblDummy">X</div>
              </v-row>
            </v-col>

            <v-col cols="3" class="ml-3 ma-0 pa-0">
              <v-row align="center" justify="center">
                <v-col>
                  <div class="titleContent">Dosis</div>
                  <v-text-field
                    dense
                    v-model="dosis"
                    solo
                    flat
                    required
                    type="number"
                    hide-details
                    class="customStyleTF"
                    min="0"
                  >
                  </v-text-field>
                </v-col>
                <div class="mt-5 lblDummy">Per</div>
              </v-row>
            </v-col>

            <v-col cols="4" class="ml-3">
              <div class="titleContent">Sigma</div>
              <v-combobox
                dense
                v-model="typePeriod"
                :items="itemPeriod"
                item-value="value"
                item-text="label"
                solo
                flat
                required
                hide-details
                class="customStyleTF"
              ></v-combobox>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12" lg="3" class="">
          <v-row>
            <v-col cols="6" class="ma-0 pa-0">
              <div class="titleContentCenter">Total Hari</div>
              <v-text-field
                dense
                v-model="totalHari"
                solo
                flat
                type="number"
                hide-details
                required
                class="customStyleTF ml-2 mr-1"
                min="0"
              >
              </v-text-field>
            </v-col>
            <v-col cols="6" lg="6" class="ma-0 pa-0">
              <div class="titleContentCenter">Jumlah</div>
              <v-text-field
                dense
                hide-details
                outlined
                 solo
                flat
                type="number"
                v-model="hasil"
                class="customStyleTF ml-3 mr-1"
              >
              </v-text-field>
            </v-col>
          </v-row>
        </v-col>

        <v-col cols="12" lg="3">
          <div class="titleContent">Cara Pakai</div>
          <v-combobox
            dense
            v-model="howToUse"
            solo
            flat
            :items="itemHtu"
            item-text="label"
            item-value="value"
            return-object
            hide-details
            class="customStyleTF"
          >
          </v-combobox>
        </v-col>
      </v-row>

      <!-- Row 3 -->
      <v-row>
        <v-col cols="12">
          <div class="titleContent">Instruksi</div>
        </v-col>

        <v-col cols="12" lg="3">
          <v-checkbox
            dense
            v-model="checkBeforeEat.id"
            :label="checkBeforeEat.value"
            color="info"
            hide-details
            class="chek"
          ></v-checkbox>
        </v-col>

        <v-col cols="12" lg="3">
          <v-checkbox
            dense
            v-model="checkAfterEat.id"
            :label="checkAfterEat.value"
            color="info"
            hide-details
          ></v-checkbox>
        </v-col>

        <v-col cols="12" lg="3">
          <v-checkbox
            dense
            v-model="checkIfSpent.id"
            :label="checkIfSpent.value"
            color="info"
            hide-details
            @change="isChecked(checkIfSpent)"
          ></v-checkbox>
        </v-col>

        <v-col cols="12" lg="3">
          <v-checkbox
            v-model="checkIfNeeded.id"
            :label="checkIfNeeded.value"
            color="info"
            hide-details
            @change="isChecked(checkIfNeeded)"
          ></v-checkbox>
        </v-col>

        <v-col cols="12">
          <v-row align="center" justify="center">
            <v-btn
              class="float-center customtf"
              dark
              width="320"
              color="#51A5FF"
              @click="addToList"
            >
              Tambahkan ke Daftar Obat
            </v-btn>
          </v-row>
          <v-alert
            class="mt-3"
            v-show="alertShowToList"
            outlined
            :type="typeAlert"
            text
          >
            {{ textAlert }}
          </v-alert>
        </v-col>
      </v-row>

      <!-- Row 4 -->
      <!-- <v-row>
        <v-col cols="12">
          <v-container class="customDivider"></v-container>
        </v-col>
      </v-row> -->

      <!-- Row 5 -->
      <v-row>
        <v-col cols="12">
          <v-container fluid fill-height class="contentTable">
            <v-row>
              <v-col cols="12">
                <!-- For Title -->
                <v-row>
                  <v-col cols="1">
                    <div class="headerTable">Tipe</div>
                  </v-col>
                  <v-col cols="2">
                    <div class="headerTable">Nama Obat</div>
                  </v-col>
                  <v-col cols="2">
                    <div class="headerTable">Kekuatan</div>
                  </v-col>
                  <v-col cols="2">
                    <div class="headerTable">Sigma</div>
                  </v-col>
                  <v-col cols="2">
                    <div class="headerTable">Cara Pakai</div>
                  </v-col>
                  <v-col cols="3" class="ma-0 pa-0">
                    <v-row>
                      <v-col cols="6">
                        <div class="headerTable">Instruksi</div>
                      </v-col>

                      <v-col cols="5">
                        <div class="headerTable">Jumlah</div>
                      </v-col>
                    </v-row>
                  </v-col>
                </v-row>
                <!-- For Data -->
                <v-row>
                  <!-- No Data -->
                  <v-col v-show="noData">
                    <v-container class="rounded ml-0 mr-0">
                      <v-row justify="center">
                        <div class="mt-3 mb-3 lblNoReceipt">
                          Belum Ada Resep Yang Diberikan
                        </div>
                      </v-row>
                    </v-container>
                  </v-col>

                  <!-- Data Show -->
                  <v-col v-show="dataShow">
                    <v-list>
                      <v-list-item
                        v-for="(drug, index) in dataTable"
                        :key="index"
                      >
                        <v-row>
                          <v-col cols="1" class="">
                            <img
                              src="@/assets/iconTypeObat.png"
                              width="20"
                              height="20"
                              alt=""
                            />
                          </v-col>
                          <v-col cols="2" class="">
                            <div class="mt-2 listTable">
                              {{ drug.drugName }}
                            </div>
                          </v-col>
                          <v-col cols="2" class="">
                            <div class="mt-2 listTable">
                              {{ drug.drugPower }}
                            </div>
                          </v-col>
                          <v-col cols="2" class="">
                            <div class="mt-2 listTable">{{ drug.sigma }}</div>
                          </v-col>
                          <v-col cols="2" class="">
                            <div class="mt-2 listTable">{{ drug.htuse }}</div>
                          </v-col>
                          <v-col cols="2" class="">
                            <div class="mt-2 listTable">
                              {{ drug.instruction }}
                            </div>
                          </v-col>
                          <v-col cols="1" class="mt-1">
                            <v-row class="">
                              <v-col class="mt-1 ma-0 pa-0" cols="7">
                                <div class="listTable">{{ drug.total }}</div>
                              </v-col>
                              <v-col class="ma-0 pa-0 float-right" cols="5">
                                <v-icon @click="removeListDrug(index)"
                                  >cancel</v-icon
                                >
                              </v-col>
                            </v-row>
                          </v-col>
                        </v-row>
                      </v-list-item>
                    </v-list>
                  </v-col>
                </v-row>

                <v-alert v-show="alertShow" outlined :type="typeAlert" text>
                  {{ textAlert }}
                </v-alert>
              </v-col>

              <v-col v-show="showCheckBox" cols="6">
                <v-checkbox
                  dense
                  v-model="isDoctorAgreement"
                  color="info"
                  hide-details
                  label="Sesuai Dengan Persetujuan Dokter"
                ></v-checkbox>
              </v-col>

              <v-col v-show="showButton" cols="6">
                <v-btn
                  rounded
                  width="120"
                  class="float-right white--text"
                  :disabled="!isDoctorAgreement"
                  color="#00CC6A"
                  @click="postPrescription"
                >
                  Submit
                </v-btn>
              </v-col>
            </v-row>
          </v-container>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>

<script>
import PharmacyServices from "@/services/pharmacy_services";
import ConsultationService from "@/services/consultation_services";
export default {
  data: () => ({
    typeAlert: "success",
    alertShow: false,
    alertShowToList: false,
    textAlert: "",
    inputNamaObat: "",
    powerMg: "",
    typeUnit: "",
    // {value:"mg",messageKey:"label.UnitType.mg",label:"mg"},
    typeObat: "",
    typePeriod: {
      value: "dayly",
      messageKey: "label.PeriodType.dayly",
      label: "Harian",
    },
    frekuensiObat: "",
    dosis: "",
    search: null,
    totalHari: "",
    howToUse: "",
    hasil:"",
    // {value:"drink",messageKey:"label.HowToUseType.drink",label:"Minum Saja"},
    total: "",
    checkBeforeEat: { id: false, value: "Sebelum Makan", item: "beforeEat" },
    checkAfterEat: { id: false, value: "Setelah Makan", item: "afterEat" },
    checkIfSpent: { id: false, value: "Dihabiskan", item: "all" },
    checkIfNeeded: { id: false, value: "Jika Perlu", item: "ifNeeded" },
    itemsObat: [],
    baseItemsObat: [],
    dataSearch: [],
    itemPeriod: [],
    itemHtu: [],
    baseItemHtu: [],
    itemUnit: [],
    baseItemUnit: [],
    selectedType: [],
    itemInstruction: [],
    showCheckBox: false,
    showButton: false,
    noData: true,
    dataShow: false,
    isDoctorAgreement: false,
    dataTable: [],
    selectedInstruction: [],
    objSearch: {},
    instruction: [],
    status: false,
    detailFcm: "",
    params: {
      uriParam: window.localStorage.getItem("cntConsultationId"),
      bodyParams: {
        cntPrescriptionId: "",
        cntConsultationId: window.localStorage.getItem("cntConsultationId"),
        prescriptionNumber: "",
        doctorApprovalStatus: false,
        details: [],
      },
    },
    selectedHtu: "",
    selectedPeriod: "",
  }),

  methods: {
    // power() {
    //   // var check = this.powerMg;
    //   // var data = check.replace(/[A-Za-z!@#$%^&*()]/g, "");
    //   const replacements = {
    //     ",": ".",
    //     "a": "",

    //   };
    //   Object.keys(replacements).forEach((k) => {
    //     this.powerMg =  this.powerMg.replace(k, replacements[k]);
    //   });
    //   // console.log(this.powerMg);
    // },
    choose(e) {
      console.log(e);
      console.log("ini data");
    },
    isChecked(obj) {
      if (obj.value === "Jika Perlu") {
        this.checkIfSpent.id = !obj.id;
      }
      if (obj.value === "Dihabiskan") {
        this.checkIfNeeded.id = !obj.id;
      }
    },
    changedObat() {
      this.itemsObat = this.baseItemsObat.filter((obj) =>
        this.inputNamaObat.medicineTypes.includes(obj.value)
      );
      this.itemHtu = this.baseItemHtu.filter((obj) =>
        this.inputNamaObat.howToUseTypes.includes(obj.value)
      );
      this.itemUnit = this.baseItemUnit.filter((obj) =>
        this.inputNamaObat.unitTypes.includes(obj.value)
      );
      this.typeObat = this.itemsObat[0];
      this.howToUse = this.itemHtu[0];
      this.typeUnit = this.itemUnit[0];
    },
    addToList() {
      console.log("power", this.powerMg);
      let listCheck = [
        this.checkBeforeEat,
        this.checkAfterEat,
        this.checkIfSpent,
        this.checkIfNeeded,
      ];
      this.instruction.push(...listCheck.filter((obj) => obj.id === true));

      if (this.inputNamaObat.length === 0 || this.instruction.length === 0) {
        this.alertShowToList = true;
        this.typeAlert = "error";
        this.textAlert = "Semua Data Obat dan Instruksi wajib diisi";
        setTimeout(() => {
          this.alertShowToList = false;
        }, 7000);
      } else if (this.powerMg === ""|| this.frekuensiObat === "" || this.dosis==="" || this.totalHari === "" || this.hasil === "") {
        this.alertShowToList = true;
        this.typeAlert = "error";
        this.textAlert = "Data Obat dan Instruksi wajib diisi";
        setTimeout(() => {
          this.alertShowToList = false;
        }, 7000);
      } 
      else {
        this.noData = false;
        this.dataShow = true;
        this.showCheckBox = true;
        this.showButton = true;
        this.dataTable.push({
          drugName: this.inputNamaObat.medicineBrandName,
          drugPower: this.powerMg + this.typeUnit.label,
          sigma:
            this.frekuensiObat +
            "x" +
            this.dosis +
            " /" +
            this.typePeriod.label,
          htuse: this.howToUse.label,
          instruction: this.instruction.map((e) => e.value).toString(),
          total: this.hasil,
        });
        console.log("ini data table" + JSON.stringify(this.dataTable));
        this.params.bodyParams.details.push({
          cntPrescriptionDetailId: "",
          cntPrescriptionId: "",
          prescriptionDetailOrder: this.dataTable.length,
          phrMedicineBrandId: this.inputNamaObat.phrMedicineBrandId,
          medicineBrandName: this.inputNamaObat.medicineBrandName,
          medicineType: this.typeObat.value,
          medicineSize: this.powerMg,
          medicineSizeUnitType: this.typeUnit.value,
          frequency: this.frekuensiObat,
          dosage: this.dosis,
          periodType: this.typePeriod.value,
          totalPeriod: this.totalHari,
          totalMedicine: this.hasil,
          howToUseType: this.howToUse.value,
          instructionTypes: this.instruction.map((e) => e.item),
        });
        console.log(
          "ini data table " + JSON.stringify(this.params.bodyParams.details)
        );
        this.clearData();
        if (
          this.params.bodyParams.details != null &&
          this.isDoctorAgreement == false
        ) {
          this.$store.dispatch("consultation/actStatusObat", true);
        }
      }
    },
    postPrescription() {
      console.log(this.params);
      this.params.bodyParams.doctorApprovalStatus = this.isDoctorAgreement;
      console.log(this.params);
      this.$store.dispatch("consultation/actStatusObat", false);
      this.$store
        .dispatch("consultation/actPostPrescription", this.params)
        .then((response) => {
          if (response.data.error === "") {
            this.typeAlert = "success";
            this.textAlert = "Success";
          } else {
            this.typeAlert = "error";
            this.textAlert = response.data.error;
          }
          this.alertShow = true;
          setTimeout(() => {
            this.alertShow = false;
          }, 5000);
        })
        .catch((error) => {
          this.alertShow = true;
          this.typeAlert = "error";
          this.textAlert = error;
          setTimeout(() => {
            this.alertShow = false;
          }, 5000);
        });
    },
    removeListDrug(value) {
      this.dataTable.splice(value, 1);
      this.params.bodyParams.details.splice(value, 1);
      if (this.dataTable == 0) {
        this.noData = true;
        this.dataShow = false;
        this.showCheckBox = false;
        this.showButton = false;
      }
    },
    getAllDataSelection() {
      PharmacyServices.getMasterLovs().then((response) => {
        console.log("ini response master obat", response);
        this.baseItemsObat = response.data.payload["medicine-type"];
        this.itemPeriod = response.data.payload["period-type"]; //flag to change
        this.baseItemHtu = response.data.payload["how-to-use-type"]; //flag to change
        this.baseItemUnit = response.data.payload["unit-type"];
        this.itemInstruction = response.data.payload["instruction-type"];

        let statusMenu = this.getResponseTakeQueue.data.payload.consultation
          .prescriptionStatus;
        if (statusMenu) {
          ConsultationService.getPrescription(
            window.localStorage.getItem("cntConsultationId")
          ).then((response) => {
            console.log("ini response input obat", JSON.stringify(response));
            this.noData = false;
            this.dataShow = true;
            this.showCheckBox = true;
            this.showButton = true;
            var obat = response.data.payload.details;
            obat.forEach((obj) => {
              obj.instructionTypes = this.itemInstruction.filter((item) =>
                obj.instructionTypes.includes(item.value)
              );
            });
            obat.map((namaobat) => {
              this.selectedHtu = this.baseItemHtu.filter(function (item) {
                if (item.value == namaobat.howToUseType) return item.label;
              });
              this.selectedType = this.baseItemUnit.filter(function (item) {
                if (item.value == namaobat.medicineSizeUnitType)
                  return item.label;
              });
              this.selectedPeriod = this.itemPeriod.filter(function (item) {
                if (item.value == namaobat.periodType) return item.label;
              });
              this.dataTable.push({
                drugName: namaobat.medicineBrandName,
                drugPower: namaobat.medicineSize + this.selectedType[0].label,
                sigma:
                  namaobat.frequency +
                  "x" +
                  namaobat.dosage +
                  " /" +
                  this.selectedPeriod[0].label,
                htuse: this.selectedHtu[0].label,
                instruction: namaobat.instructionTypes
                  .map((e) => e.label)
                  .toString(),
                total: namaobat.totalMedicine,
              });
              this.params.bodyParams.details.push({
                cntPrescriptionDetailId: "",
                cntPrescriptionId: "",
                prescriptionDetailOrder: this.dataTable.length,
                phrMedicineBrandId: namaobat.phrMedicineBrandId,
                medicineBrandName: namaobat.medicineBrandName,
                medicineType: namaobat.medicineType,
                medicineSize: namaobat.medicineSize,
                medicineSizeUnitType: namaobat.medicineSizeUnitType,
                frequency: namaobat.frequency,
                dosage: namaobat.dosage,
                periodType: namaobat.periodType,
                totalPeriod: namaobat.totalPeriod,
                totalMedicine: namaobat.totalMedicine,
                howToUseType: namaobat.howToUseType,
                instructionTypes: namaobat.instructionTypes.map((e) => e.value),
              });
            });
          });
        }
      });
    },
    clearData() {
      this.instruction = [];
      (this.inputNamaObat = ""),
        (this.powerMg = ""),
        (this.frekuensiObat = ""),
        (this.dosis = ""),
        (this.totalHari = ""),
        this.hasil = "",
        (this.checkBeforeEat.id = false),
        (this.checkAfterEat.id = false),
        (this.checkIfSpent.id = false),
        (this.checkIfNeeded.id = false);
      (this.itemHtu = []),
        (this.itemsObat = []),
        (this.itemUnit = []),
        (this.typeObat = ""),
        (this.typeUnit = ""),
        (this.howToUse = "");
    },
  },
  computed: {
    getStatusDenganJanji() {
      return this.$store.getters["consultation/getDenganJanji"];
    },
    getResponseTakeQueue() {
      if (this.getStatusDenganJanji !== true) {
        return this.$store.getters["consultation/getResponseTakeQueue"];
      } else {
        return this.$store.getters["consultation/getResponseTakeQueueWithApp"];
      }
    },
    // sum() {
    //   let hasil;
    //   if (
    //     this.frekuensiObat.length == 0 &&
    //     this.dosis.length == 0 &&
    //     this.totalHari.length == 0
    //   ) {
    //     hasil = 0;
    //   } else if (
    //     this.frekuensiObat.length == 0 ||
    //     this.dosis.length == 0 ||
    //     this.totalHari.length == 0
    //   ) {
    //     hasil = 0;
    //   } else {
    //     hasil =
    //       parseInt(this.frekuensiObat) *
    //       parseInt(this.dosis) *
    //       parseInt(this.totalHari);
    //   }
    //   return hasil;
    // },
  },
  mounted() {
    this.getStatusDenganJanji
    this.getAllDataSelection();
    ConsultationService.getPrescription(
      window.localStorage.getItem("cntConsultationId")
    ).then((response) => {
      console.log("ini", response);
    });
    window.localStorage.removeItem(
      "detailobat",
      this.params.bodyParams.details
    );
    window.localStorage.setItem("isdoctor", this.isDoctorAgreement);
  },
  watch: {
    search(value) {
      // if (!value) {
      //   this.clearData();
      // }
      if (value != null) {
        if (value.length >= 3) {
          PharmacyServices.getMasterSearchMedice(value).then((response) => {
            this.dataSearch = response.data.payload;
            // console.log(JSON.stringify(this.dataSearch));
          });
        }
      }
    },
    // replace(){
    //   const replacements = {
    //     ",": ".",
    //   }
    //   Object.keys(replacements).forEach(k => {
    //     this.powerMg = this.powerMg.replace(k, replacements[k])
    //   })
    // },
    powerMg(value){
      this.powerMg = value.replace(/[A-Za-z!@#$%^&*()]/g, '');
      const replacements = {
        ",": ".",
      }
      Object.keys(replacements).forEach(k => {
        this.powerMg = this.powerMg.replace(k, replacements[k])
      })
    },
  },
};
</script>

<style>
/* .v-list {
    height: 200px;
    overflow-y: auto;
} */
.unstyled,
.v-input
  > .v-input-control
  > .v-input-slot
  > .v-text-field__slot
  > input
  > ::-webkit-inner-spin-button,
::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
.inputPrice input[type="number"] {
  -moz-appearance: textfield;
}
.inputPrice input::-webkit-outer-spin-button,
.inputPrice input::-webkit-inner-spin-button {
  -webkit-appearance: none;
}
.lblNoReceipt {
  font-size: 16px;
  color: rgba(7, 48, 89, 0.58);
  font-family: Nunito;
}
.rounded {
  border-radius: 10px;
  background-color: white;
}
.headerTable {
  color: #6e7274;
  font-weight: bold;
  font-size: 13px;
  font-family: Nunito;
}
.listTable {
  font-size: 14px;
  line-height: 16px;
  font-family: Nunito;
}
.customDivider {
  background: #d7e2e8;
  border: 1px solid #ebf0ff;
  box-sizing: border-box;
  border-radius: 10px;
}
.contenMg {
  background: #e9f5fc;
  border-radius: 0px 10px 10px 0px;
  font-family: Nunito;
}
.titleContent {
  color: #073059;
  font-weight: bold;
  font-size: 15px;
  font-family: Nunito;
}
.titleContentCenter {
  color: #073059;
  text-align: center;
  font-weight: bold;
  font-size: 15px;
  font-family: Nunito;
}
.customStyleTFReadOnly {
  background: #e9f5fc;
  border-radius: 10px;
  border: 1px solid rgba(43, 146, 228, 0.55);
  border-radius: 10px;
  font-family: Nunito;
}
.customStyleTFReadOnly >>> input {
  border-radius: 10px;
  text-align: center;
}
.customStyleTF {
  border-radius: 10px;
  border: 1px solid rgba(43, 146, 228, 0.55);
  box-sizing: border-box;
}
.contentTable {
  background: #e9f5fc;
  border-radius: 15px;
}
.lblDummy {
  color: #000000;
  font-weight: bold;
  font-size: 15px;
}

@media only screen and (max-width: 1366px) {
  .titleContent {
    color: #073059;
    font-weight: bold;
    font-size: 13px;
    font-family: Nunito;
  }
  .titleContentCenter {
    color: #073059;
    text-align: center;
    font-weight: bold;
    font-size: 13px;
    font-family: Nunito;
  }
  .customStyleTF {
    border-radius: 10px;
    border: 1px solid rgba(43, 146, 228, 0.55);
    box-sizing: border-box;
    font-size: 11px;
    font-family: Nunito;
  }
  .customStyleTFReadOnly {
    font-size: 11px;
    background: #e9f5fc;
    border-radius: 10px;
    border: 1px solid rgba(43, 146, 228, 0.55);
    border-radius: 10px;
    font-family: Nunito;
  }
  .chek {
    font-size: 11px;
    height: 5px;
  }
  .listTable {
    font-size: 11px;
    line-height: 10px;
    font-family: Nunito;
  }
  .headerTable {
    color: #6e7274;
    font-weight: bold;
    font-size: 12px;
    font-family: Nunito;
  }
  .customtf {
    font-size: 12px;
  }
}
</style>