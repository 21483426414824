import Vue from "vue";
import VueRouter from "vue-router";

// Component
import Layout from "@/components/Layout.vue";

// Page
import Home from "@/views/Home.vue";
import Login from "@/views/Login.vue";

import ForgotPass from "@/views/ForgotPass.vue";
import SuccessResetPass from "@/views/SuccessResetPass.vue";
import ChangePass from "@/views/ChangePass.vue";
import ForceChangePass from "@/views/ForceChangePass.vue";
import Error from "@/views/Error.vue";
import Chat from "@/views/Chat.vue";
import Question from "@/views/Question.vue";

import Calender from "@/views/Calender.vue";
import ResetPassword from "@/views/ResetPassword.vue";
import showyourip from "@/views/showyourip.vue";

Vue.use(VueRouter);

// let questionView = window.localStorage.getItem('questionFeature') ? Question : Error;


const routes = [{
    path: "/login",
    name: "Login",
    component: Login,
    meta: {
      guest: true,
    },
  },
  {
    path: "/showyourip",
    name: "showyourip",
    component: showyourip,
    meta: {
      guest: true,
    },
  },
  {
    path: "/",
    redirect: "login",
    name: "Layout",
    component: Layout,
    children: [{
        path: "/home",
        name: "Home",
        component: Home,
        props: true,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/changepass",
        name: "ChangePass",
        component: ChangePass,
        meta: {
          requiresAuth: true,
        },
      },
      {
        path: "/chat",
        name: "Chat",
        component: Chat,
        meta: {
          requiresAuth: true,
        },
      },

      {
        path: "/question",
        name: "Question",
        component: Question,
        meta: {
          requiresAuth: true,
          questionFeature: true,
        },
      },
      {
        path: "/calender",
        name: "Calender",
        component: Calender,
        meta: {
          requiresAuth: true,
        },
      },
    ],
  },
  {
    path: "/forcechangepass",
    name: "ForceChangePass",
    component: ForceChangePass,
    meta: {
      requiresAuth: true,
    },
  },
  {
    path: "/resetpassword/:token",
    name: "ResetPassword",
    component: ResetPassword,
    meta: {
      guest: true,
    },
  },
  {
    path: "/forgotpass",
    name: "ForgotPass",
    component: ForgotPass,
    meta: {
      guest: true,
    },
  },
  {
    path: "/successresetpass",
    name: "SuccessResetPass",
    component: SuccessResetPass,
    meta: {
      guest: true,
    },
  },

  {
    path: "*",
    name: "Error",
    component: Error,
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach((to, from, next) => {

  console.log(to);
  // const datafcmToken = this.$store.getters["auth/getDataFcmtoken"];
  if (to.matched.some((record) => record.meta.requiresAuth)) {

    if (window.localStorage.getItem("shortToken") == null) {
      next({
        path: "/login",
        params: {
          nextUrl: to.fullPath
        },
      });
    } else {
      next();
    }
  } else if (to.matched.some((record) => record.meta.guest)) {
    if (window.localStorage.getItem("shortToken") == null) {
      next();
    } else {
      next({
        path: "/home",
        params: {
          nextUrl: to.fullPath
        },
      });
    }


  } else {
    next();
  }
});

export default router;