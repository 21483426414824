/**
 * @author [Mochamad Arifin][Tandy Alam][Iman Akbar]
 * @email [mochamad.arifin@dwidasa.com][tandy.hutama@dwidasa.com][iman.akbar@dwidasa.com]
 * @create date 2021-01-11 12:51:34 
 * @modify date 2021-05-04 12:51:34
 * @desc [description]
 */
<template>
  <v-app class="resolution">
    <v-dialog v-model="dialogWarning" persistent max-width="500">
      <v-card style="background-color: #29aaf3">
        <v-row class="ma-4 pa-2">
          <v-col cols="12" align-self="center">
            <p class="icd-judul">Konsultasi Segera Di Mulai</p>
          </v-col>
          <v-col cols="12">
            <p class="icd-text">Konsultasi Segera Di Mulai, Pada {{ jam }}</p>
            <p class="icd-text">Dengan Pasien {{ nama }}</p>
          </v-col>
        </v-row>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn medium outlined color="" dark @click="oke">Oke</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <vue-audio :file="file1" :autoPlay="statusPlay" hidden />
    <v-main class="bgColor">
      <vue-audio :file="file1" :autoPlay="statusPlay" hidden />
      <v-container fluid class="bgColor2">
        <vue-audio :file="file1" :autoPlay="statusPlay" hidden />
        <v-layout row wrap class="">
          <vue-audio :file="file1" :autoPlay="statusPlay" hidden />
          <v-spacer></v-spacer>
          <p class="ma-3 pa-3 text-nama">{{ fullName }}</p>
          <v-flex md1 class="ma-2 pa-0" align-self-start>
            <v-avatar size="40" color="white">
              <img class="image-profile" :src="photoProfile" alt="" />
            </v-avatar>
          </v-flex>
          <v-flex md12 sm12 xs12>
            <p class="lblDate ml-5 mt-1 pa-0">{{ date | dashboardDate }}</p>
            <p class="lblConsultation ml-5 mt-3 pa-0">Konsultasi Hari ini</p>
          </v-flex>
        </v-layout>

        <v-layout row wrap justify-center class="ma-0 mt-5">
          <v-flex class="ml-2 mt-1">
            <v-card hover :class="'rounded-xl'" class="rounded">
              <v-row>
                <v-col cols="4" md="4" class="" align-self="center">
                  <img
                    src="@/assets/iconcomplete.png"
                    alt=""
                    class="img-icon ml-3"
                  />
                </v-col>
                <v-col cols="8">
                  <p class="lblTitleCard mt-2 ma-0 pa-0">
                    {{ hospitalComplete }}
                  </p>
                  <p class="lblSubTitleCard">Progress Selesai</p>
                </v-col>
              </v-row>
            </v-card>
          </v-flex>
          <v-flex class="ml-2 mt-1">
            <v-card hover :class="'rounded-xl'" class="rounded">
              <v-row>
                <v-col cols="4" md="4" class="" align-self="center">
                  <img
                    src="@/assets/iconantrian.png"
                    alt=""
                    class="img-icon ml-3"
                  />
                </v-col>
                <v-col cols="8">
                  <p class="lblTitleCard mt-2 ma-0 pa-0">
                    {{ hospitaiInQueue }}
                    <vue-audio :file="file1" :autoPlay="statusPlay" hidden />
                  </p>
                  <div>
                    <vue-audio :file="file1" :autoPlay="statusPlay" hidden />
                  </div>
                  <p class="lblSubTitleCard">Dalam Antrian</p>
                </v-col>
              </v-row>
            </v-card>
          </v-flex>
          <v-flex class="ml-3 mt-1">
            <v-card hover :class="'rounded-xl'" class="rounded">
              <v-row>
                <v-col cols="4" md="4" class="" align-self="center">
                  <img
                    src="@/assets/iconkonsultasi.png"
                    class="img-icon ml-3"
                    alt=""
                  />
                </v-col>
                <v-col cols="8">
                  <p class="lblTitleCard mt-2 ma-0 pa-0">
                    {{ hospitalInProgress }}
                  </p>
                  <p class="lblSubTitleCard">Dalam Konsultasi</p>
                </v-col>
              </v-row>
            </v-card>
          </v-flex>
          <v-flex class="ml-3 mt-1">
            <v-card hover :class="'rounded-xl'" class="rounded">
              <v-row>
                <v-col cols="4" md="4" class="" align-self="center">
                  <v-progress-circular
                    :size="60"
                    color="#5236FF"
                    width="19"
                    :value="hospitalCompletePercentage"
                    class="ml-3 img-icon"
                  ></v-progress-circular>
                </v-col>
                <v-col cols="8">
                  <p class="lblTitleCard mt-2 ma-0 pa-0">
                    {{ hospitalCompletePercentage }}
                  </p>
                  <p class="lblSubTitleCard">Progress Selesai</p>
                </v-col>
              </v-row>
            </v-card>
          </v-flex>
        </v-layout>
        <v-row>
          <v-col class="mr-3 mt-5">
            <v-card :class="'rounded-xl'" class="cardchard">
              <v-row>
                <v-col cols="12" md="7" lg="9" class="">
                  <v-row class="ml-3">
                    <v-col cols="12" lg="6">
                      <p class="headerChart">Today's trends</p>
                      <p class="subHeaderChart">
                        as of {{ dateDialog | diagramDate }}
                      </p>
                    </v-col>
                  </v-row>
                  <v-row class="">
                    <v-col cols="12">
                      <apexchart
                        type="area"
                        height="240"
                        :options="chartOptions"
                        :series="series"
                      ></apexchart>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col cols="12" md="5" lg="3">
                  <v-row class="">
                    <v-col class="">
                      <p class="average">Average General Consultation Time</p>
                    </v-col>
                    <v-col class="">
                      <p class="resulTimeChart">
                        {{ hospitalAverageConsultationTime }}
                      </p>
                    </v-col>
                  </v-row>
                  <v-divider class="blue"></v-divider>
                  <v-row class="">
                    <v-col>
                      <p class="average">Average Consultation Time</p>
                    </v-col>
                    <v-col>
                      <p class="resulTimeChart">
                        {{ doctorAverageConsultationTime }}
                      </p>
                    </v-col>
                  </v-row>
                  <v-divider class="blue"></v-divider>
                  <v-row class="">
                    <v-col>
                      <p class="average">Average First Response Time</p>
                    </v-col>
                    <v-col>
                      <p class="resulTimeChart">
                        {{ averageFirstResponseTime }}
                      </p>
                    </v-col>
                  </v-row>
                  <v-divider class="blue"></v-divider>
                  <v-row class="">
                    <v-col>
                      <p class="average">Average Response Time</p>
                    </v-col>
                    <v-col>
                      <p class="resulTimeChart">{{ averageResponseTime }}</p>
                    </v-col>
                  </v-row>
                  <v-divider class="blue"></v-divider>
                  <v-row class="">
                    <v-col>
                      <p class="average">Resolution with SLA</p>
                    </v-col>
                    <v-col>
                      <p class="resulTimeChart">{{ resolutionWithSLA }}</p>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
            </v-card>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import AuthServices from "@/services/auth_services";
import GeneralServices from "@/services/general_services";
import DoctorServices from "@/services/doctor_services";
import moment from "moment";
import notif from "@/assets/pristine-609.mp3";
import VueAudio from "vue-audio";
import NotificationServices from "@/services/notificationServices";
export default {
  name: "Home",
  data: () => ({
    file1: notif,
    statusPlay: false,
    dialogWarning: false,
    antri: 0,
    date: new Date(),
    dateDialog: new Date(),
    fullName: "",
    waktu: null,
    photoProfile: null,
    hospitalComplete: "",
    hospitalInProgress: "",
    hospitaiInQueue: "",
    hospitalCompletePercentage: "",
    hospitalAverageConsultationTime: "",
    doctorAverageConsultationTime: "",
    averageFirstResponseTime: "",
    averageResponseTime: "",
    resolutionWithSLA: "",
    series: [],
    tmpToday: [],
    tmpYesterday: [],
    dataGrafikToday: [],
    dataGrafikYestrday: [],
    resultArray: [],
    resultArray2: [],
    nilai: 0,
    chartOptions: {
      chart: {
        height: 100,
        type: "area",
      },
      dataLabels: {
        enabled: false,
      },
      stroke: {
        curve: "smooth",
      },
      xaxis: {
        type: "datetime",
        categories: [],
      },
      tooltip: {
        x: {
          format: "dd/MM/yy HH:mm",
        },
      },
    },
  }),

  methods: {
    // popup() {
    //   console.log(this.nama);
    //   console.log(this.jam);
    //   console.log(this.dialogWarning);
    //   this.dialogWarning = this.warning;
    //   this.nama;
    //   this.jam;
    // },
    callRefreshToken() {
      // console.log("trial");
      const params = {
        userId: window.localStorage.getItem("secUserId"),
        deviceType: "browser",
        deviceCode: "123",
        longToken: window.localStorage.getItem("longToken"),
        role: window.localStorage.getItem("role"),
      };
      console.log("PARAMS " + params.longToken);
      AuthServices.doRefreshToken(params)
        .then((response) => {
          console.log("MASUK SINI");
          console.log(
            "TOKEN REFRESH " +
              JSON.stringify(response.data.payload.shortToken.token)
          );
          window.localStorage.removeItem("shortToken");
          window.localStorage.setItem(
            "shortToken",
            response.data.payload.shortToken.token
          );
          console.log(
            "TOKEN STORAGE " + window.localStorage.getItem("shortToken")
          );
        })
        .catch((err) => {
          console.log(err);
          console.log("long token error");
          window.localStorage.clear();
          window.localStorage.removeItem("in");
          this.$router.push("/login");
        });
    },
    getPhotoprofile() {
      // GeneralServices.getPhotoProfile(
      //   window.localStorage.getItem("secUserId")
      // ).then((response) => {
      //   console.log('l',response);
      //   this.photoProfile =
      //     response.config.url +
      //     "?token=" +
      //     window.localStorage.getItem("shortToken");
      //   this.$store.dispatch("consultation/actPhoto", this.photoProfile);
      // });
      DoctorServices.getProfile().then((res) => {
        console.log("prfile", res);
        this.fullName = res.data.payload.fullName;
        this.photoProfile = res.data.payload.profilePictureUrl;
        console.log(this.photoProfile);
      });
    },
    getDataSummary() {
      GeneralServices.getDashboardConsultationSummary().then((response) => {
        this.hospitalComplete = response.data.payload.todayFinish;
        this.hospitalInProgress = response.data.payload.todayProgress;
        this.hospitaiInQueue = response.data.payload.todayQueue;
        this.hospitalCompletePercentage =
          Math.trunc(response.data.payload.todayFinishPercentage * 100) + "%";

        var data = this.hospitaiInQueue;
        // console.log(data +1);
        // console.log("295", this.antri);
        if (this.antri > 0) {
          if (data + 1 > this.antri) {
            this.antri = this.hospitaiInQueue + 1;
            // this.statusPlay = true;
            var audio = new Audio(this.file1);
            audio.play();
            // console.log("nyala");
          } else if (data + 1 < this.antri || data + 1 == this.antri) {
            this.antri = this.hospitaiInQueue + 1;
            // console.log("diam");
          }
        }
        if (this.antri == 0) {
          this.antri = this.hospitaiInQueue + 1;
          // console.log(this.antri);
          // console.log("diam");
        }
      });
    },

    getDataPerformance() {
      GeneralServices.getDashboardConsultationPerformance().then((response) => {
        this.hospitalAverageConsultationTime =
          response.data.payload.hospitalAverageConsultationTime.minutes + "m";
        this.doctorAverageConsultationTime =
          response.data.payload.doctorAverageConsultationTime.minutes + "m";
        this.averageFirstResponseTime =
          response.data.payload.doctorAverageResponseTime.minutes +
          "m:" +
          response.data.payload.doctorAverageResponseTime.totalSeconds +
          "s";
        this.averageResponseTime =
          response.data.payload.doctorAverageChatResponseTime.minutes + "m";
        this.resolutionWithSLA =
          Math.trunc(response.data.payload.hospitalSlaPercentage * 100) + "%";
      });
    },
    oke() {
      this.dialogWarning = false;
      this.$store.dispatch("consultation/actWarning", false);
    },
    getDataGrafik() {
      // this.fullName = window.localStorage.getItem("fullName");
      GeneralServices.getDashboardTraffic().then((response) => {
        this.dataGrafikToday = response.data.payload.today;
        this.dataGrafikYestrday = response.data.payload.yesterday;

        // grafik today
        this.dataGrafikToday.map((todayGrapik) => {
          this.tmpToday.push({
            hour: todayGrapik.hour,
            value: todayGrapik.value,
          });
        });
        var obj = this.tmpToday;

        this.resultArray = Object.keys(obj).map((key) => {
          return obj[key].value;
        });
        const resulthour = Object.keys(obj).map((key) => {
          return obj[key].hour;
        });
        // this.chartOptions.xaxis.categories = resulthour

        //  var maping = resulthour.map(myFunction)

        var dete = moment(this.date).format("YYYY-MM-DD");
        var pendataan = nyoba(dete, resulthour);

        function nyoba(i, j) {
          let data = [];
          for (var k = 0; k <= j.length; k++) {
            if (j[k] == 0) {
              data.push(dete + "T0" + j[k] + ":00:00.000Z");
            } else if (j[k] > 0 && j[k] < 10) {
              data.push(i + "T0" + j[k] + ":30:00.000Z");
            } else if (j[k]) {
              data.push(dete + "T" + j[k] + ":30:00.000Z");
            }
          }
          return data;
        }
        let tanggal = pendataan.toString().split(",");
        // console.log(tanggal);
        for (let i = 0; i < tanggal.length; i++) {
          this.chartOptions.xaxis.categories.push(tanggal[i]);
        }
        //  this.chartOptions.xaxis.categories.push("2021-03-08T00:30:00.000Z","2021-03-08T01:30:00.000Z","2021-03-08T02:30:00.000Z","2021-03-08T03:30:00.000Z","2021-03-08T04:30:00.000Z","2021-03-08T05:30:00.000Z","2021-03-08T06:30:00.000Z","2021-03-08T07:30:00.000Z","2021-03-08T08:30:00.000Z","2021-03-08T09:30:00.000Z","2021-03-08T10:30:00.000Z","2021-03-08T11:30:00.000Z","2021-03-08T12:30:00.000Z","2021-03-08T13:30:00.000Z","2021-03-08T14:30:00.000Z","2021-03-08T15:30:00.000Z")

        //grafik yesterday
        this.dataGrafikYestrday.map((yesterdayGrapik) => {
          this.tmpYesterday.push({
            value: yesterdayGrapik.value,
          });
        });
        var obj2 = this.tmpYesterday;
        this.resultArray2 = Object.keys(obj2).map((key) => {
          return obj2[key].value;
        });
        // console.log("today " + JSON.stringify(this.resultArray));
        // console.log("yesterday " + JSON.stringify(this.resultArray2));
        this.series = [
          {
            name: "today",
            data: this.resultArray,
          },
          {
            name: "yesterday",
            data: this.resultArray2,
          },
        ];
      });
    },
  },
  components: {
    "vue-audio": VueAudio,
  },
  computed: {
    dataShortToken() {
      return this.$store.getters["auth/getDataShortToken"];
    },
    warning() {
      return this.$store.getters["consultation/getWarning"];
    },
    nama() {
      return this.$store.getters["consultation/getWarningNama"];
    },
    jam() {
      return this.$store.getters["consultation/getWarningJam"];
    },
  },
  watch: {
    playMusik() {
      var sound = new Audio(notif);
      sound.play();
    },
  },
  //  computed: {
  //       height () {
  //         switch (this.$vuetify.breakpoint.name) {
  //           case 'xs': return 220
  //           case 'sm': return 400
  //           case 'md': return 500
  //           case 'lg': return 600
  //           case 'xl': return 800
  //         }
  //       },
  //     }

  mounted() {
    this.callRefreshToken();
    this.warning;
    this.nama;
    this.jam;
    this.waktu = setInterval(() => {
      this.popup();
    }, 30000);
    this.getDataSummary(),
      this.getDataGrafik(),
      this.getDataPerformance(),
      this.getPhotoprofile(),
      setInterval(this.getDataSummary, 5000);
    NotificationServices.getTokenfcm().then((response) => {
      console.log("xxxx", response);
    });
    // setInterval(this.getDataGrafik, 600000);
  },
};
</script>
<style scoped>
.test {
  text-size-adjust: 30;
}

.bgColor {
  background: #5c95ff;
  overflow-y: visible;
}
.bgColor2 {
  background: #edf4fb;
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  width: 100%;
  height: 100%;
}
.my-input.v-input .v-input__slot {
  border-radius: 100px;
  border-top-right-radius: 150px;
  border-bottom-right-radius: 550px;
  width: 100px;
}
/* .card{
   width: 300px;
   height: 100px;
} */
.rounded {
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  background-color: #edf4fb;
  box-shadow: 0px 4px 10px rgba(68, 133, 253, 0.5) !important;
}
.lblDate {
  font-family: Poppins;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 10px;
  color: #0e7dd6;
}
.lblConsultation {
  font-family: Nunito;
  font-style: normal;
  font-weight: bold;
  font-size: 26px;
  line-height: 15px;
  color: #073059;
}
.lblTitleCard {
  font-family: Nunito;
  font-style: normal;
  font-weight: 600;
  font-size: 30px;
  line-height: 25px;
  color: #121212;
}
.lblSubTitleCard {
  font-family: Nunito;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 33px;
  letter-spacing: 0.5px;
  color: #121212;
}
.resulTimeChart {
  color: #252733;
  font-size: 22px;
  text-align: center;
  font-weight: bold;
  font-family: Mulish;
}
.cardchard {
  height: 450px;
  box-shadow: 0px 4px 10px rgba(68, 133, 253, 0.5) !important;
}
.lblTypeResultChart {
  color: #231f20;
  font-size: 14px;
  font-family: Mulish;
}
.headerChart {
  color: #252733;
  font-size: 24px;
  font-weight: bold;
  font-family: Nunito;
}
.subHeaderChart {
  color: #9fa2b4;
  font-size: 12px;
  font-family: Mulish;
}
.average {
  color: #252733;
  font-size: 15px;
  font-family: Mulish;
}
.img-icon {
  width: 50px;
  height: 50px;
  margin-top: -10px;
}

@media only screen and (max-width: 1366px) {
  .resolution {
    width: 100%;
  }
  .lblSubTitleCard {
    font-family: Nunito;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 30px;
    letter-spacing: 0.5px;
    color: #121212;
  }
  .bgColor2 {
    background: #edf4fb;
    border-top-left-radius: 50px;
    border-bottom-left-radius: 50px;
    width: 100%;
    height: 100%;
  }
  .text-nama {
    font-size: 12px;
    font-weight: 600;
    font-family: Nunito;
  }
  .image-profile {
    width: 40px;
    height: 40px;
    border: 2px solid #ffffff;
  }
  .lblDate {
    font-family: Poppins;
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    line-height: 5px;
    color: #0e7dd6;
  }

  .lblConsultation {
    font-family: Nunito;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 5px;
    color: #073059;
  }
  .img-icon {
    width: 50px;
    height: 50px;
    margin-top: -10px;
  }
  .rounded {
    width: 250px;
    height: 85px;
    background-color: #ffffff;
  }
  .lblTitleCard {
    font-family: Nunito;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 25px;
    color: #121212;
  }
  .cardchard {
    height: 380px;
  }
  .headerChart {
    color: #252733;
    font-size: 22px;
    font-weight: bold;
    font-family: Nunito;
  }
  .subHeaderChart {
    color: #9fa2b4;
    font-size: 10px;
    font-family: Mulish;
  }
  .average {
    color: #252733;
    font-size: 13px;
    font-family: Mulish;
  }
  .resulTimeChart {
    color: #252733;
    font-size: 13px;
    text-align: center;
    font-weight: bold;
    font-family: Mulish;
  }
  .apex {
    height: 80px;
  }
}
</style>