/**
 * @author [Mochamad Arifin][Tandy Alam][Iman Akbar]
 * @email [mochamad.arifin@dwidasa.com][tandy.hutama@dwidasa.com][iman.akbar@dwidasa.com]
 * @create date 2021-01-11 12:51:34 
 * @modify date 2021-05-04 12:51:34
 * @desc [description]
 */

<template>
  <v-app class="bgColor">
    <v-container fluid fill-height class="">
      <v-row align="center" justify="center">
        <v-col cols="auto" sm="10" md="8" lg="8" class="">
          <v-card flat class="white ma-3 pa-3">
            <v-row align="center" justify="center" class="">
              <v-col class="" cols="6" lg="6">
                <v-row justify="center">
                  <img
                    src="@/assets/klinisia logo final 1.png"
                    width="200"
                    height="90"
                    alt=""
                    class=""
                  />
                </v-row>
                <v-row justify="center" class="mt-2">
                  <p class="lblLupaPass">Lupa Password?</p>
                </v-row>
              </v-col>
            </v-row>

            <v-row align="center" justify="center">
              <v-col cols="12" lg="6">
                <p class="lblDesc">
                  Jangan khawatir. Reset password Anda dengan mudah, masukan
                  email yang terdaftar
                </p>
              </v-col>
            </v-row>

            <v-row align="center" justify="center" class="">
              <v-col class="" cols="12" lg="6">
                <v-form ref="form" v-model="valid" lazy-validation>
                  <p class="lblTfEmail">Email address*</p>
                  <v-text-field
                    placeholder="Enter email address"
                    outlined
                    required
                    :rules="emailRules"
                    v-model="params.email"
                  >
                  </v-text-field>
                </v-form>
              </v-col>
            </v-row>

            <v-row align="center" justify="center" class="">
              <v-col cols="12" lg="6">
                <v-btn @click="successReset" block large class="primary"
                  >Kirim</v-btn
                >
              </v-col>
            </v-row>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
export default {
  name: "ForgotPass",
  data() {
    return {
      email: "",
      emailRules: [
        (v) => !!v || "E-mail is required",
        (v) => /.+@.+\..+/.test(v) || "E-mail must be valid",
      ],
      params: {
        userType: "doctor",
        email: "",
      },
    };
  },
  methods: {
  successReset() {
      // const reg = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;,
       const email = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (this.params.email === "") {
          this.$toast.error("Email Wajib Di Isi", {
          type: "error",
          position: "top-right",
          duration: 4000,
        });
      } else if (
        !this.params.email.includes("@") &&
        !this.params.email.includes(".")
      ) {
        this.$toast.error("Format Email Tidak Sesuai", {
          type: "error",
          position: "top-right",
          duration: 4000,
        });
      }
      else if (
        !this.params.email.includes(".")
      ) {
        this.$toast.error("Format Email Tidak Sesuai", {
          type: "error",
          position: "top-right",
          duration: 4000,
        });
      }
      else if (
        !this.params.email.includes("@")
      ) {
        this.$toast.error("Format Email Tidak Sesuai", {
          type: "error",
          position: "top-right",
          duration: 4000,
        });
      }
      else if (
        !this.params.email.includes("@") ||
        !this.params.email.includes(".") ||
        !email.test(this.params.email)
      ) {
        this.$toast.error("Format Email Tidak Sesuai", {
          type: "error",
          position: "top-right",
          duration: 4000,
        });
      }
       else {
        this.$store
          .dispatch("auth/actDoForgotPass", this.params)
          .then((response) => {
            console.log(response);
            window.localStorage.setItem("email", this.params.email);
            this.$router.push("/successresetpass");
          });
      }
    },
  },
};
</script>

<style scoped>
.bgColor {
  background: #edf4fb;
}
.lblLupaPass {
  font-family: Inter;
  font-style: normal;
  font-weight: bold;
  font-size: 28px;
  line-height: 30px;
  display: flex;
  align-items: center;

  color: #2a8bf2;
}
.lblDesc {
  font-family: Inter;
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
  display: flex;
  align-items: center;
  text-align: center;

  color: #231f20;
}
.lblTfEmail {
  font-family: Inter;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 16px;
  display: flex;
  align-items: center;

  color: #696f79;
}
</style>