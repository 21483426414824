/**
 * @author [Mochamad Arifin]
 * @email [mochamad.arifin@dwidasa.com]
 * @create date 2021-02-21 16:22:55
 * @modify date 2021-02-21 16:22:55
 * @desc [description]
 */

<template>
  <!-- <v-app class="background"> -->
  <v-row class="styleHeaderChat">
    <v-col cols="2" class="">
      <v-row align="center" justify="center">
        <v-avatar color="white">
          <img
            :src="dataTakeQueue.data.payload.consultation.profilePictureUrl"
            alt=""
            class="photo"
          />
        </v-avatar>
      </v-row>
    </v-col>
    <v-col cols="6" class="mt-3">
      <div class="name">{{ dataHeaderQiscus.name }}</div>
    </v-col>
    <v-col cols="2">
      <v-avatar class="pointer" color="white" width="19">
        <img src="@/assets/vidcall.svg" @click="govidacall" alt="" />
      </v-avatar>
    </v-col>

    <!-- <v-col cols="2">
      <v-avatar class="pointer" color="white" width="15">
        <img src="@/assets/vidcall.svg" @click="call" alt="" />
      </v-avatar>
    </v-col> -->
    <!-- <div v-if="statusAccept == true"> -->
    <!-- <v-dialog v-model="mdialog" persistent max-width="450">
      <v-card>
        <v-card-text>
          <p>Pasien {{ targetName }} Menerima Panggilan Anda</p>
          <v-btn medium outlined color="primary" @click="call">Ok</v-btn>
        </v-card-text>
      </v-card>
    </v-dialog> -->
    <!-- </div> -->
    <!-- <div id="meet" class="popup" v-show="visible">
      <iframe id="video1"  class="popup" src='https://call.dial.id/softphone/clicktovcall?clientSid=BD752E176DC1404A8B96291CF5DA438A&apikey=4k535D14LVc4Ll12345&from=534&to=081555427524&fcm_server_key=AAAADIBXWPg:APA91bHcQmGN8iPG_oteZc_lwdXPS1KQYYtS--xRi9fEpkspSlF5z0eR86kblEzC1kno9VodSzzZR_bFQJ4_ssWKoKMKrl6WOo2PQ55uIqheF52FhxK17U2WiasZUOsuPYp3srw5TucG&fcm_token=eJ5iLnMmRwa-15qLVBXjp1:APA91bHDprt-9GBiWfraiipnA3Je10GzvAbtL6HSczdgB4sOk119SnBM1-KRV4Ml2WHjEESEETbGv0uC7ansIkFvXZDisOGV5_hOvGTcWfB81sUbGbmYF6wB6aFWzN7orPo7jTVv9Q6A#081555427524' frameborder="0" allowtransparency="true" allowfullscreen></iframe>
      <a class="btn ml-3" @click="close">Close Video Call</a>
    </div> -->
  </v-row>
</template>
  <script src="https://call.qiscus.com/v2/qiscus_external_api.min.js"></script>
  <script src="https://call.qiscus.com/v2/qiscus_core_api.min.js"></script>
<script>
// import ConsultationService from "@/services/consultation_services";
import GeneralServices from "@/services/general_services";
import moment from "moment";
export default {
  data: () => ({
    photoProfile: null,
    visible: false,
    mdialog: false,
    targetName: "",
    targetEmail: "",
    targetRoom: "",
    avatarDoctor: "",
    messagechat: "",
    ConsultationId: "",
    accept: false,
    isDisabled: false,
    urlVideo: "",
    v: "",
    // api: [],
    // domain: "",
  }),
  methods: {
    getQueue() {},
    close() {
      this.visible = false;
      // this.targetRoom = "";
      // this.domain = "";
    },

    // async call() {
    //   // ConsultationService.Queue().then((response) => {
    //   //   console.log(response);
    //   //   this.targetEmail = response.data.payload[0].patientEmail;
    //   //   this.targetName = response.data.payload[0].patientFullName;
    //   //   this.targetRoom = response.data.payload[0].chatRoomId;
    //   //   this.avatarDoctor = response.data.payload[0].doctorProfilePictureUrl;
    //   //   this.ConsultationId = response.data.payload[0].cntConsultationId;
    //   // });
    //   // GeneralServices.getPhotoProfile(
    //   //   window.localStorage.getItem("secUserId")
    //   // ).then((response) => {
    //   //   console.log("lloloo",response);
    //   //   this.photoProfile =
    //   //     response.config.url +
    //   //     "?token=" +
    //   //    this.dataShortToken;
    //   // });
    //   let domain = "call.qiscus.com";
    //   let appId = "meetstage-iec22sd";
    //   let room = this.targetRoom;
    //   let data = {
    //     avatar: this.dataPhotoProfile,
    //     name: this.datafullname,
    //     email: this.dataEmail,
    //     appId: appId,
    //     room: room,
    //     moderator: false,
    //   };
    //   let core = new QiscusMeetCoreAPI();
    //   let jwt = await core.getJWT(data);
    //   let options = {
    //     roomName: appId + "/" + room,
    //     parentNode: document.querySelector("#meet"),
    //     configOverwrite: {
    //       startWithAudioMuted: true,
    //       startWithVideoMuted: true,
    //     },
    //     interfaceConfigOverwrite: {
    //       TOOLBAR_BUTTONS: ["microphone", "camera", "hangup"],
    //       VIDEO_LAYOUT_FIT: "both",
    //       DEFAULT_REMOTE_DISPLAY_NAME: "Guest",
    //       DISPLAY_WELCOME_PAGE_CONTENT: false,
    //       APP_NAME: "Qiscus",
    //       NATIVE_APP_NAME: "Qiscus",
    //       PROVIDER_NAME: "Qiscus",
    //     },

    //     jwt: jwt.token,
    //   };
    //   let api = new QiscusMeetExternalAPI(domain, options);
    //   console.log("o", api);
    //   // Triggered when current user ended the call
    //   api.meet.on("videoConferenceLeft", () => {
    //     console.log("you ended the call");
    //     api.meet.executeCommand('hangup');
    //     // window.location.href = "/chat";
    //     this.visible = false;
    //   });

    //   // Triggered when other user ended the call
    //   api.meet.on("participantLeft", () => {
    //     console.log("call ended by other user");
    //     api.meet.executeCommand('hangup');
    //     this.visible = false;
    //     // window.location.href = "/chat";
    //   });
    // },
    govidacall() {
      // console.log(this.dataHeaderQiscus);
      console.log("l", this.dataTakeQueue.data.payload);
      let time = new Date();
      let milisecond = time.getTime();
      // const cht = moment.utc(time).format("YYYY-MM-DD");
      const jam = moment.utc(jam).format("hhmmss");
      // let clientSid = "KSI234ZMPQD9835735GJBF93743HIFE93";
      let clientSidProd = "K18S29I310P411R512O613D7142906221";
      let serverkey =
        "AAAAQnawxRw:APA91bEaoCq5QvUO5FPPcy3zqBnsRhC7VWlGUmDxXpq8F14KXfF4K5nsSeLzSi8F2iWJHd9dWfp-UAYeVvj61MjiLm490XFuaFIn1e6Qeh0qpZTEArbwYzh8yuAlkjfTL-gwACYnYe2L";
      let to = this.dataTakeQueue.data.payload.patient.mobilePhone;
      let idConsul =
        this.dataTakeQueue.data.payload.consultation.cntConsultationId;
      let from =
        this.dataTakeQueue.data.payload.consultation.cntConsultationId +
        "-" +
        milisecond;
      let fcm =
        this.dataTakeQueue.data.payload.userPatientFcmTokens[0].fcmToken;
      let data = {
        cntConsultationId: idConsul,
        videoCallFrom: from,
        videoCallTo: to,
      };
      console.log("fcm token = ", fcm);
      // console.log("hasil url = ", url);
      if (fcm !== null) {
        GeneralServices.postVidcall(data).then((res) => {
          if (res.data.error !== "") {
            this.$toast.open({
              message: res.data.error,
              position: "top-right",
              type: "error",
              duration: 6000,
            });
          } else {
            console.log(res);
            // window.open(
            //   "https://devcall.dial.id/softphone/clicktovcall?clientSid=" +
            //     clientSid +
            //     "&apikey=4k535D14LVc4Ll12345&from=" +
            //     from +
            //     "&to=" +
            //     to +
            //     "&fcm_server_key=" +
            //     serverkey +
            //     "&fcm_token=" +
            //     fcm +
            //     "#" +
            //     to +
            //     ""
            // );
            window.open(
              "https://call.dial.id/softphone/clicktovcallov?clientSid=" +
                clientSidProd +
                "&apikey=4k535D14LVc4LlPR0d12345&from=" +
                from +
                "&to=" +
                to +
                "&fcm_server_key=" +
                serverkey +
                "&fcm_token=" +
                fcm +
                "#" +
                to +
                ""
            );
          }
        });
      } else {
        this.$toast.open({
          message: "Fcm Token Tidak Ada",
          position: "top-right",
          type: "error",
          duration: 6000,
        });
      }

      // window.open(
      //   "https://call.dial.id/softphone/clicktovcall?clientSid=BD752E176DC1404A8B96291CF5DA438A&apikey=4k535D14LVc4Ll12345&from=534&to=081555427524&fcm_server_key=AAAADIBXWPg:APA91bHcQmGN8iPG_oteZc_lwdXPS1KQYYtS--xRi9fEpkspSlF5z0eR86kblEzC1kno9VodSzzZR_bFQJ4_ssWKoKMKrl6WOo2PQ55uIqheF52FhxK17U2WiasZUOsuPYp3srw5TucG&fcm_token=eJ5iLnMmRwa-15qLVBXjp1:APA91bHDprt-9GBiWfraiipnA3Je10GzvAbtL6HSczdgB4sOk119SnBM1-KRV4Ml2WHjEESEETbGv0uC7ansIkFvXZDisOGV5_hOvGTcWfB81sUbGbmYF6wB6aFWzN7orPo7jTVv9Q6A#081555427524"
      // );
      //   console.log(this.dataEmail);
      //   console.log(this.datafullname);
      //   console.log(this.dataPhotoProfile);
      //   console.log(this.dataShortToken);
      //   ConsultationService.Queue()
      //     .then((response) => {
      //       console.log(response);
      //       this.targetEmail = response.data.payload[0].patientEmail;
      //       this.targetName = response.data.payload[0].patientFullName;
      //       this.targetRoom = response.data.payload[0].chatRoomId;
      //       this.avatarDoctor = response.data.payload[0].doctorProfilePictureUrl;
      //       this.ConsultationId = response.data.payload[0].cntConsultationId;
      //       let param = {
      //         cntConsultationId: this.ConsultationId,
      //         roomId: this.targetRoom,
      //       };
      //       ConsultationService.postRoomVidcall(param).then((response) => {
      //         console.log(response);
      //         if (response.data.error != "") {
      //           this.$toast.open({
      //             message: response.data.error,
      //             position: "top-right",
      //             type: "error",
      //             duration: 6000,
      //           });
      //         } else {
      //           this.isDisabled = true;
      //           this.$toast.open({
      //             message: "Anda Sedang Menghubungi Pesien " + this.targetName,
      //             position: "top-right",
      //             type: "success",
      //             duration: 6000,
      //           });
      //         }
      //       });
      //     })
      //     .catch((e) => {
      //       this.$toast.open({
      //         message: e,
      //         position: "top-right",
      //         type: "error",
      //         duration: 6000,
      //       });
      //     });
      //   this.v = window.setInterval(() => {
      //     if (this.statusAcceptVC == true || this.statusAcceptVC == "true") {
      //       clearInterval(this.v);
      //       this.visible = true;
      //       this.isDisabled = false;
      //       this.call();
      //       // clearInterval(this.v);
      //     } else if (this.statusAcceptVC == "false") {
      //       this.visible = false;
      //       this.isDisabled = false;
      //       // setTimeout(()=>{
      //       //   this.isDisabled = false;
      //       // },6000)

      //       clearInterval(this.v);
      //     }
      //   }, 1000);
    },
  },
  computed: {
    dataShortToken() {
      return this.$store.getters["auth/getDataShortToken"];
    },
    dataStatusQiscus() {
      return this.$qiscus.publishOnlinePresence(isOnline);
    },
    dataHeaderQiscus() {
      return this.$store.getters["consultation/getResponseQiscus"];
    },
    dataEmail() {
      return this.$store.getters["auth/getDataEmail"];
    },
    datafullname() {
      return this.$store.getters["auth/getDataFullName"];
    },
    dataPhotoProfile() {
      return this.$store.getters["consultation/getPhoto"];
    },
    // dataStatusQiscus() {
    //   return this.$store.getters["chat/getIsOnline"];
    // },
    getStatusDenganJanji() {
      return this.$store.getters["consultation/getDenganJanji"];
    },
    dataTakeQueue() {
      if (this.getStatusDenganJanji !== true) {
        return this.$store.getters["consultation/getResponseTakeQueue"];
      } else {
        return this.$store.getters["consultation/getResponseTakeQueueWithApp"];
      }
    },
    statusAcceptVC() {
      return this.$store.getters["consultation/getAcceptStatus"];
    },
  },

  mounted() {
    this.getStatusDenganJanji;
    this.getQueue();
    // this.dataPhotoProfile;
    // this.dataEmail;
    // this.datafullname;
    //  this.dataStatusQiscus()
    // this.govidacall();
  },
};
</script>

<style scoped>
.styleHeaderChat {
  margin-left: 3px;
  border-radius: 33px;
  height: 50px;
}
.name {
  font-family: Nunito;
  font-size: 12px;
}
.photo {
  margin-top: 0;
  width: 40px;
  height: 40px;
}
.pointer:hover {
  cursor: pointer;
}
.isonline {
  font-family: Nunito;
  font-size: 12px;
  font-style: italic;
}
.popup {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  margin: auto;
  width: 100%;
  height: 100%;
  border-radius: 15px;
  z-index: 1;
  transition: 0.6s;
  background-color: black;
}
.btn {
  color: white;
}
.btn:hover {
  color: rgb(152, 240, 255);
}
</style>